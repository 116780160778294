import { NgModule } from '@angular/core';
import { CONFIG_MANAGER_SERVICE_CONFIGURATION, ConfigManagerModule } from '@xpo-ltl/config-manager';
import {CityOperationsApiService} from './cityoperations-api.service';

@NgModule({
  imports: [
    ConfigManagerModule
  ],
  providers: [
    CityOperationsApiService,
    { provide: CONFIG_MANAGER_SERVICE_CONFIGURATION, useValue: { configFileUrl: './assets/config.json'}},
  ]
})
export class SdkCityOperationsModule { }
