import { NgModule } from '@angular/core';
import { CONFIG_MANAGER_SERVICE_CONFIGURATION, ConfigManagerModule } from '@xpo-ltl/config-manager';
import {CustomerApiService} from './customer-api.service';

@NgModule({
  imports: [
    ConfigManagerModule
  ],
  providers: [
    CustomerApiService,
    { provide: CONFIG_MANAGER_SERVICE_CONFIGURATION, useValue: { configFileUrl: './assets/config.json'}},
  ]
})
export class SdkCustomerModule { }
