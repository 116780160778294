/**
 * CityOperations API
 * Version: 1.0
 * Build: 1.0.1.50
 */

import {
	ShipmentId,
	DataValidationError,
	LatLong,
	XrtSearchQueryHeader,
	PickupLineItemSearchFilter,
	ListInfo,
	AuditInfo,
	ShipmentSpecialServiceSummary,
	StopWindow,
	PickupLineItemSearchRecord,
	XrtAttributeFilter,
	SearchRecordMessageHeader,
	XrtSortExpression,
	XrtSearchRespHeader,
	GeoCoordinates,
	ActionCd,
	AppointmentNotificationStatCd,
	BillClassCd,
	DeliveryQualifierCd,
	EarlyCutoffCd,
	EnsembleExecutionStatusCd,
	MetricStageCd,
	MetricStageSubCd,
	MetricTypeCd,
	MetricValueKeyCd,
	MetricVarianceCd,
	MinuetExecutionStatusCd,
	MovementExceptionTypeCd,
	MovementStatusCd,
	NodeTypeCd,
	NoteTypeCd,
	PickupSourceCd,
	PickupStatusCd,
	PickupTypeCd,
	PnDRoleCd,
	PolygonTypeCd,
	RouteBalancingTypeCd,
	RouteCategoryCd,
	RouteStatusCd,
	RouteTypeCd,
	RschCnclReasonCd,
	ScheduleStatusCd,
	ShipmentOnTimeStatusCd,
	ShipmentPnDStatusCd,
	ShipmentServiceTypeCd,
	ShipmentSpecialServiceCd,
	ShipperCountryCd,
	ShipperCreditStatusCd,
	ShipperPhoneCountryCd,
	SpecialEquipmentCd,
	StopSequenceModelConflictCd,
	StopWindowCd,
	StrandReasonCd,
	TripCategoryCd,
	TripNodeActivityCd,
	TripNodeStatusCd,
	TripNodeTypeCd,
	TripStatusCd,
	ZoneIndicatorCd
} from '@xpo-ltl/sdk-common';

/**
 * Path parameter for the request to specify which trip will receive the pickups.
 */
export class AssignPickupsToPnDTripPath {
	/**
	 * The unique identifier associated with the trip.
	 * Required: true
	 * Array: false
	 */
	tripInstId:number = undefined;
}
/**
 * Query parameters used to assign pickups to an existing trip.
 */
export class AssignPickupsToPnDTripRqst {
	/**
	 * The list of unique identifiers for pickups requests to assign to the trip.
	 * Required: true
	 * Array: true
	 */
	pickupRequestInstIds:number[] = undefined;
	/**
	 * The status to set on the pickups assigned to the trip.  Two possible values: ASGN or PREA.
	 * Required: true
	 * Array: false
	 */
	statusCd:TripNodeStatusCd = undefined;
}
/**
 * Path parameter for the request to specify which SIC the Route is associated with.
 */
export class AssignShipmentsToRoutePath {
	/**
	 * The service center for which the shipments are being assigned.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The instance id of the route to which shipments will be added.
	 * Required: true
	 * Array: false
	 */
	routeInstId:number = undefined;
}
/**
 * Request body for assigning shipments to an existing route.
 */
export class AssignShipmentsToRouteRqst {
	/**
	 * Mandatory list of shipments to be assigned to the new route.
	 * Required: true
	 * Array: true
	 */
	shipmentIds:ShipmentId[] = undefined;
	/**
	 * Overrides the edits which enforce TDC date validations for each shipment on the request.
	 * Required: false
	 * Array: false
	 */
	overrideTdcInd:boolean = undefined;
}
/**
 * Response to the request for adding shipments to routes, returns the details for that Trip/Route
 */
export class AssignShipmentsToRouteResp {
	/**
	 * Information about the trip/routes to which shipments were assigned.
	 * Required: false
	 * Array: false
	 */
	tripDetail:TripDetail = undefined;
	/**
	 * List of all the shipments from the request that had TDC warnings; these should be shown to the user.
	 * Required: true
	 * Array: true
	 */
	tdcWarnings:DataValidationError[] = undefined;
}
/**
 * Request to optimize the order of PnD stops or unassigned stops
 */
export class AutoSequencePnDStopsRqst {
	/**
	 * The list of trips with PnD stops and unassign stops.
	 * Required: false
	 * Array: true
	 */
	stopSequenceTrips:StopSequenceTrip[] = undefined;
}
/**
 * Response of optimized order of given PnD stops
 */
export class AutoSequencePnDStopsResp {
	/**
	 * The list of optimized order of trips with stops
	 * Required: false
	 * Array: true
	 */
	stopSequenceTrips:StopSequenceTrip[] = undefined;
}
/**
 * Path parameter for specification of Route Balancing activity.
 */
export class BalancePnDRoutesPath {
	/**
	 * The service center for which the routes are being balanced.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Request to balance routes when the user presses the Save button.
 */
export class BalancePnDRoutesRqst {
	/**
	 * The state of existing routes.
	 * Required: false
	 * Array: true
	 */
	existingRoutes:RouteBalancingTrip[] = undefined;
	/**
	 * The state of new routes.
	 * Required: false
	 * Array: true
	 */
	newRoutes:RouteBalancingTrip[] = undefined;
	/**
	 * Any Unassigned Stops as a result of the Route Balancing.
	 * Required: false
	 * Array: true
	 */
	unassignedStops:RouteBalancingTrip[] = undefined;
	/**
	 * Overrides the edits which enforce TDC date validations for each shipment on the request.
	 * Required: false
	 * Array: false
	 */
	overrideTdcInd:boolean = undefined;
}
/**
 * Response to the request to balance routes.
 */
export class BalancePnDRoutesResp {
	/**
	 * Required: false
	 * Array: true
	 */
	tripDetails:TripDetail[] = undefined;
}
/**
 * The path parameter for the request to list the collection of metrics for a trip.
 */
export class CalculatePnDTripMetricsPath {
}
/**
 * Request to calculate PnD Trip metrics.
 */
export class CalculatePnDTripMetricsRqst {
	/**
	 * The unique identifier for the trip for which the metrics are being calculated.
	 * Required: true
	 * Array: false
	 */
	tripInstId:number = undefined;
	/**
	 * The unique identifier for the route for which the metrics are being calculated.
	 * Required: true
	 * Array: false
	 */
	routeInstId:number = undefined;
	/**
	 * The stage of operations for which this metric snapshot was taken. E.g. for Pnd, the stages are: Planning, Dispatched, Dock
	 * Required: true
	 * Array: false
	 */
	stage:MetricStageCd = undefined;
	/**
	 * The specific activity performed in a stage for which this metric was created. E.g. for Pnd, Released or Sequence could be a sub stage during the Planning stage.
	 * Required: true
	 * Array: false
	 */
	subStage:MetricStageSubCd = undefined;
	/**
	 * The unique identifier for the event that triggered the calculate trip metrics call.
	 * Required: true
	 * Array: false
	 */
	eventInstId:number = undefined;
}
/**
 * Response to the request to list the collection of metrics for a trip.
 */
export class CalculatePnDTripMetricsResp {
	/**
	 * The metrics associated with this trip.
	 * Required: false
	 * Array: true
	 */
	metrics:Metric[] = undefined;
}
/**
 * Request to create a new route.
 */
export class CreateNewRouteRqst {
	/**
	 * Information to create a new route.
	 * Required: true
	 * Array: false
	 */
	route:Route = undefined;
	/**
	 * The unique identifier that represents the area that will contain the new route.
	 * Required: true
	 * Array: false
	 */
	areaInstId:number = undefined;
	/**
	 * Optional list of shipments to be assigned to the new route.
	 * Required: false
	 * Array: true
	 */
	shipmentList:ShipmentId[] = undefined;
	/**
	 * Overrides the edits which enforce TDC date validations for each shipment on the request.
	 * Required: false
	 * Array: false
	 */
	overrideTdcInd:boolean = undefined;
}
/**
 * Response to the request to create a new route.
 */
export class CreateNewRouteResp {
	/**
	 * The new route that was created.
	 * Required: false
	 * Array: false
	 */
	route:Route = undefined;
	/**
	 * The new trip that was created for the new route.
	 * Required: false
	 * Array: false
	 */
	trip:Trip = undefined;
	/**
	 * List of all the shipments from the request that had TDC warnings; these should be shown to the user.
	 * Required: false
	 * Array: true
	 */
	tdcWarnings:DataValidationError[] = undefined;
}
/**
 * The path parameter used to identify the service center associated with the routes to be deleted.
 */
export class DeletePnDRoutesPath {
	/**
	 * The service center for which the routes are being deleted.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Query parameter used to delete routes.
 */
export class DeletePnDRoutesQuery {
	/**
	 * The list of unique identifiers for the routes which will be deleted.
	 * Required: true
	 * Array: true
	 */
	routeInstIds:number[] = undefined;
}
/**
 * Request to calculate PnD Trip used in route
 * 				balancing operations
 */
export class EvaluatePnDRouteBalancingRqst {
	/**
	 * Required: true
	 * Array: false
	 */
	terminalSicCode:string = undefined;
	/**
	 * The geo coordinates of the service center
	 * 						location.
	 * Required: true
	 * Array: false
	 */
	terminalGeoCoordinates:LatLong = undefined;
	/**
	 * Required: true
	 * Array: true
	 */
	routeBalancingTrips:RouteBalancingTrip[] = undefined;
}
/**
 * Response to the request to list the collection of
 * 				metrics for a trip.
 */
export class EvaluatePnDRouteBalancingResp {
	/**
	 * The trips associated with this route balancing
	 * 						response.
	 * Required: true
	 * Array: true
	 */
	trips:RouteBalancingTrip[] = undefined;
	/**
	 * The metrics associated with this request.
	 * Required: false
	 * Array: true
	 */
	metrics:Metric[] = undefined;
}
/**
 * The path parameter for the request to get the driver location for an employeeId
 */
export class GetPnDDriverLocationPath {
	/**
	 * The identifier for the employee which is used to get the driver location.
	 * Required: true
	 * Array: false
	 */
	employeeId:string = undefined;
}
/**
 * Response to requested driver location
 */
export class GetPnDDriverLocationResp {
	/**
	 * Information about the driver location.
	 * Required: false
	 * Array: false
	 */
	driverLocation:DriverLocation = undefined;
}
/**
 * The path parameter for the request to retrieve a single selection profile from the ID provided.
 */
export class GetPnDInboundSelectionProfilePath {
	/**
	 * The unique identifier for the selection profile.
	 * Required: true
	 * Array: false
	 */
	selectionProfileId:string = undefined;
}
/**
 * Response to the request to retrieve a single selection profile from the ID provided.
 */
export class GetPnDInboundSelectionProfileResp {
	/**
	 * The details for an inbound planning profile.
	 * Required: false
	 * Array: false
	 */
	selectionProfile:SelectionProfile = undefined;
}
/**
 * Path parameter for the request to retrieve a single route from the route instance ID provided.
 */
export class GetPnDRoutePath {
	/**
	 * The instance id of the route being retrieved.
	 * Required: true
	 * Array: false
	 */
	routeInstId:string = undefined;
}
/**
 * Query parameter for the request to retrieve a single route from the route instance ID provided.
 */
export class GetPnDRouteQuery {
	/**
	 * The service center for which the routes are being retrieved.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Response to the request to retrieve a single route from the route instance ID provided.
 */
export class GetPnDRouteResp {
	/**
	 * Required: false
	 * Array: false
	 */
	route:Route = undefined;
}
/**
 * The path parameters for the request to retrieve a single stop associated with a route.
 */
export class GetPnDStopPath {
	/**
	 * The instance id of the route for which stops are being requested.
	 * Required: true
	 * Array: false
	 */
	routeInstId:string = undefined;
	/**
	 * The sequence number for the trip node.
	 * Required: true
	 * Array: false
	 */
	tripNodeSequenceNbr:number = undefined;
}
/**
 * Response to the request to list the stops associated with a route.
 */
export class GetPnDStopResp {
	/**
	 * the instance of the requested stop.
	 * Required: false
	 * Array: false
	 */
	stop:Stop = undefined;
}
/**
 * Request to return trip details.
 */
export class GetPnDTripPath {
	/**
	 * The location associated with the trip.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The identifier for the trip.
	 * Required: true
	 * Array: false
	 */
	tripInstId:number = undefined;
}
/**
 * Response to the request to return trip details.
 */
export class GetPnDTripResp {
	/**
	 * Information about the trip.
	 * Required: false
	 * Array: false
	 */
	tripDetail:TripDetail = undefined;
}
/**
 * Path parameter for the request to return a pick up and delivery trip along with the details of its stops.
 */
export class GetPnDTripStopsPath {
	/**
	 * The identifier for the trip.
	 * Required: true
	 * Array: false
	 */
	tripInstId:number = undefined;
}
/**
 * Response to the request to return a pick up and delivery trip along with the details of its stops.
 */
export class GetPnDTripStopsResp {
	/**
	 * Information about the trip stops.
	 * Required: false
	 * Array: true
	 */
	tripStops:TripStop[] = undefined;
}
/**
 * Path parameter for the request to return a list of current driver locations.
 */
export class ListPnDDriverLocationsPath {
	/**
	 * The location associated with the trip.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Response to the request to provide a list of current driver locations.
 */
export class ListPnDDriverLocationsResp {
	/**
	 * Information about the driver locations.
	 * Required: false
	 * Array: true
	 */
	driverLocations:DriverLocation[] = undefined;
}
/**
 * The query parameter used for the request to list the inbound selection profiles associated with the SIC proivded.
 */
export class ListPnDInboundSelectionProfilesQuery {
	/**
	 * The service center for which the profiles are being listed.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Response to the request to list all inbound selection profiles associated with the SIC provided.
 */
export class ListPnDInboundSelectionProfilesResp {
	/**
	 * The list of selection profiles which matched the input criteria.
	 * Required: false
	 * Array: true
	 */
	selectionProfiles:SelectionProfile[] = undefined;
}
/**
 * Path parameter for the request to list all routes that need to be planned for today's delivery.
 */
export class ListPnDRoutesPath {
	/**
	 * The service center for which the routes are being retrieved.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * The query parameters used for the request to list all routes that need to be planned for today's delivery.
 */
export class ListPnDRoutesQuery {
	/**
	 * The date for which the retrieved shipments are being planned.
	 * Required: true
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * Indicates the zone.  The default is IncludeZones.
	 * Required: false
	 * Array: false
	 */
	zoneInd:ZoneIndicatorCd = undefined;
	/**
	 * Indicates that a planner is using the software. When this flag is set to true (default), planning routes, new routes, and special services routes are included.
	 * Required: false
	 * Array: false
	 */
	plannerInd:boolean = undefined;
	/**
	 * Indicates newly released routes should be included.  The default is true.
	 * Required: false
	 * Array: false
	 */
	newReleaseInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	categoryCd:RouteCategoryCd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	statusCd:RouteStatusCd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	specialServices:ShipmentSpecialServiceCd[] = undefined;
}
/**
 * Response to the request to list all routes that need to be planned for today's delivery.
 */
export class ListPnDRoutesResp {
	/**
	 * The shipments to be delivered in the current plan.
	 * Required: false
	 * Array: true
	 */
	routes:Route[] = undefined;
}
/**
 * Path parameter for the request to list metrics associated with the specified service center.
 */
export class ListPnDServiceCenterMetricsPath {
	/**
	 * The service center for which the metrics are being retrieved.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Query parameter for the request to list metrics associated with the specified service center.
 */
export class ListPnDServiceCenterMetricsQuery {
	/**
	 * The planning date to use for metrics calculation.
	 * Required: true
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * The role of the user.
	 * Required: false
	 * Array: false
	 */
	roleCd:PnDRoleCd = undefined;
}
/**
 * Response to the request to list metrics associated with the specified service center.
 */
export class ListPnDServiceCenterMetricsResp {
	/**
	 * Required: false
	 * Array: true
	 */
	metrics:Metric[] = undefined;
}
/**
 * Path parameter for the request to list the stops associated with a route.
 */
export class ListPnDStopsPath {
	/**
	 * The instance id of the route for which stops are being requested.
	 * Required: true
	 * Array: false
	 */
	routeInstId:string = undefined;
}
/**
 * Response to the request to list the stops associated with a route.
 */
export class ListPnDStopsResp {
	/**
	 * The route for the stops.
	 * Required: false
	 * Array: false
	 */
	route:Route = undefined;
	/**
	 * The stop(s) associated with this route.
	 * Required: false
	 * Array: true
	 */
	stops:Stop[] = undefined;
}
/**
 * The query parameters used for the request to list suggested route names.
 */
export class ListPnDSuggestedRouteNamesPath {
	/**
	 * The sic for which to create suggested route names.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Path parameter for suggested route names 
 */
export class ListPnDSuggestedRouteNamesQuery {
	/**
	 * The date for which the routes and names will be returned.
	 * Required: true
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * Indicates if satellite sics should be included.
	 * Required: false
	 * Array: false
	 */
	satelliteInd:boolean = undefined;
}
/**
 * Response to the request to list all suggested route names for the sic.
 */
export class ListPnDSuggestedRouteNamesResp {
	/**
	 * The suggested route names for the sic.
	 * Required: false
	 * Array: true
	 */
	suggestedRouteNames:SuggestedRouteName[] = undefined;
	/**
	 * The list of existing routes.
	 * Required: false
	 * Array: true
	 */
	existingRouteSummaries:ExistingRouteSummary[] = undefined;
}
/**
 * The path parameters for the request to list the collection of all trips for a SIC and trip date.
 */
export class ListPnDTripsPath {
	/**
	 * The location associated with the trip.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * The query parameter for the request to list the collection of all trips for a SIC and trip date.
 */
export class ListPnDTripsQuery {
	/**
	 * Indicator to get trips for zone SICs along with the host
	 * Required: false
	 * Array: false
	 */
	includeZoneCodeInd:boolean = undefined;
	/**
	 * The status for the trip.
	 * Required: false
	 * Array: true
	 */
	tripStatusCd:TripStatusCd[] = undefined;
	/**
	 * The date associated with the trip.
	 * Required: true
	 * Array: false
	 */
	tripDate:string = undefined;
}
/**
 * Response to the request to list trip details.
 */
export class ListPnDTripsResp {
	/**
	 * Details about a trip.
	 * Required: false
	 * Array: true
	 */
	tripDetails:TripDetail[] = undefined;
}
/**
 * Query parameters used to list unassigned pickups.
 */
export class ListPnDUnassignedPickupsRqst {
	/**
	 * Specifies the page size, sort order, etc.
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchQueryHeader = undefined;
	/**
	 * The query for searching elastic/XRT.
	 * Required: false
	 * Array: false
	 */
	filter:PickupLineItemSearchFilter = undefined;
	/**
	 * The indicator to determine the retrieval of an unmapped alias.
	 * Required: false
	 * Array: false
	 */
	unmappedInd:boolean = undefined;
}
/**
 * Response to the request to list unassigned pickups.
 */
export class ListPnDUnassignedPickupsResp {
	/**
	 * Information about the pickups.
	 * Required: false
	 * Array: true
	 */
	unassignedPickups:UnassignedPickup[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * 
 * 				Request to list delivery stops that are unassigned or unmapped based on query parameter unmapped.
 * 			 
 */
export class ListPnDUnassignedStopsRqst {
	/**
	 * Specifies the page size, sort order, etc.
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchQueryHeader = undefined;
	/**
	 * The query for searching elastic/XRT.
	 * Required: false
	 * Array: false
	 */
	filter:DeliveryShipmentSearchFilter = undefined;
	/**
	 * The indicator to determine the retrieval of unmapped alias
	 * Required: false
	 * Array: false
	 */
	unmappedInd:boolean = undefined;
}
/**
 * Response to the request to provide a list of delivery shipments that are unassigned.
 */
export class ListPnDUnassignedStopsResp {
	/**
	 * Information about the unassigned shipments.
	 * Required: false
	 * Array: true
	 */
	unassignedStops:UnassignedStop[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Path parameter used to reassign a list of shipments to a new route.
 */
export class ReassignPnDShipmentsPath {
	/**
	 * The instance identifier of the route to which shipments will be reassigned.
	 * Required: true
	 * Array: false
	 */
	routeInstId:number = undefined;
}
/**
 * Request body for reassigning shipments from one route to another.
 */
export class ReassignPnDShipmentsRqst {
	/**
	 * The old route associated with the shipments.
	 * Required: true
	 * Array: false
	 */
	previousRouteInstId:number = undefined;
	/**
	 * Mandatory list of shipments to be reassigned.
	 * Required: true
	 * Array: true
	 */
	shipmentIds:ShipmentId[] = undefined;
	/**
	 * Overrides the edits which enforce TDC date validations for each shipment on the request.
	 * Required: false
	 * Array: false
	 */
	overrideTdcInd:boolean = undefined;
	/**
	 * Standard audit trail information.
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Request to reassign a list of stops to a route.
 */
export class ReassignStopsRqst {
	/**
	 * The old trip associated with the stops.
	 * Required: true
	 * Array: false
	 */
	oldTrip:Trip = undefined;
	/**
	 * The new trip associated with the stops.
	 * Required: true
	 * Array: false
	 */
	newTrip:Trip = undefined;
	/**
	 * The old route associated with the stops.
	 * Required: true
	 * Array: false
	 */
	oldRoute:Route = undefined;
	/**
	 * The new route associated with the stops.
	 * Required: true
	 * Array: false
	 */
	newRoute:Route = undefined;
	/**
	 * The list of trip nodes.
	 * Required: false
	 * Array: true
	 */
	tripNode:TripNode[] = undefined;
	/**
	 * Indicates whether or not to override all soft errors.
	 * Required: true
	 * Array: false
	 */
	overrideAllSoftErrorsInd:boolean = undefined;
	/**
	 * Overrides the edits which enforce TDC date validations for each shipment on the request.
	 * Required: false
	 * Array: false
	 */
	overrideTdcInd:boolean = undefined;
	/**
	 * Standard audit trail information.
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * List of stops for a trip assigned to a specific route.
 */
export class ReassignStopsResp {
	/**
	 * Information about the trip stops.
	 * Required: false
	 * Array: true
	 */
	tripStops:TripStop[] = undefined;
}
/**
 * Request to resequence the freight on the trailer by the order of its stops on a specific route.
 */
export class ResequenceFreightByRouteRqst {
	/**
	 * The route associated with the stops.
	 * Required: true
	 * Array: false
	 */
	route:Route = undefined;
	/**
	 * The stop sequence for the trip.
	 * Required: false
	 * Array: false
	 */
	stopSequenceTrip:StopSequenceTrip = undefined;
	/**
	 * AutoSequence indicator.
	 * Required: false
	 * Array: false
	 */
	autoSequenceInd:boolean = undefined;
}
/**
 * Request to resequence the freight on the trailer by the order of its stops on a specific trip.
 */
export class ResequenceFreightByTripRqst {
	/**
	 * Required: true
	 * Array: false
	 */
	terminalSicCode:string = undefined;
	/**
	 * The geo coordinates of the service center
	 * 						location.
	 * Required: true
	 * Array: false
	 */
	terminalGeoCoordinates:LatLong = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	routeBalancingTrip:RouteBalancingTrip = undefined;
}
/**
 * Request to run the stop sequencing model.
 */
export class StopSequenceModelRqst {
	/**
	 * The list of trips to optimize.
	 * Required: false
	 * Array: true
	 */
	input:StopSequenceModelData[] = undefined;
}
/**
 * Request to run the stop sequencing model.
 */
export class StopSequenceModelResp {
	/**
	 * The response with the optimized order of trips with stops.
	 * Required: false
	 * Array: true
	 */
	output:StopSequenceModelData[] = undefined;
}
/**
 * Request to update the onExcludedTrailerInd in the SCO_DLVR_SHM table.
 */
export class UpdateDeliveryShipmentExcludedTrailerIndPath {
	/**
	 * The location where the trailer's excluded status is changed.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The unique identifier of the trailer that needs its excluded status changed.
	 * Required: true
	 * Array: false
	 */
	equipmentInstId:number = undefined;
}
/**
 * Request to update the onExcludedTrailerInd in the SCO_DLVR_SHM table.
 */
export class UpdateDeliveryShipmentExcludedTrailerIndRqst {
	/**
	 * The excluded indicator.
	 * Required: true
	 * Array: false
	 */
	onExcludedTrailerInd:boolean = undefined;
	/**
	 * Standard audit trail information.
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Path parameter used to update the Route information as part of release.
 */
export class UpdatePnDRoutePath {
	/**
	 * The instance id of the route which is being updated
	 * Required: true
	 * Array: false
	 */
	routeInstId:number = undefined;
}
/**
 * Request to update Route info on SCO_ROUTE table
 */
export class UpdatePnDRouteRqst {
	/**
	 * Information to update the route
	 * Required: true
	 * Array: false
	 */
	route:Route = undefined;
	/**
	 * Standard audit trail information.
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Response to the request to update Route info on SCO_ROUTE table
 */
export class UpdatePnDRouteResp {
	/**
	 * Information to update the route
	 * Required: true
	 * Array: false
	 */
	route:Route = undefined;
	/**
	 * This is for informational messages that could come from the API. If present, this would be displayed by the UI for a successful route update (as opposed to an error message for unsuccessful update).
	 * Required: false
	 * Array: true
	 */
	messages:string[] = undefined;
}
/**
 * Request to unassign a list of stops from a route.
 */
export class UnassignStopsRqst {
	/**
	 * The trip associated with the stops.
	 * Required: true
	 * Array: false
	 */
	trip:Trip = undefined;
	/**
	 * The trip associated with the stops.
	 * Required: false
	 * Array: true
	 */
	tripNodes:TripNode[] = undefined;
	/**
	 * Standard audit trail information.
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Path parameter used to unassign a list of shipments from a route.
 */
export class UnassignPnDShipmentsFromRoutePath {
	/**
	 * The instance id of the route to which shipments will be unassigned.
	 * Required: true
	 * Array: false
	 */
	routeInstId:number = undefined;
}
/**
 * Request body for unassigning shipments from a route.
 */
export class UnassignPnDShipmentsFromRouteRqst {
	/**
	 * Mandatory list of shipments to be unassigned from a route.
	 * Required: true
	 * Array: true
	 */
	shipmentIds:ShipmentId[] = undefined;
}
/**
 * Path parameter for the request to validate a new route.
 */
export class ValidatePnDRoutePath {
	/**
	 * The service center for which the new route validity is being determined.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * The request to validate a new route.
 */
export class ValidatePnDRouteRqst {
	/**
	 * The date for which the new route validity is being determined.
	 * Required: true
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * The prefix for this route, generally the area name.
	 * Required: true
	 * Array: false
	 */
	routePrefix:string = undefined;
	/**
	 * The suffix for this route, uniquely identifying the route.
	 * Required: true
	 * Array: false
	 */
	routeSuffix:string = undefined;
}
/**
 * Response to the request to validate a new route.
 */
export class ValidatePnDRouteResp {
	/**
	 * Indicates whether or not the new route is valid.
	 * Required: false
	 * Array: false
	 */
	validRouteInd:boolean = undefined;
}
/**
 * Path parameter used to retrieve the final consignee destination arrival timestamp for the PRO provided.
 */
export class GetFinalConsigneeDestinationArrivalTmstByProPath {
	/**
	 * The shipment for which the arrival timestamp for the final consignee destination will be retrieved.
	 * Required: true
	 * Array: false
	 */
	proNbr:string = undefined;
}
/**
 * Respone to the request to retrieve the final consignee destination arrival timestamp for the PRO provided.
 */
export class GetFinalConsigneeDestinationArrivalTmstByProResp {
	/**
	 * The timestamp at which the shipment arrived at the final consignee destination.
	 * Required: true
	 * Array: false
	 */
	destinationArrivalTmst:Date = undefined;
}
/**
 * Elastic Search document for Delivery Shipment details
 */
export class DeliveryShipmentSearchRecord {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	areaInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	areaName:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryQualifierCd:DeliveryQualifierCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routePrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeSuffix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleETA:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedDeliveryDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	motorizedPiecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loosePcsCnt:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalCubePercentage:number = undefined;
	/**
	 * Derived attribute representing the estimated footprint of the shipment for planning a floor-loaded route.
	 * Required: false
	 * Array: false
	 */
	footprintPercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentPndStatus:ShipmentPnDStatusCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentInstId:number = undefined;
	/**
	 * Equipment id prefix
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * Equipment id suffix
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffix:number = undefined;
	/**
	 * The trailer on which this shipment is currently located
	 * Required: false
	 * Array: false
	 */
	currentTrailer:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hostDestSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleDestinationSicCd:string = undefined;
	/**
	 * This is the timestamp when this row was inserted/update in the search repository.
	 * Required: false
	 * Array: false
	 */
	lstUpdtTmst:Date = undefined;
	/**
	 * This is the timestamp when the original event was created.
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * This is the timestamp when the original event was created in microseconds.
	 * Required: false
	 * Array: false
	 */
	eventTmstMicro:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	scheduledLocalEta:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	scheduleStatusCd:ScheduleStatusCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	movementStatusCd:MovementStatusCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	movementExceptionTypeCd:MovementExceptionTypeCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	inbondInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	purgeBlockInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	routeTypeCd:RouteTypeCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	adminAsofDateTime:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destinationArrivalDateTime:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billClassCd:BillClassCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	serviceSummaryInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	onExcludedTrailerInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	trailerCurrSicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destSicEta:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destSicEtaUpdtTmstMicro:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destSicEtaLastUpdtTmst:Date = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	specialServiceSummary:ShipmentSpecialServiceSummary[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	stopWindow:StopWindow[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentDeletedInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	suppRefNbr:string[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupDt:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	originSic:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	responsibleSic:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee:InterfaceAcct = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper:InterfaceAcct = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo:InterfaceAcct = undefined;
	/**
	 * This is the timestamp when shipment was last moved.
	 * Required: false
	 * Array: false
	 */
	lstMvmtTmst:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	apptDtls:DeliveryNotification = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	apptReq:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc:TimeDateCritical = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	movementExcpActionReason:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	correctiveAction:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	splitInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentLocationSicCd:string = undefined;
	/**
	 * Indicator showing if the shipment final delivered date was within the Time Date Critical constraints.
	 * Required: false
	 * Array: false
	 */
	withinTdcCd:ShipmentOnTimeStatusCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	actualDeliveryDateTime:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	onTimeDeliveryCd:ShipmentOnTimeStatusCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	afterHoursPickupInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperRefNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentPurgedInd:boolean = undefined;
}
export class Activity {
	/**
	 * Required: false
	 * Array: false
	 */
	tripNodeActivity:TripNodeActivity = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	stopWindow:StopWindow = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	routeShipment:RouteShipment = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	shipmentSpecialServices:ShipmentSpecialServiceSummary[] = undefined;
}
/**
 * Basic header information about an activity for a trip node but only in the context of trip metrics
 */
export class ActivityInfoBasic {
	/**
	 * Code that categorizes the Activity.  Examples: DL, PU, HE, etc.
	 * Required: false
	 * Array: false
	 */
	activityCd:string = undefined;
	/**
	 * Total weight of Shipments at this activity
	 * Required: false
	 * Array: false
	 */
	totalWgt:number = undefined;
}
/**
 * Information related to a Driver's Current Location
 */
export class DriverLocation {
	/**
	 * Required: false
	 * Array: false
	 */
	currentLocation:CurrentLocation = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tripDriver:TripDriver = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	trip:Trip = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	routeEquipment:RouteEquipment[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	employeeMobileDevice:EmployeeDevice = undefined;
}
/**
 * A derived set of summary information for an existing route
 */
export class ExistingRouteSummary {
	/**
	 * The unique identifier for the trip associated with the route.
	 * Required: false
	 * Array: false
	 */
	tripInstId:number = undefined;
	/**
	 * The unique identifier of the route.
	 * Required: false
	 * Array: false
	 */
	routeInstId:number = undefined;
	/**
	 * The prefix component of the route name.
	 * Required: false
	 * Array: false
	 */
	routePrefix:string = undefined;
	/**
	 * The suffix component of the route name.
	 * Required: false
	 * Array: false
	 */
	routeSuffix:string = undefined;
	/**
	 * Identifies if the route (prefix/suffix) is a planning or delivery route.
	 * Required: false
	 * Array: false
	 */
	categoryCd:RouteCategoryCd = undefined;
	/**
	 * A SIC is associated with the route's prefix/suffix. For example the SIC USU can have a route of TRAP-NEXT. But USU has a satellite SIC of UEN. USU and UEN can both have a route named TRAP-NEXT. As a result, the planner would see TRAP-NEXT multiple times in their list. Adding the SIC will allow the planner to identify the planning route associated with a particular SIC.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Details about a geo area.
 */
export class GeoArea {
	/**
	 * The unique identifier for the area.
	 * Required: false
	 * Array: false
	 */
	areaInstId:string = undefined;
	/**
	 * The SIC code for the location.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The name for the geo area.
	 * Required: false
	 * Array: false
	 */
	geoAreaName:string = undefined;
	/**
	 * The description for the geo area.
	 * Required: false
	 * Array: false
	 */
	geoAreaDesc:string = undefined;
	/**
	 * Defines what the polygon coordinates represent.  Possible values are: Area (A), Boundary (B), Fence (F), and Service Center (S).
	 * Required: false
	 * Array: false
	 */
	polygonTypeCd:PolygonTypeCd = undefined;
	/**
	 * The coordinates for the locations.
	 * Required: false
	 * Array: true
	 */
	polygon:LatLong[] = undefined;
	/**
	 * Standard audit trail information.
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Basic header information about a trip but only in
 * 				the context of Route Balancing
 */
export class RouteBalancingTrip {
	/**
	 * Unique instance ID for a trip
	 * Required: false
	 * Array: false
	 */
	tripInstId:number = undefined;
	/**
	 * Trip date
	 * Required: false
	 * Array: false
	 */
	tripDate:string = undefined;
	/**
	 * Status of the trip
	 * Required: false
	 * Array: false
	 */
	tripStatusCd:TripStatusCd = undefined;
	/**
	 * A timestamp of when the trip starts
	 * Required: false
	 * Array: false
	 */
	estimatedStartDateTime:Date = undefined;
	/**
	 * Estimated Empty Time. The estimated time that a
	 * 						DSR will have completed his delivery stops. His trailers will be
	 * 						empty and available to complete pickup stops
	 * Required: false
	 * Array: false
	 */
	estimatedEmptyDateTime:Date = undefined;
	/**
	 * The wait time (minutes) that the driver is idle between stops.
	 * Required: false
	 * Array: false
	 */
	idleWaitTime:number = undefined;
	/**
	 * List of routes
	 * Required: false
	 * Array: true
	 */
	routes:RouteBalancingRoute[] = undefined;
	/**
	 * The timestamp trip was last updated.
	 * Required: false
	 * Array: false
	 */
	lastUpdateTimestamp:Date = undefined;
}
/**
 * Basic header information about an activity for a
 * 				trip node but only in the context of trip metrics
 */
export class RouteBalancingActivity {
	/**
	 * Code that categorizes the Activity.Â  Examples:
	 * 						DL, PU, HE, etc.
	 * Required: false
	 * Array: false
	 */
	activityCd:TripNodeActivityCd = undefined;
	/**
	 * Total weight of Shipments at this activity
	 * Required: false
	 * Array: false
	 */
	totalWeight:number = undefined;
	/**
	 * Total motor moves of this activity
	 * Required: false
	 * Array: false
	 */
	totalMotorMoves:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	specialServices:ShipmentSpecialServiceCd[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
}
/**
 * Basic header information about a route but only in
 * 				the context of trip metrics
 */
export class RouteBalancingRoute {
	/**
	 * Required: false
	 * Array: false
	 */
	routeInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	routePrefix:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	routeSuffix:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	routeCategoryCd:RouteCategoryCd = undefined;
	/**
	 * Delivery route departure time goal for this route
	 * 						to leave the service center
	 * Required: false
	 * Array: false
	 */
	departTime:string = undefined;
	/**
	 * The identifier of the first pin trip node.
	 * Required: false
	 * Array: false
	 */
	firstStopNodeSequenceNumber:number = undefined;
	/**
	 * The identifier of the last pin trip node.
	 * Required: false
	 * Array: false
	 */
	lastStopNodeSequenceNumber:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	routeBalancingTypeCd:RouteBalancingTypeCd = undefined;
	/**
	 * List of stops
	 * Required: false
	 * Array: true
	 */
	stops:RouteBalancingStop[] = undefined;
	/**
	 * The timestamp route was last updated.
	 * Required: false
	 * Array: false
	 */
	lastUpdateTimestamp:Date = undefined;
	/**
	 * A list of conflicts that relate to the trip model or its nodes.
	 * Required: false
	 * Array: true
	 */
	conflicts:StopSequenceModelConflict[] = undefined;
}
/**
 * Basic header information about a stop including
 * 				activities and trip node information but only in the context of route balancing
 */
export class RouteBalancingStop {
	/**
	 * The trip node sequence number
	 * Required: false
	 * Array: false
	 */
	tripNodeSequenceNumber:number = undefined;
	/**
	 * The unique identifier for the trip node.
	 * Required: false
	 * Array: false
	 */
	tripNodeInstId:number = undefined;
	/**
	 * The customer name representing the tripNodeInstId.
	 * Required: false
	 * Array: false
	 */
	customerName:string = undefined;
	/**
	 * Indicates that a stop window will potentially be lost for a particular stop.
	 * Required: false
	 * Array: false
	 */
	potentialMissedStopWindowsInd:boolean = undefined;
	/**
	 * Indicates whether or not to include stop window information in auto-sequencing calculation for a particular stop.
	 * Required: false
	 * Array: false
	 */
	ignoreStopWindowInd:boolean = undefined;
	/**
	 * The type for the node.
	 * Required: false
	 * Array: false
	 */
	nodeTypeCd:NodeTypeCd = undefined;
	/**
	 * The type for the trip node
	 * Required: false
	 * Array: false
	 */
	tripNodeTypeCd:TripNodeTypeCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	estimatedArriveDateTime:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	estimatedDepartDateTime:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	estimatedArriveDateTimeLocal:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	estimatedDepartDateTimeLocal:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	planDistanceFromPreviousNode:number = undefined;
	/**
	 * The wait time (minutes) that the driver is idle between stops.
	 * Required: false
	 * Array: false
	 */
	idleWaitTime:number = undefined;
	/**
	 * List of activities for a trip node
	 * Required: false
	 * Array: true
	 */
	activities:RouteBalancingActivity[] = undefined;
	/**
	 * The geo coordinates of this Stop.
	 * Required: false
	 * Array: false
	 */
	geoCoordinate:LatLong = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	stopWindows:StopWindow[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	fromRoute:RouteIdentifier = undefined;
	/**
	 * The timestamp stop was last updated.
	 * Required: false
	 * Array: false
	 */
	lastUpdateTimestamp:Date = undefined;
}
export class RouteDetail {
	/**
	 * Required: false
	 * Array: false
	 */
	route:Route = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	trailer:Trailer = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	equipment:Equipment = undefined;
}
/**
 * 
 */
export class RouteEquipment {
	/**
	 * Required: false
	 * Array: false
	 */
	tripEquipment:TripEquipment = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	route:Route = undefined;
}
/**
 * The initial location of a Stop prior to any Route Balancing 
 */
export class RouteIdentifier {
	/**
	 * The instance id of of the trip that this stop was originally associated.
	 * Required: false
	 * Array: false
	 */
	tripInstId:number = undefined;
	/**
	 * The instance id of the route this stop was originally associated with
	 * Required: false
	 * Array: false
	 */
	routeInstId:number = undefined;
	/**
	 * The instance id of the route this stop was originally associated with
	 * Required: false
	 * Array: false
	 */
	tripNodeSequenceNbr:number = undefined;
}
/**
 * 
 */
export class RouteSequenceAdjustment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripNodeSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stopSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeSequenceNbr:number = undefined;
	/**
	 * A list of trip node sequence numbers.
	 * Required: false
	 * Array: true
	 */
	positionOnTrailerCodeList:string[] = undefined;
}
export class Stop {
	/**
	 * Required: false
	 * Array: false
	 */
	tripNode:TripNode = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	customer:InterfaceAcct = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	activities:Activity[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	stopWindowSummary:StopWindow[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	specialServicesSummary:ShipmentSpecialServiceSummary[] = undefined;
}
/**
 * Information about the tripModel and its nodes.
 */
export class StopSequenceModelData {
	/**
	 * The SIC code of the service center location.
	 * Required: false
	 * Array: false
	 */
	terminalSicCd:string = undefined;
	/**
	 * The geo coordinates of the service center location.
	 * Required: false
	 * Array: false
	 */
	terminalGeoCoordinates:LatLong = undefined;
	/**
	 * The time at which the wave departs.
	 * Required: false
	 * Array: false
	 */
	departureTime:string = undefined;
	/**
	 * The time at which the wave arrives.
	 * Required: false
	 * Array: false
	 */
	arrivalTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trip:StopSequenceModelTrip = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	stops:StopSequenceModelStop[] = undefined;
	/**
	 * A matrix of all distances between all the stops
	 * Required: false
	 * Array: false
	 */
	distanceMatrix:object = undefined;
	/**
	 * A list of conflicts that relate to the trip model or its nodes.
	 * Required: false
	 * Array: true
	 */
	conflicts:StopSequenceModelConflictCd[] = undefined;
}
/**
 * 
 */
export class StopSequenceModelStop {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripNodeSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nodeInstId:number = undefined;
	/**
	 * The customer name representing the nodeInstId.
	 * Required: false
	 * Array: false
	 */
	customerName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nodeTypeCd:NodeTypeCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripNodeTypeCd:TripNodeTypeCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stopSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalMotorMoves:number = undefined;
	/**
	 * Dwell time at this stop
	 * Required: false
	 * Array: false
	 */
	dwellTime:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	geoCoordinates:LatLong = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stopWindow:StopWindow = undefined;
}
/**
 * 
 */
export class StopSequenceModelTrip {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	terminalSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalMotorMoves:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedDepartDateTime:Date = undefined;
	/**
	 * The identifier of the first Trip Node that was a Stop on this Trip.
	 * Required: false
	 * Array: false
	 */
	firstStopNodeSequenceNbr:number = undefined;
	/**
	 * The identifier of the last Trip Node that was a Stop on this Trip.
	 * Required: false
	 * Array: false
	 */
	lastStopNodeSequenceNbr:number = undefined;
}
/**
 * Information about the trip and its nodes.
 */
export class StopSequenceTrip {
	/**
	 * Instance ID of trip if it exists
	 * Required: false
	 * Array: false
	 */
	tripInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedDepartDateTime:Date = undefined;
	/**
	 * The SIC code of the service center location.
	 * Required: false
	 * Array: false
	 */
	terminalSicCd:string = undefined;
	/**
	 * The geo coordinates of the service center location.
	 * Required: false
	 * Array: false
	 */
	terminalGeoCoordinates:LatLong = undefined;
	/**
	 * The identifier of the first Trip Node that was a Stop on this Trip.
	 * Required: false
	 * Array: false
	 */
	firstStopNodeSequenceNbr:number = undefined;
	/**
	 * The identifier of the last Trip Node that was a Stop on this Trip.
	 * Required: false
	 * Array: false
	 */
	lastStopNodeSequenceNbr:number = undefined;
	/**
	 * The identifier of the first unassigned stop. Specify only one of firstStopNodeSequenceNbr or firstUnassignedStopNodeSequenceNbr
	 * Required: false
	 * Array: false
	 */
	firstUnassignedStopNodeSequenceNbr:number = undefined;
	/**
	 * The identifier of the last unassigned stop.
	 * Required: false
	 * Array: false
	 */
	lastUnassignedStopNodeSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedEmptyDateTime:Date = undefined;
	/**
	 * The assigned stops to optimize the order.
	 * Required: false
	 * Array: true
	 */
	stops:Stop[] = undefined;
	/**
	 * Information about the unassigned shipments.
	 * Required: false
	 * Array: true
	 */
	unassignedStops:UnassignedStop[] = undefined;
	/**
	 * A list of conflicts that relate to the trip model or its nodes.
	 * Required: false
	 * Array: true
	 */
	conflicts:StopSequenceModelConflict[] = undefined;
}
/**
 * A derived entity that contains geo area information and suggested suffix for the sic/area. 
 */
export class SuggestedRouteName {
	/**
	 * Geo Area information for a given sic
	 * Required: false
	 * Array: false
	 */
	geoArea:GeoArea = undefined;
	/**
	 * The next suggested route suffix for the sic/area
	 * Required: false
	 * Array: false
	 */
	suggestedSuffix:string = undefined;
	/**
	 * The category code
	 * Required: false
	 * Array: false
	 */
	categoryCd:RouteCategoryCd = undefined;
	/**
	 * Is the route associated with the parent or a satellite
	 * Required: false
	 * Array: false
	 */
	isSatelliteIind:boolean = undefined;
}
export class Trailer {
	/**
	 * Required: false
	 * Array: false
	 */
	tripEquipment:TripEquipment = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	equipment:TripEquipment = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	trailerLoad:TrailerLoad = undefined;
}
/**
 * 
 */
export class TripDetail {
	/**
	 * Information about the trip.
	 * Required: false
	 * Array: false
	 */
	trip:Trip = undefined;
	/**
	 * Divides up the trip so multiple dispatchers can share the trip.
	 * Required: false
	 * Array: false
	 */
	dispatchGroup:DispatchGroup = undefined;
	/**
	 * Information about the employee driving the trip.
	 * Required: false
	 * Array: false
	 */
	tripDriver:TripDriver = undefined;
	/**
	 * Equipment information for tractors.
	 * Required: false
	 * Array: false
	 */
	tractorTripEquipment:TripEquipment = undefined;
	/**
	 * Equipment information for dollies.
	 * Required: false
	 * Array: true
	 */
	dolly:TripEquipment[] = undefined;
	/**
	 * Details about the routes.
	 * Required: false
	 * Array: true
	 */
	route:RouteDetail[] = undefined;
	/**
	 * Represents a stop at a location for a trip in the form of a list.
	 * Required: false
	 * Array: true
	 */
	tripStops:TripStop[] = undefined;
	/**
	 * Represents the metrics for a trip in the form of a list.
	 * Required: false
	 * Array: true
	 */
	metrics:Metric[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	specialServices:ShipmentSpecialServiceCd[] = undefined;
}
/**
 * Represents a stop at a location for a trip.
 */
export class TripStop {
	/**
	 * Represents an individual stop.
	 * Required: false
	 * Array: false
	 */
	tripNode:TripNode = undefined;
	/**
	 * The route associated with the stop.
	 * Required: false
	 * Array: false
	 */
	route:Route = undefined;
	/**
	 * Information about the consignee.
	 * Required: false
	 * Array: false
	 */
	cisInterfaceAcct:InterfaceAcct = undefined;
	/**
	 * Activities for the trip stop.
	 * Required: false
	 * Array: true
	 */
	activityTypes:TripNodeActivity[] = undefined;
	/**
	 * A list of times that freight can be delivered to the customer.
	 * Required: false
	 * Array: true
	 */
	stopWindows:StopWindow[] = undefined;
}
/**
 * A derived entity that is used for aggregation of Delivery Shipments by Consignee
 */
export class UnassignedStop {
	/**
	 * Required: false
	 * Array: false
	 */
	consignee:InterfaceAcct = undefined;
	/**
	 * The total number of shipments for this consignee
	 * Required: false
	 * Array: false
	 */
	totalShipmentsCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	motorizedPiecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loosePcsCnt:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalCubePercentage:number = undefined;
	/**
	 * Derived attribute representing the aggregated footprint of the shipment for planning a floor-loaded route.
	 * Required: false
	 * Array: false
	 */
	footprintPercentage:number = undefined;
	/**
	 * The current SicCd if all at the same SIC, otherwise a message indicating mixed SICs
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * If all of the service dates are the same, this will contain the Service Date.  If the service dates are different, this will indicate mixed dates.
	 * Required: false
	 * Array: false
	 */
	serviceDateMessage:string = undefined;
	/**
	 * The minimum ETA of the shipment arriving earliest for a stop at the destination servicecenter
	 * Required: false
	 * Array: false
	 */
	earliestDestSicEta:Date = undefined;
	/**
	 * The maximum ETA of the shipment arriving latest for a stop at the destination servicecenter
	 * Required: false
	 * Array: false
	 */
	latestDestSicEta:Date = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	deliveryQualifiers:DeliveryQualifierCd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	specialServiceSummary:ShipmentSpecialServiceSummary[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	stopWindow:StopWindow[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	deliveryShipments:DeliveryShipmentSearchRecord[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentLocationSicCd:string = undefined;
}
/**
 * A derived entity that is used for aggregation of pickups by instance ID.
 */
export class UnassignedPickup {
	/**
	 * Pickup Instance Id
	 * Required: false
	 * Array: false
	 */
	pickupInstId:number = undefined;
	/**
	 * The Call Nbr of the Pickup Request
	 * Required: false
	 * Array: false
	 */
	callNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	sourceCd:PickupSourceCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	typeCd:PickupTypeCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	statusCd:PickupStatusCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper:InterfaceAcct = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupDate:string = undefined;
	/**
	 * The open/ready time of the shipper
	 * Required: false
	 * Array: false
	 */
	readyTime:string = undefined;
	/**
	 * The close time of the shipper
	 * Required: false
	 * Array: false
	 */
	closeTime:string = undefined;
	/**
	 * The aggregated total of loose pieces for the pickup request
	 * Required: false
	 * Array: false
	 */
	totalLoosePcsCnt:number = undefined;
	/**
	 * The aggregated total of motor moves for the pickup request
	 * Required: false
	 * Array: false
	 */
	totalMotorizedPiecesCount:number = undefined;
	/**
	 * The aggregated total of weight for the pickup request
	 * Required: false
	 * Array: false
	 */
	totalWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalCubePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loosePcsCnt:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pkupTrmnlSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originalSicCd:string = undefined;
	/**
	 * Pickup Request remarks
	 * Required: false
	 * Array: false
	 */
	remarks:string = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	specialServiceSummary:ShipmentSpecialServiceCd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	pickupLines:PickupLineItemSearchRecord[] = undefined;
}
export class StopSequenceModelConflict {
	/**
	 * Required: false
	 * Array: false
	 */
	conflictCd:StopSequenceModelConflictCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
}
export class RouteFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	sicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	planDate:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	zoneInd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	plannerInd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	newReleaseInd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	categoryCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	statusCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	specialServices:XrtAttributeFilter = undefined;
}
export class RouteChangeRecord {
	/**
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	zoneInd:ZoneIndicatorCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	plannerInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	newReleaseInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	categoryCd:RouteCategoryCd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	statusCd:RouteStatusCd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	specialServices:ShipmentSpecialServiceCd[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	routeInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	record:Route = undefined;
}
/**
 * Request for register
 */
export class RegisterFilterRoutesRqst {
	/**
	 * Id of the  user. For future use.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The filter to register
	 * Required: false
	 * Array: false
	 */
	filter:RouteFilter = undefined;
}
/**
 * Response for register filter
 */
export class RegisterFilterRoutesResp {
	/**
	 * Hash for the filter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
	/**
	 * Duration for the API execution
	 * Required: false
	 * Array: false
	 */
	duration:number = undefined;
	/**
	 * Number of registrations created
	 * Required: false
	 * Array: false
	 */
	numberOfRegistrations:number = undefined;
}
/**
 * Request for register
 */
export class UnregisterFilterRoutesRqst {
	/**
	 * Id of the  user. For future use.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The hash of the filter to unregister. Filter has is returned by registerFilter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
}
/**
 * Request for changes feed
 */
export class ChangesFeedRoutesRqst {
	/**
	 * A list of document Ids
	 * Required: false
	 * Array: true
	 */
	changedDocumentIds:number[] = undefined;
	/**
	 * A list of changed documents
	 * Required: false
	 * Array: true
	 */
	changedDocuments:RouteChangeRecord[] = undefined;
}
export class TripDetailFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	sicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tripDate:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tripStatusCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	includeZoneCodeInd:XrtAttributeFilter = undefined;
}
export class TripDetailChangeRecord {
	/**
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tripDate:string = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	tripStatusCd:TripStatusCd[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	includeZoneCodeInd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tripInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	record:TripDetail = undefined;
}
/**
 * Request for register
 */
export class RegisterFilterTripsRqst {
	/**
	 * Id of the  user. For future use.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The filter to register
	 * Required: false
	 * Array: false
	 */
	filter:TripDetailFilter = undefined;
}
/**
 * Response for register filter
 */
export class RegisterFilterTripsResp {
	/**
	 * Hash for the filter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
	/**
	 * Duration for the API execution
	 * Required: false
	 * Array: false
	 */
	duration:number = undefined;
	/**
	 * Number of registrations created
	 * Required: false
	 * Array: false
	 */
	numberOfRegistrations:number = undefined;
}
/**
 * Request for register
 */
export class UnregisterFilterTripsRqst {
	/**
	 * Id of the  user. For future use.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The hash of the filter to unregister. Filter has is returned by registerFilter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
}
/**
 * Request for changes feed
 */
export class ChangesFeedTripsRqst {
	/**
	 * A list of document Ids
	 * Required: false
	 * Array: true
	 */
	changedDocumentIds:number[] = undefined;
	/**
	 * A list of changed documents
	 * Required: false
	 * Array: true
	 */
	changedDocuments:TripDetailChangeRecord[] = undefined;
}
export class MetricFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	sicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	planDate:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	roleCd:XrtAttributeFilter = undefined;
}
export class MetricChangeRecord {
	/**
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	roleCd:PnDRoleCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	metricId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	record:Metric = undefined;
}
/**
 * Request for register
 */
export class RegisterFilterServiceCenterMetricsRqst {
	/**
	 * Id of the  user. For future use.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The filter to register
	 * Required: false
	 * Array: false
	 */
	filter:MetricFilter = undefined;
}
/**
 * Response for register filter
 */
export class RegisterFilterServiceCenterMetricsResp {
	/**
	 * Hash for the filter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
	/**
	 * Duration for the API execution
	 * Required: false
	 * Array: false
	 */
	duration:number = undefined;
	/**
	 * Number of registrations created
	 * Required: false
	 * Array: false
	 */
	numberOfRegistrations:number = undefined;
}
/**
 * Request for register
 */
export class UnregisterFilterServiceCenterMetricsRqst {
	/**
	 * Id of the  user. For future use.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The hash of the filter to unregister. Filter has is returned by registerFilter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
}
/**
 * Request for changes feed
 */
export class ChangesFeedServiceCenterMetricsRqst {
	/**
	 * A list of document Ids
	 * Required: false
	 * Array: true
	 */
	changedDocumentIds:number[] = undefined;
	/**
	 * A list of changed documents
	 * Required: false
	 * Array: true
	 */
	changedDocuments:MetricChangeRecord[] = undefined;
}
/**
 * Path parameters for get Ensemble status counts
 */
export class GetEnsembleStatusCountsPath {
	/**
	 * Name of the queue
	 * Required: true
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * From timestamp
	 * Required: true
	 * Array: false
	 */
	fromTmst:Date = undefined;
	/**
	 * To timestamp
	 * Required: true
	 * Array: false
	 */
	toTmst:Date = undefined;
}
/**
 * Response to the request to get Ensemble status counts
 */
export class GetEnsembleStatusCountsResp {
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleMetrics:EnsembleMetrics = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	queueMetrics:QueueMetrics = undefined;
}
/**
 * Path parameters for get Ensemble status counts
 */
export class GetQueuePendingCountPath {
	/**
	 * Name of the queue
	 * Required: true
	 * Array: false
	 */
	queueName:string = undefined;
}
/**
 * Response for getQueuePendingCount
 */
export class GetQueuePendingCountResp {
	/**
	 * Number of messages in either ready or wait status. This count indicates the queue depth.
	 * Required: false
	 * Array: false
	 */
	pendingCount:number = undefined;
}
/**
 * Request to Restart execution of a Started or Not Started  Ensemble
 */
export class RestartEnsemblePath {
	/**
	 * Name of the queue
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * Unique identifier for the application workflow.
	 * Required: false
	 * Array: false
	 */
	ensembleInstId:string = undefined;
}
/**
 * Request to Restart execution of a Started or Not Started  Ensemble
 */
export class RestartEnsembleRqst {
}
/**
 * Response to the Request to Restart execution of a Started or Not Started  Ensemble
 */
export class RestartEnsembleResp {
	/**
	 * Ensemble that was Restarted.
	 * Required: false
	 * Array: false
	 */
	ensembleRestartedInd:boolean = undefined;
}
/**
 * Request to Resume execution of a Suspended ensemble
 */
export class ResumeEnsemblePath {
	/**
	 * Name of the queue
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * Unique identifier for the application workflow.
	 * Required: false
	 * Array: false
	 */
	ensembleInstId:string = undefined;
}
/**
 * Request to Resume execution of a Suspended ensemble
 */
export class ResumeEnsembleRqst {
}
/**
 * Response to the Request to Resume execution of a Suspended ensemble
 */
export class ResumeEnsembleResp {
	/**
	 * Ensemble that was Resumed
	 * Required: false
	 * Array: false
	 */
	ensembleResumedInd:boolean = undefined;
}
/**
 * Request to start ensemble.
 */
export class StartMessageDrivenEnsembleRqst {
	/**
	 * Required: true
	 * Array: false
	 */
	queue:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	messageId:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	message:string = undefined;
}
/**
 * Enter comments
 */
export class StartMessageDrivenEnsembleResp {
	/**
	 * Instance ID of the ensemble that was started.
	 * Required: false
	 * Array: false
	 */
	ensembleInstId:string = undefined;
}
/**
 * Request to stop execution of an Ensemble
 */
export class KillEnsembleRqst {
	/**
	 * Unique identifier for the ensemble.
	 * Required: true
	 * Array: true
	 */
	ensembleInstIds:string[] = undefined;
}
/**
 * Response to the request to stop execution of an Ensemble
 */
export class KillEnsembleResp {
	/**
	 * Ensembles that were killed.
	 * Required: false
	 * Array: true
	 */
	status:KillEnsembleStatus[] = undefined;
}
/**
 * Path parameters for stopQueue
 */
export class StopQueuePath {
	/**
	 * Name of the queue to stop
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
}
/**
 * Path parameters for stopQueue
 */
export class StopQueueViaWebhookPath {
	/**
	 * Name of the queue to stop
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
}
/**
 * Path parameters for stopQueue
 */
export class StopQueueViaWebhookQuery {
	/**
	 * Authorization token
	 * Required: false
	 * Array: false
	 */
	auth_token:string = undefined;
}
/**
 * Path parameters for startQueue
 */
export class StartQueuePath {
	/**
	 * Name of the queue to start
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
}
/**
 * Response for listQueues
 */
export class ListQueuesResp {
	/**
	 * Name of the queue to start
	 * Required: false
	 * Array: true
	 */
	queues:SymphonyQueue[] = undefined;
}
/**
 * Path parameters
 */
export class ListExpiredMessagesPath {
	/**
	 * Name of the queue
	 * Required: true
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * From enqueue timestamp
	 * Required: true
	 * Array: false
	 */
	enqueueFromTmst:Date = undefined;
	/**
	 * Tom enqueue timestamp
	 * Required: true
	 * Array: false
	 */
	enqueueToTmst:Date = undefined;
}
/**
 * Response
 */
export class ListExpiredMessagesResp {
	/**
	 * Name of the queue
	 * Required: false
	 * Array: true
	 */
	message:SymphonyMessage[] = undefined;
}
/**
 * Path parameters
 */
export class DeleteExpiredMessagesPath {
	/**
	 * Name of the queue
	 * Required: true
	 * Array: false
	 */
	queueName:string = undefined;
}
/**
 * Path parameters
 */
export class DeleteExpiredMessagesQuery {
	/**
	 * Id of the message to be deleted
	 * Required: true
	 * Array: true
	 */
	messageId:string[] = undefined;
}
/**
 * Response to the request to stop execution of an Ensemble
 */
export class DeleteExpiredMessagesResp {
	/**
	 * Ensembles that were killed.
	 * Required: false
	 * Array: true
	 */
	status:DeleteExpiredMessageStatus[] = undefined;
}
/**
 * Path parameters
 */
export class ReplayMessagePath {
	/**
	 * Name of the queue
	 * Required: true
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * ID of the message to replay. It needs to be in expired status
	 * Required: true
	 * Array: false
	 */
	messageId:string = undefined;
}
export class MessageDrivenEnsemblePayload {
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventType:string = undefined;
	/**
	 * The event occurrence timestamp
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * The event occurrence timestamp in microseconds
	 * Required: false
	 * Array: false
	 */
	eventTmstMicro:number = undefined;
	/**
	 * The correlation ID of the originating transaction
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * The event data. Use JSON format with previous and current values of all attributes that were changed by this event
	 * Required: false
	 * Array: false
	 */
	eventData:object = undefined;
}
/**
 * Status of a delete expired message request
 */
export class DeleteExpiredMessageStatus {
	/**
	 * Unique identifier for the ensemble record.
	 * Required: false
	 * Array: false
	 */
	messageId:string = undefined;
	/**
	 * Indicates whether the ensemble was killed.
	 * Required: false
	 * Array: false
	 */
	messageDeletedInd:boolean = undefined;
	/**
	 * A status describing why the message could not be deleted. Populated if messageDeletedInd is false
	 * Required: false
	 * Array: false
	 */
	failStatus:string = undefined;
}
/**
 * Status of a kill ensemble request
 */
export class KillEnsembleStatus {
	/**
	 * Unique identifier for the ensemble record.
	 * Required: false
	 * Array: false
	 */
	ensembleInstId:string = undefined;
	/**
	 * Indicates whether the ensemble was killed.
	 * Required: false
	 * Array: false
	 */
	ensembleKilledInd:boolean = undefined;
	/**
	 * A status describing why the ensemble could not be killed. Populated if ensembleKilledInd is false
	 * Required: false
	 * Array: false
	 */
	failStatus:string = undefined;
}
/**
 * Stores execution information for application workflows.
 */
export class EnsembleStateSearchRecord {
	/**
	 * Unique identifier for the ensemble record.
	 * Required: false
	 * Array: false
	 */
	instId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState:EnsembleState = undefined;
}
export class Payload {
	/**
	 * Required: true
	 * Array: false
	 */
	type:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	value:object = undefined;
}
export class EnsembleState {
	/**
	 * Unique identifier for the ensemble record.
	 * Required: false
	 * Array: false
	 */
	instId:string = undefined;
	/**
	 * Name of the application workflow.
	 * Required: false
	 * Array: false
	 */
	name:string = undefined;
	/**
	 * A short description of the ensemble
	 * Required: false
	 * Array: false
	 */
	desc:string = undefined;
	/**
	 * Name of the queue publishing this message.
	 * Required: false
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * Id of the message.
	 * Required: false
	 * Array: false
	 */
	messageId:string = undefined;
	/**
	 * The timestamp of when the trigger event was created.
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * The timestamp of when the message was received by ensemble.
	 * Required: false
	 * Array: false
	 */
	recvTmst:Date = undefined;
	/**
	 * The timestamp of when this ensemble was dispatched to the executor thread.
	 * Required: false
	 * Array: false
	 */
	dispatchTmst:Date = undefined;
	/**
	 * The timestamp of when this ensemble processing started.
	 * Required: false
	 * Array: false
	 */
	strtTmst:Date = undefined;
	/**
	 * The timestamp of when this ensemble processing ended.
	 * Required: false
	 * Array: false
	 */
	endTmst:Date = undefined;
	/**
	 * Dequeue time in milliseconds. Populated for started ensembles, and is the difference between the received and event timestamps.
	 * Required: false
	 * Array: false
	 */
	dequeueTime:number = undefined;
	/**
	 * Dispatch time in milliseconds. Populated for started ensembles, and is the difference between the dispatch and received timestamps.
	 * Required: false
	 * Array: false
	 */
	dispatchTime:number = undefined;
	/**
	 * Wait time in milliseconds. Populated for started ensembles, and is the difference between the start and dispatch timestamps.
	 * Required: false
	 * Array: false
	 */
	waitTime:number = undefined;
	/**
	 * Time in milliseconds it took to process this ensemble from the time it was started. Populated for completed ensembles, and is the difference between the end and start timestamps.
	 * Required: false
	 * Array: false
	 */
	processTime:number = undefined;
	/**
	 * Total elapsed time in milliseconds to process this ensemble from the time the event was created. Populated for completed ensembles, and is the difference between the start and event timestamps.
	 * Required: false
	 * Array: false
	 */
	totalTime:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lstUpdtTmst:Date = undefined;
	/**
	 * Status of workflow execution.  
	 * 
	 * Valid values: 
	 * NOT_STARTED 
	 * STARTED
	 * SUSPENDED 
	 * COMPLETED
	 * Required: false
	 * Array: false
	 */
	statCd:EnsembleExecutionStatusCd = undefined;
	/**
	 * Status message for the workflow execution.  This could be an error message, stacktrace, success message, etc.
	 * Required: false
	 * Array: false
	 */
	statMsg:string = undefined;
	/**
	 * Same value for CORRELATION 
	 * 
	 * id is assigned to various rows from different tables that form a single business 
	 * 
	 * transactions. 
	 * In other words, this groups multiple rows across various tables for a single business 
	 * 
	 * transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Application key value like pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * Name of the host on which the ensemble was last processed
	 * Required: false
	 * Array: false
	 */
	host:string = undefined;
	/**
	 * Name of the thread on which the ensemble was last processed
	 * Required: false
	 * Array: false
	 */
	thread:string = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	minuetStates:MinuetState[] = undefined;
	/**
	 * Payload that is input to the ensemble execution.
	 * Required: false
	 * Array: false
	 */
	payload:Payload = undefined;
}
/**
 * Stores execution information for the tasks that compose the application workflow.
 */
export class MinuetState {
	/**
	 * Name of the task.
	 * Required: false
	 * Array: false
	 */
	name:string = undefined;
	/**
	 * Response payload that is output  from the task.
	 * Required: false
	 * Array: false
	 */
	respPayload:Payload = undefined;
	/**
	 * Datetime the task execution started
	 * Required: false
	 * Array: false
	 */
	strtTmst:Date = undefined;
	/**
	 * Datetime the task execution ended
	 * Required: false
	 * Array: false
	 */
	endTmst:Date = undefined;
	/**
	 * Time in milliseconds it took to process this minuet from the time it was started. Populated for completed minuets, and is the difference between the end and start timestamps.
	 * Required: false
	 * Array: false
	 */
	processTime:number = undefined;
	/**
	 * Number of times this minuet was retried
	 * Required: false
	 * Array: false
	 */
	executionCount:number = undefined;
	/**
	 * Status of task execution.  
	 * 
	 * Valid values:  
	 * STARTED
	 * FAILED 
	 * COMPLETED
	 * Required: false
	 * Array: false
	 */
	statCd:MinuetExecutionStatusCd = undefined;
	/**
	 * Status message for the task.  This could be an error message, stacktrace, success message, etc.
	 * Required: false
	 * Array: false
	 */
	statMsg:string = undefined;
}
export class EnsembleMetrics {
	/**
	 * Required: false
	 * Array: false
	 */
	notStartedCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	startedCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	completedCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	suspendedCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	failedCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	killedCount:number = undefined;
}
export class QueueMetrics {
	/**
	 * Required: false
	 * Array: false
	 */
	readyCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	waitCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	processedCount:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	expiredCount:number = undefined;
}
export class ScoDlvrShmMessageDrivenEnsemblePayload {
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventType:string = undefined;
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * The event occurrence timestamp in microseconds
	 * Required: false
	 * Array: false
	 */
	eventTmstMicro:number = undefined;
	/**
	 * The correlation ID of the originating transaction
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * The event data. Use JSON format with previous and current values of all attributes that were changed by this event
	 * Required: false
	 * Array: false
	 */
	eventData:DeliveryShipmentSnapshot = undefined;
}
export class ScoEventMessageDrivenEnsemblePayload {
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventType:string = undefined;
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * The event occurrence timestamp in microseconds
	 * Required: false
	 * Array: false
	 */
	eventTmstMicro:number = undefined;
	/**
	 * The correlation ID of the originating transaction
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * The event data. Use JSON format with previous and current values of all attributes that were changed by this event
	 * Required: false
	 * Array: false
	 */
	eventData:ScoEventSnapshot = undefined;
}
export class IntrfcAcctMessageDrivenEnsemblePayload {
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventType:string = undefined;
	/**
	 * The type of event. This will determine which ensemble to execute
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * The event occurrence timestamp in microseconds
	 * Required: false
	 * Array: false
	 */
	eventTmstMicro:number = undefined;
	/**
	 * The correlation ID of the originating transaction
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * pro, shipid, pickupdate etc.
	 * Required: false
	 * Array: false
	 */
	businessKey5:string = undefined;
	/**
	 * The event data. Use JSON format with previous and current values of all attributes that were changed by this event
	 * Required: false
	 * Array: false
	 */
	eventData:InterfaceAcctSnapshot = undefined;
}
export class DeliveryShipmentSearchFilterNot {
	/**
	 * Required: false
	 * Array: true
	 */
	and:DeliveryShipmentSearchFilterAnd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	or:DeliveryShipmentSearchFilterOr[] = undefined;
}
export class DeliveryShipmentSearchFilterOr {
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	currentSicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	currentSicCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	hostDestSicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	hostDestSicCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destinationSicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destinationSicCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	scheduleDestinationSicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	scheduleDestinationSicCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	scheduleETA:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	scheduleETA_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	estimatedDeliveryDate:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	estimatedDeliveryDate_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	currentTrailer:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	currentTrailer_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	specialServiceSummary_specialService:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	specialServiceSummary_specialService_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_geoCoordinates:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_geoCoordinates_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	routeInstId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	routeInstId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destSicEta:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destSicEta_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentPndStatus:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentPndStatus_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destSicEtaLastUpdtTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destSicEtaLastUpdtTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupDt:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupDt_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	originSic:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	originSic_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	responsibleSic:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	responsibleSic_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	suppRefNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	suppRefNbr_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_acctInstId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_acctInstId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_name1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_name1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_name2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_name2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_addressLine1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_addressLine1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_addressLine2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_addressLine2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_cityName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_cityName_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_stateCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_stateCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_postalCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_postalCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_countryCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_countryCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_acctMadCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_acctMadCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_acctInstId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_acctInstId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_name1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_name1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_name2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_name2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_addressLine1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_addressLine1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_addressLine2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_addressLine2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_cityName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_cityName_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_stateCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_stateCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_postalCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_postalCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_countryCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_countryCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_acctMadCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_acctMadCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_acctInstId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_acctInstId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_name1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_name1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_name2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_name2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_addressLine1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_addressLine1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_addressLine2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_addressLine2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_cityName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_cityName_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_stateCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_stateCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_postalCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_postalCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_countryCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_countryCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_acctMadCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_acctMadCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	movementStatusCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	movementStatusCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	movementExceptionTypeCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	movementExceptionTypeCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	deliveryQualifierCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	deliveryQualifierCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	apptDtls_scheduledDeliveryDate:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	apptDtls_scheduledDeliveryDate_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	apptDtls_scheduledDeliveryFromTime:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	apptDtls_scheduledDeliveryFromTime_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	apptDtls_scheduledDeliveryToTime:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	apptDtls_scheduledDeliveryToTime_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcDateTypeCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcDateTypeCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcDate1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcDate1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcDate2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcDate2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcTimeTypeCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcTimeTypeCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcTime1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcTime1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcTime2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcTime2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	correctiveAction:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	correctiveAction_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billClassCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billClassCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	apptReq:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	apptReq_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	withinTdcCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	withinTdcCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destinationArrivalDateTime:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destinationArrivalDateTime_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentLocationSicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentLocationSicCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	actualDeliveryDateTime:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	actualDeliveryDateTime_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	onTimeDeliveryCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	onTimeDeliveryCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	afterHoursPickupInd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	afterHoursPickupInd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperRefNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperRefNbr_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	movementExcpActionReason:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	movementExcpActionReason_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	lstMvmtTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	lstMvmtTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentPurgedInd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentPurgedInd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	and:DeliveryShipmentSearchFilterAnd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	not:DeliveryShipmentSearchFilterNot[] = undefined;
	public toJSON = function(this: DeliveryShipmentSearchFilterOr) {
		return {
			"proNbr" : this.proNbr,
			"proNbr.keyword" : this.proNbr_keyword,
			"currentSicCd" : this.currentSicCd,
			"currentSicCd.keyword" : this.currentSicCd_keyword,
			"hostDestSicCd" : this.hostDestSicCd,
			"hostDestSicCd.keyword" : this.hostDestSicCd_keyword,
			"destinationSicCd" : this.destinationSicCd,
			"destinationSicCd.keyword" : this.destinationSicCd_keyword,
			"scheduleDestinationSicCd" : this.scheduleDestinationSicCd,
			"scheduleDestinationSicCd.keyword" : this.scheduleDestinationSicCd_keyword,
			"scheduleETA" : this.scheduleETA,
			"scheduleETA.keyword" : this.scheduleETA_keyword,
			"estimatedDeliveryDate" : this.estimatedDeliveryDate,
			"estimatedDeliveryDate.keyword" : this.estimatedDeliveryDate_keyword,
			"currentTrailer" : this.currentTrailer,
			"currentTrailer.keyword" : this.currentTrailer_keyword,
			"specialServiceSummary.specialService" : this.specialServiceSummary_specialService,
			"specialServiceSummary.specialService.keyword" : this.specialServiceSummary_specialService_keyword,
			"consignee.geoCoordinates" : this.consignee_geoCoordinates,
			"consignee.geoCoordinates.keyword" : this.consignee_geoCoordinates_keyword,
			"routeInstId" : this.routeInstId,
			"routeInstId.keyword" : this.routeInstId_keyword,
			"destSicEta" : this.destSicEta,
			"destSicEta.keyword" : this.destSicEta_keyword,
			"shipmentPndStatus" : this.shipmentPndStatus,
			"shipmentPndStatus.keyword" : this.shipmentPndStatus_keyword,
			"destSicEtaLastUpdtTmst" : this.destSicEtaLastUpdtTmst,
			"destSicEtaLastUpdtTmst.keyword" : this.destSicEtaLastUpdtTmst_keyword,
			"pickupDt" : this.pickupDt,
			"pickupDt.keyword" : this.pickupDt_keyword,
			"originSic" : this.originSic,
			"originSic.keyword" : this.originSic_keyword,
			"responsibleSic" : this.responsibleSic,
			"responsibleSic.keyword" : this.responsibleSic_keyword,
			"suppRefNbr" : this.suppRefNbr,
			"suppRefNbr.keyword" : this.suppRefNbr_keyword,
			"consignee.acctInstId" : this.consignee_acctInstId,
			"consignee.acctInstId.keyword" : this.consignee_acctInstId_keyword,
			"consignee.name1" : this.consignee_name1,
			"consignee.name1.keyword" : this.consignee_name1_keyword,
			"consignee.name2" : this.consignee_name2,
			"consignee.name2.keyword" : this.consignee_name2_keyword,
			"consignee.addressLine1" : this.consignee_addressLine1,
			"consignee.addressLine1.keyword" : this.consignee_addressLine1_keyword,
			"consignee.addressLine2" : this.consignee_addressLine2,
			"consignee.addressLine2.keyword" : this.consignee_addressLine2_keyword,
			"consignee.cityName" : this.consignee_cityName,
			"consignee.cityName.keyword" : this.consignee_cityName_keyword,
			"consignee.stateCd" : this.consignee_stateCd,
			"consignee.stateCd.keyword" : this.consignee_stateCd_keyword,
			"consignee.postalCd" : this.consignee_postalCd,
			"consignee.postalCd.keyword" : this.consignee_postalCd_keyword,
			"consignee.countryCd" : this.consignee_countryCd,
			"consignee.countryCd.keyword" : this.consignee_countryCd_keyword,
			"consignee.acctMadCd" : this.consignee_acctMadCd,
			"consignee.acctMadCd.keyword" : this.consignee_acctMadCd_keyword,
			"billTo.acctInstId" : this.billTo_acctInstId,
			"billTo.acctInstId.keyword" : this.billTo_acctInstId_keyword,
			"billTo.name1" : this.billTo_name1,
			"billTo.name1.keyword" : this.billTo_name1_keyword,
			"billTo.name2" : this.billTo_name2,
			"billTo.name2.keyword" : this.billTo_name2_keyword,
			"billTo.addressLine1" : this.billTo_addressLine1,
			"billTo.addressLine1.keyword" : this.billTo_addressLine1_keyword,
			"billTo.addressLine2" : this.billTo_addressLine2,
			"billTo.addressLine2.keyword" : this.billTo_addressLine2_keyword,
			"billTo.cityName" : this.billTo_cityName,
			"billTo.cityName.keyword" : this.billTo_cityName_keyword,
			"billTo.stateCd" : this.billTo_stateCd,
			"billTo.stateCd.keyword" : this.billTo_stateCd_keyword,
			"billTo.postalCd" : this.billTo_postalCd,
			"billTo.postalCd.keyword" : this.billTo_postalCd_keyword,
			"billTo.countryCd" : this.billTo_countryCd,
			"billTo.countryCd.keyword" : this.billTo_countryCd_keyword,
			"billTo.acctMadCd" : this.billTo_acctMadCd,
			"billTo.acctMadCd.keyword" : this.billTo_acctMadCd_keyword,
			"shipper.acctInstId" : this.shipper_acctInstId,
			"shipper.acctInstId.keyword" : this.shipper_acctInstId_keyword,
			"shipper.name1" : this.shipper_name1,
			"shipper.name1.keyword" : this.shipper_name1_keyword,
			"shipper.name2" : this.shipper_name2,
			"shipper.name2.keyword" : this.shipper_name2_keyword,
			"shipper.addressLine1" : this.shipper_addressLine1,
			"shipper.addressLine1.keyword" : this.shipper_addressLine1_keyword,
			"shipper.addressLine2" : this.shipper_addressLine2,
			"shipper.addressLine2.keyword" : this.shipper_addressLine2_keyword,
			"shipper.cityName" : this.shipper_cityName,
			"shipper.cityName.keyword" : this.shipper_cityName_keyword,
			"shipper.stateCd" : this.shipper_stateCd,
			"shipper.stateCd.keyword" : this.shipper_stateCd_keyword,
			"shipper.postalCd" : this.shipper_postalCd,
			"shipper.postalCd.keyword" : this.shipper_postalCd_keyword,
			"shipper.countryCd" : this.shipper_countryCd,
			"shipper.countryCd.keyword" : this.shipper_countryCd_keyword,
			"shipper.acctMadCd" : this.shipper_acctMadCd,
			"shipper.acctMadCd.keyword" : this.shipper_acctMadCd_keyword,
			"movementStatusCd" : this.movementStatusCd,
			"movementStatusCd.keyword" : this.movementStatusCd_keyword,
			"movementExceptionTypeCd" : this.movementExceptionTypeCd,
			"movementExceptionTypeCd.keyword" : this.movementExceptionTypeCd_keyword,
			"deliveryQualifierCd" : this.deliveryQualifierCd,
			"deliveryQualifierCd.keyword" : this.deliveryQualifierCd_keyword,
			"apptDtls.scheduledDeliveryDate" : this.apptDtls_scheduledDeliveryDate,
			"apptDtls.scheduledDeliveryDate.keyword" : this.apptDtls_scheduledDeliveryDate_keyword,
			"apptDtls.scheduledDeliveryFromTime" : this.apptDtls_scheduledDeliveryFromTime,
			"apptDtls.scheduledDeliveryFromTime.keyword" : this.apptDtls_scheduledDeliveryFromTime_keyword,
			"apptDtls.scheduledDeliveryToTime" : this.apptDtls_scheduledDeliveryToTime,
			"apptDtls.scheduledDeliveryToTime.keyword" : this.apptDtls_scheduledDeliveryToTime_keyword,
			"tdc.tdcDateTypeCd" : this.tdc_tdcDateTypeCd,
			"tdc.tdcDateTypeCd.keyword" : this.tdc_tdcDateTypeCd_keyword,
			"tdc.tdcDate1" : this.tdc_tdcDate1,
			"tdc.tdcDate1.keyword" : this.tdc_tdcDate1_keyword,
			"tdc.tdcDate2" : this.tdc_tdcDate2,
			"tdc.tdcDate2.keyword" : this.tdc_tdcDate2_keyword,
			"tdc.tdcTimeTypeCd" : this.tdc_tdcTimeTypeCd,
			"tdc.tdcTimeTypeCd.keyword" : this.tdc_tdcTimeTypeCd_keyword,
			"tdc.tdcTime1" : this.tdc_tdcTime1,
			"tdc.tdcTime1.keyword" : this.tdc_tdcTime1_keyword,
			"tdc.tdcTime2" : this.tdc_tdcTime2,
			"tdc.tdcTime2.keyword" : this.tdc_tdcTime2_keyword,
			"correctiveAction" : this.correctiveAction,
			"correctiveAction.keyword" : this.correctiveAction_keyword,
			"billClassCd" : this.billClassCd,
			"billClassCd.keyword" : this.billClassCd_keyword,
			"apptReq" : this.apptReq,
			"apptReq.keyword" : this.apptReq_keyword,
			"withinTdcCd" : this.withinTdcCd,
			"withinTdcCd.keyword" : this.withinTdcCd_keyword,
			"destinationArrivalDateTime" : this.destinationArrivalDateTime,
			"destinationArrivalDateTime.keyword" : this.destinationArrivalDateTime_keyword,
			"shipmentLocationSicCd" : this.shipmentLocationSicCd,
			"shipmentLocationSicCd.keyword" : this.shipmentLocationSicCd_keyword,
			"actualDeliveryDateTime" : this.actualDeliveryDateTime,
			"actualDeliveryDateTime.keyword" : this.actualDeliveryDateTime_keyword,
			"onTimeDeliveryCd" : this.onTimeDeliveryCd,
			"onTimeDeliveryCd.keyword" : this.onTimeDeliveryCd_keyword,
			"afterHoursPickupInd" : this.afterHoursPickupInd,
			"afterHoursPickupInd.keyword" : this.afterHoursPickupInd_keyword,
			"shipperRefNbr" : this.shipperRefNbr,
			"shipperRefNbr.keyword" : this.shipperRefNbr_keyword,
			"movementExcpActionReason" : this.movementExcpActionReason,
			"movementExcpActionReason.keyword" : this.movementExcpActionReason_keyword,
			"lstMvmtTmst" : this.lstMvmtTmst,
			"lstMvmtTmst.keyword" : this.lstMvmtTmst_keyword,
			"shipmentPurgedInd" : this.shipmentPurgedInd,
			"shipmentPurgedInd.keyword" : this.shipmentPurgedInd_keyword,
			"and" : this.and,
			"not" : this.not		}
	}
}
export class DeliveryShipmentSearchFilterAnd {
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	currentSicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	currentSicCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	hostDestSicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	hostDestSicCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destinationSicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destinationSicCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	scheduleDestinationSicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	scheduleDestinationSicCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	scheduleETA:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	scheduleETA_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	estimatedDeliveryDate:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	estimatedDeliveryDate_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	currentTrailer:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	currentTrailer_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	specialServiceSummary_specialService:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	specialServiceSummary_specialService_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_geoCoordinates:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_geoCoordinates_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	routeInstId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	routeInstId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destSicEta:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destSicEta_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentPndStatus:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentPndStatus_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destSicEtaLastUpdtTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destSicEtaLastUpdtTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupDt:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupDt_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	originSic:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	originSic_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	responsibleSic:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	responsibleSic_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	suppRefNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	suppRefNbr_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_acctInstId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_acctInstId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_name1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_name1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_name2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_name2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_addressLine1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_addressLine1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_addressLine2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_addressLine2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_cityName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_cityName_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_stateCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_stateCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_postalCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_postalCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_countryCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_countryCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_acctMadCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_acctMadCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_acctInstId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_acctInstId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_name1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_name1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_name2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_name2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_addressLine1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_addressLine1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_addressLine2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_addressLine2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_cityName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_cityName_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_stateCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_stateCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_postalCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_postalCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_countryCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_countryCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_acctMadCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_acctMadCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_acctInstId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_acctInstId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_name1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_name1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_name2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_name2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_addressLine1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_addressLine1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_addressLine2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_addressLine2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_cityName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_cityName_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_stateCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_stateCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_postalCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_postalCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_countryCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_countryCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_acctMadCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_acctMadCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	movementStatusCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	movementStatusCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	movementExceptionTypeCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	movementExceptionTypeCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	deliveryQualifierCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	deliveryQualifierCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	apptDtls_scheduledDeliveryDate:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	apptDtls_scheduledDeliveryDate_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	apptDtls_scheduledDeliveryFromTime:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	apptDtls_scheduledDeliveryFromTime_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	apptDtls_scheduledDeliveryToTime:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	apptDtls_scheduledDeliveryToTime_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcDateTypeCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcDateTypeCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcDate1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcDate1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcDate2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcDate2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcTimeTypeCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcTimeTypeCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcTime1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcTime1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcTime2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcTime2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	correctiveAction:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	correctiveAction_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billClassCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billClassCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	apptReq:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	apptReq_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	withinTdcCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	withinTdcCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destinationArrivalDateTime:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destinationArrivalDateTime_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentLocationSicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentLocationSicCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	actualDeliveryDateTime:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	actualDeliveryDateTime_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	onTimeDeliveryCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	onTimeDeliveryCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	afterHoursPickupInd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	afterHoursPickupInd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperRefNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperRefNbr_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	movementExcpActionReason:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	movementExcpActionReason_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	lstMvmtTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	lstMvmtTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentPurgedInd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentPurgedInd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	or:DeliveryShipmentSearchFilterOr[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	not:DeliveryShipmentSearchFilterNot[] = undefined;
	public toJSON = function(this: DeliveryShipmentSearchFilterAnd) {
		return {
			"proNbr" : this.proNbr,
			"proNbr.keyword" : this.proNbr_keyword,
			"currentSicCd" : this.currentSicCd,
			"currentSicCd.keyword" : this.currentSicCd_keyword,
			"hostDestSicCd" : this.hostDestSicCd,
			"hostDestSicCd.keyword" : this.hostDestSicCd_keyword,
			"destinationSicCd" : this.destinationSicCd,
			"destinationSicCd.keyword" : this.destinationSicCd_keyword,
			"scheduleDestinationSicCd" : this.scheduleDestinationSicCd,
			"scheduleDestinationSicCd.keyword" : this.scheduleDestinationSicCd_keyword,
			"scheduleETA" : this.scheduleETA,
			"scheduleETA.keyword" : this.scheduleETA_keyword,
			"estimatedDeliveryDate" : this.estimatedDeliveryDate,
			"estimatedDeliveryDate.keyword" : this.estimatedDeliveryDate_keyword,
			"currentTrailer" : this.currentTrailer,
			"currentTrailer.keyword" : this.currentTrailer_keyword,
			"specialServiceSummary.specialService" : this.specialServiceSummary_specialService,
			"specialServiceSummary.specialService.keyword" : this.specialServiceSummary_specialService_keyword,
			"consignee.geoCoordinates" : this.consignee_geoCoordinates,
			"consignee.geoCoordinates.keyword" : this.consignee_geoCoordinates_keyword,
			"routeInstId" : this.routeInstId,
			"routeInstId.keyword" : this.routeInstId_keyword,
			"destSicEta" : this.destSicEta,
			"destSicEta.keyword" : this.destSicEta_keyword,
			"shipmentPndStatus" : this.shipmentPndStatus,
			"shipmentPndStatus.keyword" : this.shipmentPndStatus_keyword,
			"destSicEtaLastUpdtTmst" : this.destSicEtaLastUpdtTmst,
			"destSicEtaLastUpdtTmst.keyword" : this.destSicEtaLastUpdtTmst_keyword,
			"pickupDt" : this.pickupDt,
			"pickupDt.keyword" : this.pickupDt_keyword,
			"originSic" : this.originSic,
			"originSic.keyword" : this.originSic_keyword,
			"responsibleSic" : this.responsibleSic,
			"responsibleSic.keyword" : this.responsibleSic_keyword,
			"suppRefNbr" : this.suppRefNbr,
			"suppRefNbr.keyword" : this.suppRefNbr_keyword,
			"consignee.acctInstId" : this.consignee_acctInstId,
			"consignee.acctInstId.keyword" : this.consignee_acctInstId_keyword,
			"consignee.name1" : this.consignee_name1,
			"consignee.name1.keyword" : this.consignee_name1_keyword,
			"consignee.name2" : this.consignee_name2,
			"consignee.name2.keyword" : this.consignee_name2_keyword,
			"consignee.addressLine1" : this.consignee_addressLine1,
			"consignee.addressLine1.keyword" : this.consignee_addressLine1_keyword,
			"consignee.addressLine2" : this.consignee_addressLine2,
			"consignee.addressLine2.keyword" : this.consignee_addressLine2_keyword,
			"consignee.cityName" : this.consignee_cityName,
			"consignee.cityName.keyword" : this.consignee_cityName_keyword,
			"consignee.stateCd" : this.consignee_stateCd,
			"consignee.stateCd.keyword" : this.consignee_stateCd_keyword,
			"consignee.postalCd" : this.consignee_postalCd,
			"consignee.postalCd.keyword" : this.consignee_postalCd_keyword,
			"consignee.countryCd" : this.consignee_countryCd,
			"consignee.countryCd.keyword" : this.consignee_countryCd_keyword,
			"consignee.acctMadCd" : this.consignee_acctMadCd,
			"consignee.acctMadCd.keyword" : this.consignee_acctMadCd_keyword,
			"billTo.acctInstId" : this.billTo_acctInstId,
			"billTo.acctInstId.keyword" : this.billTo_acctInstId_keyword,
			"billTo.name1" : this.billTo_name1,
			"billTo.name1.keyword" : this.billTo_name1_keyword,
			"billTo.name2" : this.billTo_name2,
			"billTo.name2.keyword" : this.billTo_name2_keyword,
			"billTo.addressLine1" : this.billTo_addressLine1,
			"billTo.addressLine1.keyword" : this.billTo_addressLine1_keyword,
			"billTo.addressLine2" : this.billTo_addressLine2,
			"billTo.addressLine2.keyword" : this.billTo_addressLine2_keyword,
			"billTo.cityName" : this.billTo_cityName,
			"billTo.cityName.keyword" : this.billTo_cityName_keyword,
			"billTo.stateCd" : this.billTo_stateCd,
			"billTo.stateCd.keyword" : this.billTo_stateCd_keyword,
			"billTo.postalCd" : this.billTo_postalCd,
			"billTo.postalCd.keyword" : this.billTo_postalCd_keyword,
			"billTo.countryCd" : this.billTo_countryCd,
			"billTo.countryCd.keyword" : this.billTo_countryCd_keyword,
			"billTo.acctMadCd" : this.billTo_acctMadCd,
			"billTo.acctMadCd.keyword" : this.billTo_acctMadCd_keyword,
			"shipper.acctInstId" : this.shipper_acctInstId,
			"shipper.acctInstId.keyword" : this.shipper_acctInstId_keyword,
			"shipper.name1" : this.shipper_name1,
			"shipper.name1.keyword" : this.shipper_name1_keyword,
			"shipper.name2" : this.shipper_name2,
			"shipper.name2.keyword" : this.shipper_name2_keyword,
			"shipper.addressLine1" : this.shipper_addressLine1,
			"shipper.addressLine1.keyword" : this.shipper_addressLine1_keyword,
			"shipper.addressLine2" : this.shipper_addressLine2,
			"shipper.addressLine2.keyword" : this.shipper_addressLine2_keyword,
			"shipper.cityName" : this.shipper_cityName,
			"shipper.cityName.keyword" : this.shipper_cityName_keyword,
			"shipper.stateCd" : this.shipper_stateCd,
			"shipper.stateCd.keyword" : this.shipper_stateCd_keyword,
			"shipper.postalCd" : this.shipper_postalCd,
			"shipper.postalCd.keyword" : this.shipper_postalCd_keyword,
			"shipper.countryCd" : this.shipper_countryCd,
			"shipper.countryCd.keyword" : this.shipper_countryCd_keyword,
			"shipper.acctMadCd" : this.shipper_acctMadCd,
			"shipper.acctMadCd.keyword" : this.shipper_acctMadCd_keyword,
			"movementStatusCd" : this.movementStatusCd,
			"movementStatusCd.keyword" : this.movementStatusCd_keyword,
			"movementExceptionTypeCd" : this.movementExceptionTypeCd,
			"movementExceptionTypeCd.keyword" : this.movementExceptionTypeCd_keyword,
			"deliveryQualifierCd" : this.deliveryQualifierCd,
			"deliveryQualifierCd.keyword" : this.deliveryQualifierCd_keyword,
			"apptDtls.scheduledDeliveryDate" : this.apptDtls_scheduledDeliveryDate,
			"apptDtls.scheduledDeliveryDate.keyword" : this.apptDtls_scheduledDeliveryDate_keyword,
			"apptDtls.scheduledDeliveryFromTime" : this.apptDtls_scheduledDeliveryFromTime,
			"apptDtls.scheduledDeliveryFromTime.keyword" : this.apptDtls_scheduledDeliveryFromTime_keyword,
			"apptDtls.scheduledDeliveryToTime" : this.apptDtls_scheduledDeliveryToTime,
			"apptDtls.scheduledDeliveryToTime.keyword" : this.apptDtls_scheduledDeliveryToTime_keyword,
			"tdc.tdcDateTypeCd" : this.tdc_tdcDateTypeCd,
			"tdc.tdcDateTypeCd.keyword" : this.tdc_tdcDateTypeCd_keyword,
			"tdc.tdcDate1" : this.tdc_tdcDate1,
			"tdc.tdcDate1.keyword" : this.tdc_tdcDate1_keyword,
			"tdc.tdcDate2" : this.tdc_tdcDate2,
			"tdc.tdcDate2.keyword" : this.tdc_tdcDate2_keyword,
			"tdc.tdcTimeTypeCd" : this.tdc_tdcTimeTypeCd,
			"tdc.tdcTimeTypeCd.keyword" : this.tdc_tdcTimeTypeCd_keyword,
			"tdc.tdcTime1" : this.tdc_tdcTime1,
			"tdc.tdcTime1.keyword" : this.tdc_tdcTime1_keyword,
			"tdc.tdcTime2" : this.tdc_tdcTime2,
			"tdc.tdcTime2.keyword" : this.tdc_tdcTime2_keyword,
			"correctiveAction" : this.correctiveAction,
			"correctiveAction.keyword" : this.correctiveAction_keyword,
			"billClassCd" : this.billClassCd,
			"billClassCd.keyword" : this.billClassCd_keyword,
			"apptReq" : this.apptReq,
			"apptReq.keyword" : this.apptReq_keyword,
			"withinTdcCd" : this.withinTdcCd,
			"withinTdcCd.keyword" : this.withinTdcCd_keyword,
			"destinationArrivalDateTime" : this.destinationArrivalDateTime,
			"destinationArrivalDateTime.keyword" : this.destinationArrivalDateTime_keyword,
			"shipmentLocationSicCd" : this.shipmentLocationSicCd,
			"shipmentLocationSicCd.keyword" : this.shipmentLocationSicCd_keyword,
			"actualDeliveryDateTime" : this.actualDeliveryDateTime,
			"actualDeliveryDateTime.keyword" : this.actualDeliveryDateTime_keyword,
			"onTimeDeliveryCd" : this.onTimeDeliveryCd,
			"onTimeDeliveryCd.keyword" : this.onTimeDeliveryCd_keyword,
			"afterHoursPickupInd" : this.afterHoursPickupInd,
			"afterHoursPickupInd.keyword" : this.afterHoursPickupInd_keyword,
			"shipperRefNbr" : this.shipperRefNbr,
			"shipperRefNbr.keyword" : this.shipperRefNbr_keyword,
			"movementExcpActionReason" : this.movementExcpActionReason,
			"movementExcpActionReason.keyword" : this.movementExcpActionReason_keyword,
			"lstMvmtTmst" : this.lstMvmtTmst,
			"lstMvmtTmst.keyword" : this.lstMvmtTmst_keyword,
			"shipmentPurgedInd" : this.shipmentPurgedInd,
			"shipmentPurgedInd.keyword" : this.shipmentPurgedInd_keyword,
			"or" : this.or,
			"not" : this.not		}
	}
}
export class DeliveryShipmentSearchFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	q:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	b:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	currentSicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	currentSicCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	hostDestSicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	hostDestSicCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destinationSicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destinationSicCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	scheduleDestinationSicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	scheduleDestinationSicCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	scheduleETA:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	scheduleETA_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	estimatedDeliveryDate:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	estimatedDeliveryDate_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	currentTrailer:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	currentTrailer_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	specialServiceSummary_specialService:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	specialServiceSummary_specialService_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_geoCoordinates:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_geoCoordinates_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	routeInstId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	routeInstId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destSicEta:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destSicEta_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentPndStatus:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentPndStatus_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destSicEtaLastUpdtTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destSicEtaLastUpdtTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupDt:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupDt_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	originSic:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	originSic_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	responsibleSic:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	responsibleSic_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	suppRefNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	suppRefNbr_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_acctInstId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_acctInstId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_name1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_name1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_name2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_name2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_addressLine1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_addressLine1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_addressLine2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_addressLine2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_cityName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_cityName_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_stateCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_stateCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_postalCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_postalCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_countryCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_countryCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_acctMadCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consignee_acctMadCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_acctInstId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_acctInstId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_name1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_name1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_name2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_name2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_addressLine1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_addressLine1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_addressLine2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_addressLine2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_cityName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_cityName_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_stateCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_stateCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_postalCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_postalCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_countryCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_countryCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_acctMadCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billTo_acctMadCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_acctInstId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_acctInstId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_name1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_name1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_name2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_name2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_addressLine1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_addressLine1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_addressLine2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_addressLine2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_cityName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_cityName_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_stateCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_stateCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_postalCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_postalCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_countryCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_countryCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_acctMadCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipper_acctMadCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	movementStatusCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	movementStatusCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	movementExceptionTypeCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	movementExceptionTypeCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	deliveryQualifierCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	deliveryQualifierCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	apptDtls_scheduledDeliveryDate:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	apptDtls_scheduledDeliveryDate_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	apptDtls_scheduledDeliveryFromTime:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	apptDtls_scheduledDeliveryFromTime_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	apptDtls_scheduledDeliveryToTime:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	apptDtls_scheduledDeliveryToTime_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcDateTypeCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcDateTypeCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcDate1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcDate1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcDate2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcDate2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcTimeTypeCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcTimeTypeCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcTime1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcTime1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcTime2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tdc_tdcTime2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	correctiveAction:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	correctiveAction_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billClassCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billClassCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	apptReq:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	apptReq_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	withinTdcCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	withinTdcCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destinationArrivalDateTime:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	destinationArrivalDateTime_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentLocationSicCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentLocationSicCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	actualDeliveryDateTime:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	actualDeliveryDateTime_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	onTimeDeliveryCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	onTimeDeliveryCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	afterHoursPickupInd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	afterHoursPickupInd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperRefNbr:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperRefNbr_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	movementExcpActionReason:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	movementExcpActionReason_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	lstMvmtTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	lstMvmtTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentPurgedInd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentPurgedInd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	and:DeliveryShipmentSearchFilterAnd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	or:DeliveryShipmentSearchFilterOr[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	not:DeliveryShipmentSearchFilterNot[] = undefined;
	public toJSON = function(this: DeliveryShipmentSearchFilter) {
		return {
			"q" : this.q,
			"b" : this.b,
			"proNbr" : this.proNbr,
			"proNbr.keyword" : this.proNbr_keyword,
			"currentSicCd" : this.currentSicCd,
			"currentSicCd.keyword" : this.currentSicCd_keyword,
			"hostDestSicCd" : this.hostDestSicCd,
			"hostDestSicCd.keyword" : this.hostDestSicCd_keyword,
			"destinationSicCd" : this.destinationSicCd,
			"destinationSicCd.keyword" : this.destinationSicCd_keyword,
			"scheduleDestinationSicCd" : this.scheduleDestinationSicCd,
			"scheduleDestinationSicCd.keyword" : this.scheduleDestinationSicCd_keyword,
			"scheduleETA" : this.scheduleETA,
			"scheduleETA.keyword" : this.scheduleETA_keyword,
			"estimatedDeliveryDate" : this.estimatedDeliveryDate,
			"estimatedDeliveryDate.keyword" : this.estimatedDeliveryDate_keyword,
			"currentTrailer" : this.currentTrailer,
			"currentTrailer.keyword" : this.currentTrailer_keyword,
			"specialServiceSummary.specialService" : this.specialServiceSummary_specialService,
			"specialServiceSummary.specialService.keyword" : this.specialServiceSummary_specialService_keyword,
			"consignee.geoCoordinates" : this.consignee_geoCoordinates,
			"consignee.geoCoordinates.keyword" : this.consignee_geoCoordinates_keyword,
			"routeInstId" : this.routeInstId,
			"routeInstId.keyword" : this.routeInstId_keyword,
			"destSicEta" : this.destSicEta,
			"destSicEta.keyword" : this.destSicEta_keyword,
			"shipmentPndStatus" : this.shipmentPndStatus,
			"shipmentPndStatus.keyword" : this.shipmentPndStatus_keyword,
			"destSicEtaLastUpdtTmst" : this.destSicEtaLastUpdtTmst,
			"destSicEtaLastUpdtTmst.keyword" : this.destSicEtaLastUpdtTmst_keyword,
			"pickupDt" : this.pickupDt,
			"pickupDt.keyword" : this.pickupDt_keyword,
			"originSic" : this.originSic,
			"originSic.keyword" : this.originSic_keyword,
			"responsibleSic" : this.responsibleSic,
			"responsibleSic.keyword" : this.responsibleSic_keyword,
			"suppRefNbr" : this.suppRefNbr,
			"suppRefNbr.keyword" : this.suppRefNbr_keyword,
			"consignee.acctInstId" : this.consignee_acctInstId,
			"consignee.acctInstId.keyword" : this.consignee_acctInstId_keyword,
			"consignee.name1" : this.consignee_name1,
			"consignee.name1.keyword" : this.consignee_name1_keyword,
			"consignee.name2" : this.consignee_name2,
			"consignee.name2.keyword" : this.consignee_name2_keyword,
			"consignee.addressLine1" : this.consignee_addressLine1,
			"consignee.addressLine1.keyword" : this.consignee_addressLine1_keyword,
			"consignee.addressLine2" : this.consignee_addressLine2,
			"consignee.addressLine2.keyword" : this.consignee_addressLine2_keyword,
			"consignee.cityName" : this.consignee_cityName,
			"consignee.cityName.keyword" : this.consignee_cityName_keyword,
			"consignee.stateCd" : this.consignee_stateCd,
			"consignee.stateCd.keyword" : this.consignee_stateCd_keyword,
			"consignee.postalCd" : this.consignee_postalCd,
			"consignee.postalCd.keyword" : this.consignee_postalCd_keyword,
			"consignee.countryCd" : this.consignee_countryCd,
			"consignee.countryCd.keyword" : this.consignee_countryCd_keyword,
			"consignee.acctMadCd" : this.consignee_acctMadCd,
			"consignee.acctMadCd.keyword" : this.consignee_acctMadCd_keyword,
			"billTo.acctInstId" : this.billTo_acctInstId,
			"billTo.acctInstId.keyword" : this.billTo_acctInstId_keyword,
			"billTo.name1" : this.billTo_name1,
			"billTo.name1.keyword" : this.billTo_name1_keyword,
			"billTo.name2" : this.billTo_name2,
			"billTo.name2.keyword" : this.billTo_name2_keyword,
			"billTo.addressLine1" : this.billTo_addressLine1,
			"billTo.addressLine1.keyword" : this.billTo_addressLine1_keyword,
			"billTo.addressLine2" : this.billTo_addressLine2,
			"billTo.addressLine2.keyword" : this.billTo_addressLine2_keyword,
			"billTo.cityName" : this.billTo_cityName,
			"billTo.cityName.keyword" : this.billTo_cityName_keyword,
			"billTo.stateCd" : this.billTo_stateCd,
			"billTo.stateCd.keyword" : this.billTo_stateCd_keyword,
			"billTo.postalCd" : this.billTo_postalCd,
			"billTo.postalCd.keyword" : this.billTo_postalCd_keyword,
			"billTo.countryCd" : this.billTo_countryCd,
			"billTo.countryCd.keyword" : this.billTo_countryCd_keyword,
			"billTo.acctMadCd" : this.billTo_acctMadCd,
			"billTo.acctMadCd.keyword" : this.billTo_acctMadCd_keyword,
			"shipper.acctInstId" : this.shipper_acctInstId,
			"shipper.acctInstId.keyword" : this.shipper_acctInstId_keyword,
			"shipper.name1" : this.shipper_name1,
			"shipper.name1.keyword" : this.shipper_name1_keyword,
			"shipper.name2" : this.shipper_name2,
			"shipper.name2.keyword" : this.shipper_name2_keyword,
			"shipper.addressLine1" : this.shipper_addressLine1,
			"shipper.addressLine1.keyword" : this.shipper_addressLine1_keyword,
			"shipper.addressLine2" : this.shipper_addressLine2,
			"shipper.addressLine2.keyword" : this.shipper_addressLine2_keyword,
			"shipper.cityName" : this.shipper_cityName,
			"shipper.cityName.keyword" : this.shipper_cityName_keyword,
			"shipper.stateCd" : this.shipper_stateCd,
			"shipper.stateCd.keyword" : this.shipper_stateCd_keyword,
			"shipper.postalCd" : this.shipper_postalCd,
			"shipper.postalCd.keyword" : this.shipper_postalCd_keyword,
			"shipper.countryCd" : this.shipper_countryCd,
			"shipper.countryCd.keyword" : this.shipper_countryCd_keyword,
			"shipper.acctMadCd" : this.shipper_acctMadCd,
			"shipper.acctMadCd.keyword" : this.shipper_acctMadCd_keyword,
			"movementStatusCd" : this.movementStatusCd,
			"movementStatusCd.keyword" : this.movementStatusCd_keyword,
			"movementExceptionTypeCd" : this.movementExceptionTypeCd,
			"movementExceptionTypeCd.keyword" : this.movementExceptionTypeCd_keyword,
			"deliveryQualifierCd" : this.deliveryQualifierCd,
			"deliveryQualifierCd.keyword" : this.deliveryQualifierCd_keyword,
			"apptDtls.scheduledDeliveryDate" : this.apptDtls_scheduledDeliveryDate,
			"apptDtls.scheduledDeliveryDate.keyword" : this.apptDtls_scheduledDeliveryDate_keyword,
			"apptDtls.scheduledDeliveryFromTime" : this.apptDtls_scheduledDeliveryFromTime,
			"apptDtls.scheduledDeliveryFromTime.keyword" : this.apptDtls_scheduledDeliveryFromTime_keyword,
			"apptDtls.scheduledDeliveryToTime" : this.apptDtls_scheduledDeliveryToTime,
			"apptDtls.scheduledDeliveryToTime.keyword" : this.apptDtls_scheduledDeliveryToTime_keyword,
			"tdc.tdcDateTypeCd" : this.tdc_tdcDateTypeCd,
			"tdc.tdcDateTypeCd.keyword" : this.tdc_tdcDateTypeCd_keyword,
			"tdc.tdcDate1" : this.tdc_tdcDate1,
			"tdc.tdcDate1.keyword" : this.tdc_tdcDate1_keyword,
			"tdc.tdcDate2" : this.tdc_tdcDate2,
			"tdc.tdcDate2.keyword" : this.tdc_tdcDate2_keyword,
			"tdc.tdcTimeTypeCd" : this.tdc_tdcTimeTypeCd,
			"tdc.tdcTimeTypeCd.keyword" : this.tdc_tdcTimeTypeCd_keyword,
			"tdc.tdcTime1" : this.tdc_tdcTime1,
			"tdc.tdcTime1.keyword" : this.tdc_tdcTime1_keyword,
			"tdc.tdcTime2" : this.tdc_tdcTime2,
			"tdc.tdcTime2.keyword" : this.tdc_tdcTime2_keyword,
			"correctiveAction" : this.correctiveAction,
			"correctiveAction.keyword" : this.correctiveAction_keyword,
			"billClassCd" : this.billClassCd,
			"billClassCd.keyword" : this.billClassCd_keyword,
			"apptReq" : this.apptReq,
			"apptReq.keyword" : this.apptReq_keyword,
			"withinTdcCd" : this.withinTdcCd,
			"withinTdcCd.keyword" : this.withinTdcCd_keyword,
			"destinationArrivalDateTime" : this.destinationArrivalDateTime,
			"destinationArrivalDateTime.keyword" : this.destinationArrivalDateTime_keyword,
			"shipmentLocationSicCd" : this.shipmentLocationSicCd,
			"shipmentLocationSicCd.keyword" : this.shipmentLocationSicCd_keyword,
			"actualDeliveryDateTime" : this.actualDeliveryDateTime,
			"actualDeliveryDateTime.keyword" : this.actualDeliveryDateTime_keyword,
			"onTimeDeliveryCd" : this.onTimeDeliveryCd,
			"onTimeDeliveryCd.keyword" : this.onTimeDeliveryCd_keyword,
			"afterHoursPickupInd" : this.afterHoursPickupInd,
			"afterHoursPickupInd.keyword" : this.afterHoursPickupInd_keyword,
			"shipperRefNbr" : this.shipperRefNbr,
			"shipperRefNbr.keyword" : this.shipperRefNbr_keyword,
			"movementExcpActionReason" : this.movementExcpActionReason,
			"movementExcpActionReason.keyword" : this.movementExcpActionReason_keyword,
			"lstMvmtTmst" : this.lstMvmtTmst,
			"lstMvmtTmst.keyword" : this.lstMvmtTmst_keyword,
			"shipmentPurgedInd" : this.shipmentPurgedInd,
			"shipmentPurgedInd.keyword" : this.shipmentPurgedInd_keyword,
			"and" : this.and,
			"or" : this.or,
			"not" : this.not		}
	}
}
/**
 * Path parameters
 */
export class GetElasticDocumentDeliveryShipmentPath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	shipmentInstId:string = undefined;
}
/**
 * Query parameters
 */
export class GetElasticDocumentDeliveryShipmentQuery {
	/**
	 * Source filter
	 * Required: false
	 * Array: false
	 */
	_source:string = undefined;
	/**
	 * Source include filter
	 * Required: false
	 * Array: false
	 */
	_source_include:string = undefined;
	/**
	 * Source exclude filter
	 * Required: false
	 * Array: false
	 */
	_source_exclude:string = undefined;
}
/**
 * Response from getElasticDocument.
 */
export class GetElasticDocumentDeliveryShipmentResp {
	/**
	 * The record if found
	 * Required: false
	 * Array: false
	 */
	record:DeliveryShipmentSearchRecord = undefined;
}
/**
 * Query parameters
 */
export class GetElasticDocumentDeliveryShipmentCountQuery {
	/**
	 * Query filter
	 * Required: false
	 * Array: false
	 */
	q:string = undefined;
}
/**
 * Response from getElasticDocumentCount.
 */
export class GetElasticDocumentDeliveryShipmentCountResp {
	/**
	 * The count of the documents
	 * Required: false
	 * Array: false
	 */
	count:number = undefined;
}
/**
 * Request message to update information for DeliveryShipment.
 */
export class UpdateElasticDocumentDeliveryShipmentRqst {
	/**
	 * The record to upsert in elastic
	 * Required: false
	 * Array: false
	 */
	doc:DeliveryShipmentSearchRecord = undefined;
	/**
	 * Indicate if an upsert operation should be executed if the document was not found
	 * Required: false
	 * Array: false
	 */
	doc_as_upsert:boolean = undefined;
}
/**
 * Path parameters
 */
export class UpdateElasticDocumentDeliveryShipmentPath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	shipmentInstId:string = undefined;
}
/**
 * Response from updateElasticDocument.
 */
export class UpdateElasticDocumentDeliveryShipmentResp {
	/**
	 * The result of the operation
	 * Required: false
	 * Array: false
	 */
	result:string = undefined;
}
/**
 * Path parameters
 */
export class DeleteElasticDocumentDeliveryShipmentPath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	shipmentInstId:string = undefined;
}
/**
 * Request message to delete information for DeliveryShipment.
 */
export class DeleteElasticDocumentDeliveryShipmentResp {
	/**
	 * The result of the operation
	 * Required: false
	 * Array: false
	 */
	result:string = undefined;
}
/**
 * Request message to search information for unassigned-deliveries.
 */
export class SearchUnassignedDeliveriesRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	pageNumber:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	sortExpressions:XrtSortExpression[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pageSize:number = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:DeliveryShipmentSearchFilter = undefined;
}
/**
 * Response to the request to search information for unassigned-deliveries.
 */
export class SearchUnassignedDeliveriesResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:DeliveryShipmentSearchRecord[] = undefined;
}
/**
 * Request for register filter
 */
export class RegisterFilterUnassignedDeliveriesRqst {
	/**
	 * Id of the  user
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The filter to register
	 * Required: false
	 * Array: false
	 */
	filter:DeliveryShipmentSearchFilter = undefined;
}
/**
 * Response for register filter
 */
export class RegisterFilterUnassignedDeliveriesResp {
	/**
	 * Hash for the filter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
	/**
	 * Duration for the API execution
	 * Required: false
	 * Array: false
	 */
	duration:number = undefined;
	/**
	 * Number of registrations created
	 * Required: false
	 * Array: false
	 */
	numberOfRegistrations:number = undefined;
}
/**
 * Request for register
 */
export class UnregisterFilterUnassignedDeliveriesRqst {
	/**
	 * Id of the  user. For future use.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The hash of the filter to unregister. Filter has is returned by registerFilter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
}
/**
 * Request for changes feed
 */
export class ChangesFeedUnassignedDeliveriesRqst {
	/**
	 * A list of document Ids
	 * Required: false
	 * Array: true
	 */
	changedDocumentIds:number[] = undefined;
	/**
	 * A list of changed documents
	 * Required: false
	 * Array: true
	 */
	changedDocuments:DeliveryShipmentSearchRecord[] = undefined;
}
/**
 * Request message to search information for unbilled-shipments.
 */
export class SearchUnbilledShipmentsRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	pageNumber:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	sortExpressions:XrtSortExpression[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pageSize:number = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:DeliveryShipmentSearchFilter = undefined;
}
/**
 * Response to the request to search information for unbilled-shipments.
 */
export class SearchUnbilledShipmentsResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:DeliveryShipmentSearchRecord[] = undefined;
}
/**
 * Request for register filter
 */
export class RegisterFilterUnbilledShipmentsRqst {
	/**
	 * Id of the  user
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The filter to register
	 * Required: false
	 * Array: false
	 */
	filter:DeliveryShipmentSearchFilter = undefined;
}
/**
 * Response for register filter
 */
export class RegisterFilterUnbilledShipmentsResp {
	/**
	 * Hash for the filter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
	/**
	 * Duration for the API execution
	 * Required: false
	 * Array: false
	 */
	duration:number = undefined;
	/**
	 * Number of registrations created
	 * Required: false
	 * Array: false
	 */
	numberOfRegistrations:number = undefined;
}
/**
 * Request for register
 */
export class UnregisterFilterUnbilledShipmentsRqst {
	/**
	 * Id of the  user. For future use.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The hash of the filter to unregister. Filter has is returned by registerFilter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
}
/**
 * Request for changes feed
 */
export class ChangesFeedUnbilledShipmentsRqst {
	/**
	 * A list of document Ids
	 * Required: false
	 * Array: true
	 */
	changedDocumentIds:number[] = undefined;
	/**
	 * A list of changed documents
	 * Required: false
	 * Array: true
	 */
	changedDocuments:DeliveryShipmentSearchRecord[] = undefined;
}
/**
 * Request message to search information for delivery-shipments.
 */
export class SearchDeliveryShipmentsRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	pageNumber:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	sortExpressions:XrtSortExpression[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pageSize:number = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:DeliveryShipmentSearchFilter = undefined;
}
/**
 * Response to the request to search information for delivery-shipments.
 */
export class SearchDeliveryShipmentsResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:DeliveryShipmentSearchRecord[] = undefined;
}
/**
 * Request for register filter
 */
export class RegisterFilterDeliveryShipmentsRqst {
	/**
	 * Id of the  user
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The filter to register
	 * Required: false
	 * Array: false
	 */
	filter:DeliveryShipmentSearchFilter = undefined;
}
/**
 * Response for register filter
 */
export class RegisterFilterDeliveryShipmentsResp {
	/**
	 * Hash for the filter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
	/**
	 * Duration for the API execution
	 * Required: false
	 * Array: false
	 */
	duration:number = undefined;
	/**
	 * Number of registrations created
	 * Required: false
	 * Array: false
	 */
	numberOfRegistrations:number = undefined;
}
/**
 * Request for register
 */
export class UnregisterFilterDeliveryShipmentsRqst {
	/**
	 * Id of the  user. For future use.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The hash of the filter to unregister. Filter has is returned by registerFilter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
}
/**
 * Request for changes feed
 */
export class ChangesFeedDeliveryShipmentsRqst {
	/**
	 * A list of document Ids
	 * Required: false
	 * Array: true
	 */
	changedDocumentIds:number[] = undefined;
	/**
	 * A list of changed documents
	 * Required: false
	 * Array: true
	 */
	changedDocuments:DeliveryShipmentSearchRecord[] = undefined;
}
/**
 * Request message to search information for unmapped-shipments.
 */
export class SearchUnmappedShipmentsRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	pageNumber:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	sortExpressions:XrtSortExpression[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pageSize:number = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:DeliveryShipmentSearchFilter = undefined;
}
/**
 * Response to the request to search information for unmapped-shipments.
 */
export class SearchUnmappedShipmentsResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:DeliveryShipmentSearchRecord[] = undefined;
}
/**
 * Request for register filter
 */
export class RegisterFilterUnmappedShipmentsRqst {
	/**
	 * Id of the  user
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The filter to register
	 * Required: false
	 * Array: false
	 */
	filter:DeliveryShipmentSearchFilter = undefined;
}
/**
 * Response for register filter
 */
export class RegisterFilterUnmappedShipmentsResp {
	/**
	 * Hash for the filter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
	/**
	 * Duration for the API execution
	 * Required: false
	 * Array: false
	 */
	duration:number = undefined;
	/**
	 * Number of registrations created
	 * Required: false
	 * Array: false
	 */
	numberOfRegistrations:number = undefined;
}
/**
 * Request for register
 */
export class UnregisterFilterUnmappedShipmentsRqst {
	/**
	 * Id of the  user. For future use.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The hash of the filter to unregister. Filter has is returned by registerFilter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
}
/**
 * Request for changes feed
 */
export class ChangesFeedUnmappedShipmentsRqst {
	/**
	 * A list of document Ids
	 * Required: false
	 * Array: true
	 */
	changedDocumentIds:number[] = undefined;
	/**
	 * A list of changed documents
	 * Required: false
	 * Array: true
	 */
	changedDocuments:DeliveryShipmentSearchRecord[] = undefined;
}
/**
 * Request message to search information for admin-route-shipments.
 */
export class SearchAdminRouteShipmentsRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	pageNumber:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	sortExpressions:XrtSortExpression[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pageSize:number = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:DeliveryShipmentSearchFilter = undefined;
}
/**
 * Response to the request to search information for admin-route-shipments.
 */
export class SearchAdminRouteShipmentsResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:DeliveryShipmentSearchRecord[] = undefined;
}
/**
 * Request for register filter
 */
export class RegisterFilterAdminRouteShipmentsRqst {
	/**
	 * Id of the  user
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The filter to register
	 * Required: false
	 * Array: false
	 */
	filter:DeliveryShipmentSearchFilter = undefined;
}
/**
 * Response for register filter
 */
export class RegisterFilterAdminRouteShipmentsResp {
	/**
	 * Hash for the filter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
	/**
	 * Duration for the API execution
	 * Required: false
	 * Array: false
	 */
	duration:number = undefined;
	/**
	 * Number of registrations created
	 * Required: false
	 * Array: false
	 */
	numberOfRegistrations:number = undefined;
}
/**
 * Request for register
 */
export class UnregisterFilterAdminRouteShipmentsRqst {
	/**
	 * Id of the  user. For future use.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The hash of the filter to unregister. Filter has is returned by registerFilter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
}
/**
 * Request for changes feed
 */
export class ChangesFeedAdminRouteShipmentsRqst {
	/**
	 * A list of document Ids
	 * Required: false
	 * Array: true
	 */
	changedDocumentIds:number[] = undefined;
	/**
	 * A list of changed documents
	 * Required: false
	 * Array: true
	 */
	changedDocuments:DeliveryShipmentSearchRecord[] = undefined;
}
/**
 * Request message to search information for undeleted-shipments.
 */
export class SearchUndeletedShipmentsRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	pageNumber:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	sortExpressions:XrtSortExpression[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pageSize:number = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:DeliveryShipmentSearchFilter = undefined;
}
/**
 * Response to the request to search information for undeleted-shipments.
 */
export class SearchUndeletedShipmentsResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:DeliveryShipmentSearchRecord[] = undefined;
}
/**
 * Request for register filter
 */
export class RegisterFilterUndeletedShipmentsRqst {
	/**
	 * Id of the  user
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The filter to register
	 * Required: false
	 * Array: false
	 */
	filter:DeliveryShipmentSearchFilter = undefined;
}
/**
 * Response for register filter
 */
export class RegisterFilterUndeletedShipmentsResp {
	/**
	 * Hash for the filter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
	/**
	 * Duration for the API execution
	 * Required: false
	 * Array: false
	 */
	duration:number = undefined;
	/**
	 * Number of registrations created
	 * Required: false
	 * Array: false
	 */
	numberOfRegistrations:number = undefined;
}
/**
 * Request for register
 */
export class UnregisterFilterUndeletedShipmentsRqst {
	/**
	 * Id of the  user. For future use.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The hash of the filter to unregister. Filter has is returned by registerFilter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
}
/**
 * Request for changes feed
 */
export class ChangesFeedUndeletedShipmentsRqst {
	/**
	 * A list of document Ids
	 * Required: false
	 * Array: true
	 */
	changedDocumentIds:number[] = undefined;
	/**
	 * A list of changed documents
	 * Required: false
	 * Array: true
	 */
	changedDocuments:DeliveryShipmentSearchRecord[] = undefined;
}
/**
 * Request message to search information for delivery-shipment-unfiltered.
 */
export class SearchDeliveryShipmentUnfilteredRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	pageNumber:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	sortExpressions:XrtSortExpression[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pageSize:number = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:DeliveryShipmentSearchFilter = undefined;
}
/**
 * Response to the request to search information for delivery-shipment-unfiltered.
 */
export class SearchDeliveryShipmentUnfilteredResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:DeliveryShipmentSearchRecord[] = undefined;
}
/**
 * Request for register filter
 */
export class RegisterFilterDeliveryShipmentUnfilteredRqst {
	/**
	 * Id of the  user
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The filter to register
	 * Required: false
	 * Array: false
	 */
	filter:DeliveryShipmentSearchFilter = undefined;
}
/**
 * Response for register filter
 */
export class RegisterFilterDeliveryShipmentUnfilteredResp {
	/**
	 * Hash for the filter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
	/**
	 * Duration for the API execution
	 * Required: false
	 * Array: false
	 */
	duration:number = undefined;
	/**
	 * Number of registrations created
	 * Required: false
	 * Array: false
	 */
	numberOfRegistrations:number = undefined;
}
/**
 * Request for register
 */
export class UnregisterFilterDeliveryShipmentUnfilteredRqst {
	/**
	 * Id of the  user. For future use.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The hash of the filter to unregister. Filter has is returned by registerFilter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
}
/**
 * Request for changes feed
 */
export class ChangesFeedDeliveryShipmentUnfilteredRqst {
	/**
	 * A list of document Ids
	 * Required: false
	 * Array: true
	 */
	changedDocumentIds:number[] = undefined;
	/**
	 * A list of changed documents
	 * Required: false
	 * Array: true
	 */
	changedDocuments:DeliveryShipmentSearchRecord[] = undefined;
}
export class EnsembleStateSearchFilterNot {
	/**
	 * Required: false
	 * Array: true
	 */
	and:EnsembleStateSearchFilterAnd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	or:EnsembleStateSearchFilterOr[] = undefined;
}
export class EnsembleStateSearchFilterOr {
	/**
	 * Required: false
	 * Array: false
	 */
	instId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	instId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_messageId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_messageId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_queueName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_queueName_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_name:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_name_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_desc:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_desc_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_eventTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_eventTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_strtTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_strtTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_endTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_endTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_statCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_statCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_correlationId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_correlationId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey3:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey3_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey4:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey4_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey5:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey5_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_host:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_host_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	and:EnsembleStateSearchFilterAnd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	not:EnsembleStateSearchFilterNot[] = undefined;
	public toJSON = function(this: EnsembleStateSearchFilterOr) {
		return {
			"instId" : this.instId,
			"instId.keyword" : this.instId_keyword,
			"ensembleState.messageId" : this.ensembleState_messageId,
			"ensembleState.messageId.keyword" : this.ensembleState_messageId_keyword,
			"ensembleState.queueName" : this.ensembleState_queueName,
			"ensembleState.queueName.keyword" : this.ensembleState_queueName_keyword,
			"ensembleState.name" : this.ensembleState_name,
			"ensembleState.name.keyword" : this.ensembleState_name_keyword,
			"ensembleState.desc" : this.ensembleState_desc,
			"ensembleState.desc.keyword" : this.ensembleState_desc_keyword,
			"ensembleState.eventTmst" : this.ensembleState_eventTmst,
			"ensembleState.eventTmst.keyword" : this.ensembleState_eventTmst_keyword,
			"ensembleState.strtTmst" : this.ensembleState_strtTmst,
			"ensembleState.strtTmst.keyword" : this.ensembleState_strtTmst_keyword,
			"ensembleState.endTmst" : this.ensembleState_endTmst,
			"ensembleState.endTmst.keyword" : this.ensembleState_endTmst_keyword,
			"ensembleState.statCd" : this.ensembleState_statCd,
			"ensembleState.statCd.keyword" : this.ensembleState_statCd_keyword,
			"ensembleState.correlationId" : this.ensembleState_correlationId,
			"ensembleState.correlationId.keyword" : this.ensembleState_correlationId_keyword,
			"ensembleState.businessKey1" : this.ensembleState_businessKey1,
			"ensembleState.businessKey1.keyword" : this.ensembleState_businessKey1_keyword,
			"ensembleState.businessKey2" : this.ensembleState_businessKey2,
			"ensembleState.businessKey2.keyword" : this.ensembleState_businessKey2_keyword,
			"ensembleState.businessKey3" : this.ensembleState_businessKey3,
			"ensembleState.businessKey3.keyword" : this.ensembleState_businessKey3_keyword,
			"ensembleState.businessKey4" : this.ensembleState_businessKey4,
			"ensembleState.businessKey4.keyword" : this.ensembleState_businessKey4_keyword,
			"ensembleState.businessKey5" : this.ensembleState_businessKey5,
			"ensembleState.businessKey5.keyword" : this.ensembleState_businessKey5_keyword,
			"ensembleState.host" : this.ensembleState_host,
			"ensembleState.host.keyword" : this.ensembleState_host_keyword,
			"and" : this.and,
			"not" : this.not		}
	}
}
export class EnsembleStateSearchFilterAnd {
	/**
	 * Required: false
	 * Array: false
	 */
	instId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	instId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_messageId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_messageId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_queueName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_queueName_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_name:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_name_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_desc:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_desc_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_eventTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_eventTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_strtTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_strtTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_endTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_endTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_statCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_statCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_correlationId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_correlationId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey3:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey3_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey4:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey4_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey5:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey5_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_host:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_host_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	or:EnsembleStateSearchFilterOr[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	not:EnsembleStateSearchFilterNot[] = undefined;
	public toJSON = function(this: EnsembleStateSearchFilterAnd) {
		return {
			"instId" : this.instId,
			"instId.keyword" : this.instId_keyword,
			"ensembleState.messageId" : this.ensembleState_messageId,
			"ensembleState.messageId.keyword" : this.ensembleState_messageId_keyword,
			"ensembleState.queueName" : this.ensembleState_queueName,
			"ensembleState.queueName.keyword" : this.ensembleState_queueName_keyword,
			"ensembleState.name" : this.ensembleState_name,
			"ensembleState.name.keyword" : this.ensembleState_name_keyword,
			"ensembleState.desc" : this.ensembleState_desc,
			"ensembleState.desc.keyword" : this.ensembleState_desc_keyword,
			"ensembleState.eventTmst" : this.ensembleState_eventTmst,
			"ensembleState.eventTmst.keyword" : this.ensembleState_eventTmst_keyword,
			"ensembleState.strtTmst" : this.ensembleState_strtTmst,
			"ensembleState.strtTmst.keyword" : this.ensembleState_strtTmst_keyword,
			"ensembleState.endTmst" : this.ensembleState_endTmst,
			"ensembleState.endTmst.keyword" : this.ensembleState_endTmst_keyword,
			"ensembleState.statCd" : this.ensembleState_statCd,
			"ensembleState.statCd.keyword" : this.ensembleState_statCd_keyword,
			"ensembleState.correlationId" : this.ensembleState_correlationId,
			"ensembleState.correlationId.keyword" : this.ensembleState_correlationId_keyword,
			"ensembleState.businessKey1" : this.ensembleState_businessKey1,
			"ensembleState.businessKey1.keyword" : this.ensembleState_businessKey1_keyword,
			"ensembleState.businessKey2" : this.ensembleState_businessKey2,
			"ensembleState.businessKey2.keyword" : this.ensembleState_businessKey2_keyword,
			"ensembleState.businessKey3" : this.ensembleState_businessKey3,
			"ensembleState.businessKey3.keyword" : this.ensembleState_businessKey3_keyword,
			"ensembleState.businessKey4" : this.ensembleState_businessKey4,
			"ensembleState.businessKey4.keyword" : this.ensembleState_businessKey4_keyword,
			"ensembleState.businessKey5" : this.ensembleState_businessKey5,
			"ensembleState.businessKey5.keyword" : this.ensembleState_businessKey5_keyword,
			"ensembleState.host" : this.ensembleState_host,
			"ensembleState.host.keyword" : this.ensembleState_host_keyword,
			"or" : this.or,
			"not" : this.not		}
	}
}
export class EnsembleStateSearchFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	q:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	b:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	instId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	instId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_messageId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_messageId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_queueName:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_queueName_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_name:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_name_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_desc:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_desc_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_eventTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_eventTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_strtTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_strtTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_endTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_endTmst_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_statCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_statCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_correlationId:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_correlationId_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey1:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey1_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey2:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey2_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey3:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey3_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey4:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey4_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey5:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_businessKey5_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_host:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	ensembleState_host_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	and:EnsembleStateSearchFilterAnd[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	or:EnsembleStateSearchFilterOr[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	not:EnsembleStateSearchFilterNot[] = undefined;
	public toJSON = function(this: EnsembleStateSearchFilter) {
		return {
			"q" : this.q,
			"b" : this.b,
			"instId" : this.instId,
			"instId.keyword" : this.instId_keyword,
			"ensembleState.messageId" : this.ensembleState_messageId,
			"ensembleState.messageId.keyword" : this.ensembleState_messageId_keyword,
			"ensembleState.queueName" : this.ensembleState_queueName,
			"ensembleState.queueName.keyword" : this.ensembleState_queueName_keyword,
			"ensembleState.name" : this.ensembleState_name,
			"ensembleState.name.keyword" : this.ensembleState_name_keyword,
			"ensembleState.desc" : this.ensembleState_desc,
			"ensembleState.desc.keyword" : this.ensembleState_desc_keyword,
			"ensembleState.eventTmst" : this.ensembleState_eventTmst,
			"ensembleState.eventTmst.keyword" : this.ensembleState_eventTmst_keyword,
			"ensembleState.strtTmst" : this.ensembleState_strtTmst,
			"ensembleState.strtTmst.keyword" : this.ensembleState_strtTmst_keyword,
			"ensembleState.endTmst" : this.ensembleState_endTmst,
			"ensembleState.endTmst.keyword" : this.ensembleState_endTmst_keyword,
			"ensembleState.statCd" : this.ensembleState_statCd,
			"ensembleState.statCd.keyword" : this.ensembleState_statCd_keyword,
			"ensembleState.correlationId" : this.ensembleState_correlationId,
			"ensembleState.correlationId.keyword" : this.ensembleState_correlationId_keyword,
			"ensembleState.businessKey1" : this.ensembleState_businessKey1,
			"ensembleState.businessKey1.keyword" : this.ensembleState_businessKey1_keyword,
			"ensembleState.businessKey2" : this.ensembleState_businessKey2,
			"ensembleState.businessKey2.keyword" : this.ensembleState_businessKey2_keyword,
			"ensembleState.businessKey3" : this.ensembleState_businessKey3,
			"ensembleState.businessKey3.keyword" : this.ensembleState_businessKey3_keyword,
			"ensembleState.businessKey4" : this.ensembleState_businessKey4,
			"ensembleState.businessKey4.keyword" : this.ensembleState_businessKey4_keyword,
			"ensembleState.businessKey5" : this.ensembleState_businessKey5,
			"ensembleState.businessKey5.keyword" : this.ensembleState_businessKey5_keyword,
			"ensembleState.host" : this.ensembleState_host,
			"ensembleState.host.keyword" : this.ensembleState_host_keyword,
			"and" : this.and,
			"or" : this.or,
			"not" : this.not		}
	}
}
/**
 * Path parameters
 */
export class GetElasticDocumentEnsembleStatePath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	instId:string = undefined;
}
/**
 * Query parameters
 */
export class GetElasticDocumentEnsembleStateQuery {
	/**
	 * Source filter
	 * Required: false
	 * Array: false
	 */
	_source:string = undefined;
	/**
	 * Source include filter
	 * Required: false
	 * Array: false
	 */
	_source_include:string = undefined;
	/**
	 * Source exclude filter
	 * Required: false
	 * Array: false
	 */
	_source_exclude:string = undefined;
}
/**
 * Response from getElasticDocument.
 */
export class GetElasticDocumentEnsembleStateResp {
	/**
	 * The record if found
	 * Required: false
	 * Array: false
	 */
	record:EnsembleStateSearchRecord = undefined;
}
/**
 * Query parameters
 */
export class GetElasticDocumentEnsembleStateCountQuery {
	/**
	 * Query filter
	 * Required: false
	 * Array: false
	 */
	q:string = undefined;
}
/**
 * Response from getElasticDocumentCount.
 */
export class GetElasticDocumentEnsembleStateCountResp {
	/**
	 * The count of the documents
	 * Required: false
	 * Array: false
	 */
	count:number = undefined;
}
/**
 * Request message to update information for EnsembleState.
 */
export class UpdateElasticDocumentEnsembleStateRqst {
	/**
	 * The record to upsert in elastic
	 * Required: false
	 * Array: false
	 */
	doc:EnsembleStateSearchRecord = undefined;
	/**
	 * Indicate if an upsert operation should be executed if the document was not found
	 * Required: false
	 * Array: false
	 */
	doc_as_upsert:boolean = undefined;
}
/**
 * Path parameters
 */
export class UpdateElasticDocumentEnsembleStatePath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	instId:string = undefined;
}
/**
 * Response from updateElasticDocument.
 */
export class UpdateElasticDocumentEnsembleStateResp {
	/**
	 * The result of the operation
	 * Required: false
	 * Array: false
	 */
	result:string = undefined;
}
/**
 * Path parameters
 */
export class DeleteElasticDocumentEnsembleStatePath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	instId:string = undefined;
}
/**
 * Request message to delete information for EnsembleState.
 */
export class DeleteElasticDocumentEnsembleStateResp {
	/**
	 * The result of the operation
	 * Required: false
	 * Array: false
	 */
	result:string = undefined;
}
/**
 * Request message to search information for ensemble-state-unfiltered.
 */
export class SearchEnsembleStateUnfilteredRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	pageNumber:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	sortExpressions:XrtSortExpression[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pageSize:number = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:EnsembleStateSearchFilter = undefined;
}
/**
 * Response to the request to search information for ensemble-state-unfiltered.
 */
export class SearchEnsembleStateUnfilteredResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:EnsembleStateSearchRecord[] = undefined;
}
/**
 * Request for register filter
 */
export class RegisterFilterEnsembleStateUnfilteredRqst {
	/**
	 * Id of the  user
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The filter to register
	 * Required: false
	 * Array: false
	 */
	filter:EnsembleStateSearchFilter = undefined;
}
/**
 * Response for register filter
 */
export class RegisterFilterEnsembleStateUnfilteredResp {
	/**
	 * Hash for the filter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
	/**
	 * Duration for the API execution
	 * Required: false
	 * Array: false
	 */
	duration:number = undefined;
	/**
	 * Number of registrations created
	 * Required: false
	 * Array: false
	 */
	numberOfRegistrations:number = undefined;
}
/**
 * Request for register
 */
export class UnregisterFilterEnsembleStateUnfilteredRqst {
	/**
	 * Id of the  user. For future use.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The hash of the filter to unregister. Filter has is returned by registerFilter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
}
/**
 * Request for changes feed
 */
export class ChangesFeedEnsembleStateUnfilteredRqst {
	/**
	 * A list of document Ids
	 * Required: false
	 * Array: true
	 */
	changedDocumentIds:number[] = undefined;
	/**
	 * A list of changed documents
	 * Required: false
	 * Array: true
	 */
	changedDocuments:EnsembleStateSearchRecord[] = undefined;
}
/**
 * 
 */
export class InterfaceAcct {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	acctInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	acctMadCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parentAcctInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nationalAcctInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	creditStatusCode:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	locationTypeCode:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	addressLine1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	addressLine2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cityName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	postalCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deletedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	latitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	longitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastCreatedBy:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastCreatedDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	geoCoordinates:LatLong = undefined;
	/**
	 * Geo coordinations
	 * Required: false
	 * Array: false
	 */
	geoCoordinatesGeo:GeoCoordinates = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip4RestUs:string = undefined;
}
/**
 * Holds a snapshot of InterfaceAcct. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class InterfaceAcctSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:InterfaceAcct = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:InterfaceAcct = undefined;
}
/**
 * 
 */
export class CurrentLocation {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	speed:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	elevation:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	domicileSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventSequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nodeSequence:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	coordinates:LatLong = undefined;
}
/**
 * 
 */
export class DockLocation {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	terminalSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockRemarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockClosestDoorNbr:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
}
/**
 * 
 */
export class Event {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	subTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventOccurredDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedReleaseNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	histCurrentEvent:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	histCurrentEventDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmsDocId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	doorNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	transactionSrcCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	operationsShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reason:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class OperationsDetail {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	areaInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	assignmentSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	availableStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	handheldId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	jobSelectionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rankNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	speedDialNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	todayDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	todayHrsTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	todayStartTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripStartOffsetMinutes:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripStartWindowMinutes:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeAvailableDeliveryMinutes:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripDrtnMaxMinutes:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	prfrdTrailerLengthFoot:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lftgtInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrsEventId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Details about employees that process and approve claims.
 */
export class InterfaceEmployee {
	/**
	 * Employee Id of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * First Name of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	firstName:string = undefined;
	/**
	 * Middle Name of the Supervisor of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	middleName:string = undefined;
	/**
	 * Last Name of the Supervisor of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	lastName:string = undefined;
	/**
	 * Job Description of the Supervisor of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	jobDescription:string = undefined;
	/**
	 * The domicile SIC location for the employee.
	 * Required: false
	 * Array: false
	 */
	domicileSicCd:string = undefined;
	/**
	 * The employee id of this employee's supervisor.
	 * Required: false
	 * Array: false
	 */
	supervisorEmployeeId:string = undefined;
	/**
	 * Desc: The business phone number of the employee.  It is sourced from HRS_EMPL_PHONE_VW in DB2 for typ_cd = 'B'.  01/10/2019 Added by Cathy
	 * Required: false
	 * Array: false
	 */
	businessPhoneTxt:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Equipment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentTypeDetailCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleSequence:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEvnt:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEvntDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentRemarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnScacCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnReturnSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnRespSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnRntlCntrNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnRntlInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnCreatedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateTransactionSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mfrYr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmclSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	wrkRequestInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pmDueDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	inServiceDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	safeStackInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeToRsiInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	extdSlprLaneInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	limitedAvailableInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentStatusQlfrCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentEventId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * 
 */
export class LoadedShipment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dckLrgMarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerSectn1Ind:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerSectn2Ind:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerSectn3Ind:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerSectn4Ind:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerSectn5Ind:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerSectn6Ind:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billDestinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	misloadInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * 
 */
export class Schedule {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleIdNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEvent:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEventDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	milg:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	displayUserid:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	finalDestinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	etaDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	intmdlScac:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleDestinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chargeReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	discrInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleRemarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	onDfrdLegInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentEventId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class TrailerLoad {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	evntDoor:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEvnt:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEvntDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerLengthFeet:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedDestination:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedWeight:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedCbePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedCbeProfitability:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedReleaseNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	xclvInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sealNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	closeDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frzblInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	placardInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	garntServiceInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cityRoute:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	brkOrProprCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadDeckCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerLoadedRemarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadLocation:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadSuffix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dfrdShipmentInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedDfrdInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sealMatchCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	authrzUserid:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	foodShipmentCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	poisonShipmentCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	evntDoorSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	evntShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exctdUnloadShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	closShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerInspReqdInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exctdUnloadDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	suggestedLoadedDestination:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routingRequiredInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bulkLiquidInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overrideRvwRequiredInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
}
/**
 * 
 */
export class DispatchArea {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	groupId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	areaInstId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * An update operation u
	 * ses this to version check the record to ensure another transaction has not updat
	 * ed the row after this transaction had read the row and is now about to update it
	 * .
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
}
/**
 * 
 */
export class DispatchGroup {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	groupId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	groupName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	groupDescription:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	activeDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * An update operation 
	 * uses this to version check the record to ensure another transaction has not upda
	 * ted the row after this transaction had read the row and is now about to update i
	 * t.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
}
/**
 * 
 */
export class DeliveryShipment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	areaInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleIdNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billClassCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryQualifierCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastMovementDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleDestinationSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routePrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeSuffix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerCurrentSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerEtaDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	splitInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationLiftgateInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalPiecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	motorizedPiecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedDeliveryDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	inbondInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	purgeBlockInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	adminAsofDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationArrivalDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	timeDateCriticalInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationNotifyInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceSummaryInd:boolean = undefined;
	/**
	 * The trailer on which this shipment is currently located
	 * Required: false
	 * Array: false
	 */
	currentTrailer:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loosePiecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeCustomerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pupVolumePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	foodPoisonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	drvrCollectCd:string = undefined;
	/**
	 * Desc: Identifies if the 
	 * 
	 * shipment is on a trailer that has been excluded from the destination SICs inbound plan 
	 * 
	 * (Y) or not (N).
	 * 
	 * 05/01/2019 Added by Cathy Doucet as per Bill Muchmore (PND Tower)
	 * Required: false
	 * Array: false
	 */
	onExcludedTrailerInd:boolean = undefined;
	/**
	 * An update operation u
	 * ses this to version check the record to ensure another transaction has not updat
	 * ed the row after this transaction had read the row and is now about to update it
	 * .
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceTypeCd:string = undefined;
	/**
	 * This is the ETA at 
	 * 
	 * the destination SIC, and is recalculated for every linehaul dispatch and arrive event. 
	 * 
	 * 	Added 10/23/2019
	 * Required: false
	 * Array: false
	 */
	destinationSicEtaDateTime:Date = undefined;
}
/**
 * Holds a snapshot of DeliveryShipment. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class DeliveryShipmentSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:DeliveryShipment = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:DeliveryShipment = undefined;
}
/**
 * 
 */
export class DockActivity {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockVisitInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockActivitySequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockActivityCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryInformationRqdCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryQualifierCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	actualActivityDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	actualActivityDateTimeLocal:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	foodInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Same value for CORRELATIO
	 * N id is assigned to various rows from different tables that form a single busine
	 * ss transactions. In other words, this groups multiple rows across various tables
	 * 	for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Same value for event id is 
	 * assigned to various rows from different tables that form a single database trans
	 * actions. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 * Required: false
	 * Array: false
	 */
	scoEventId:number = undefined;
	/**
	 * An update operation u
	 * ses this to version check the record to ensure another transaction has not updat
	 * ed the row after this transaction had read the row and is now about to update it
	 * .
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class DockVisit {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockVisitInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockVisitSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerArriveDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerDepartDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerArriveDateTimeLocal:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerDepartDateTimeLocal:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperUnloadAtDockInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeLoadedAtDockInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cashDueCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freezeCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	guaranteedCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentRtndCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalBillCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalPiecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalPalletCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalWeightCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalVolumeCubicFeet:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalCubePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalMmCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Same value for CORRELATION i
	 * d is assigned to various rows from different tables that form a single business 
	 * transactions. In other words, this groups multiple rows across various tables fo
	 * r a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Same value for event id is ass
	 * igned to various rows from different tables that form a single database transact
	 * ions. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 * Required: false
	 * Array: false
	 */
	scoEventId:number = undefined;
	/**
	 * An update operation uses
	 * 	this to version check the record to ensure another transaction has not updated 
	 * the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	dockActivity:DockActivity[] = undefined;
}
/**
 * 
 */
export class EmployeeDevice {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	devSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deviceTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	primaryDeviceInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serverAddress:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	devicePhoneCountryCdNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	devicePhoneAreaCdNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	devicePhoneNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	logonSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	applCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deviceIpAddress:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * An update operation use
	 * s this to version check the record to ensure another transaction has not updated
	 * 	the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
}
/**
 * 
 */
export class ScoEvent {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	evtInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	subTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stopInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupReqInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sendToDeviceInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	event:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serverAddress:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	callDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	callSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hookInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deviceTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsrUserId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	applicationCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripNodeSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripNodeActivitySequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	activitySpfcNodeInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	activitySpfcNodeTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	activitySpfcName:string = undefined;
	/**
	 * Unique ID assigned to Route.  
	 * 
	 * Added 7/2/2019
	 * Required: false
	 * Array: false
	 */
	routeInstId:number = undefined;
	/**
	 * Same value for CORRELATION id is 
	 * assigned to various rows from different tables that form a single business trans
	 * actions. In other words, this groups multiple rows across various tables for a s
	 * ingle business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Holds a snapshot of ScoEvent. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class ScoEventSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:ScoEvent = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:ScoEvent = undefined;
}
/**
 * This table will be used for capturing SCO Metrics associated with both current metrics 
 * 
 * and snapshots.  An example is Trip Level metrics.  Added 07/12/2019
 */
export class Metric {
	/**
	 * Unique identifier
	 * Required: false
	 * Array: false
	 */
	metricId:number = undefined;
	/**
	 * The stage of operations for which this metric snapshot was taken. E.g. for Pnd, the stages are: Planning, Dispatched, Dock
	 * Required: false
	 * Array: false
	 */
	stage:MetricStageCd = undefined;
	/**
	 * The specific activity performed in a stage for which this metric was created. E.g. for Pnd, Released or Sequence could be a sub stage during the Planning stage.
	 * Required: false
	 * Array: false
	 */
	subStage:MetricStageSubCd = undefined;
	/**
	 * Service Center Code that owns the Route or Trip, etc.
	 * Required: false
	 * Array: false
	 */
	sicCode:string = undefined;
	/**
	 * Entity associated with the metric (e.g. Route, Trip, Service Center)
	 * Required: false
	 * Array: false
	 */
	typeCd:MetricTypeCd = undefined;
	/**
	 * Actual Metric Name (e.g. CWT, PPMH) an enumerated value.
	 * Required: false
	 * Array: false
	 */
	valueKeyCd:MetricValueKeyCd = undefined;
	/**
	 * Goals from external source.
	 * Required: false
	 * Array: false
	 */
	goal:number = undefined;
	/**
	 * The variance between actual goal.
	 * 
	 * Valid Values
	 * NEUTRAL
	 * ABOVE 
	 * BELOW
	 * Required: false
	 * Array: false
	 */
	variance:MetricVarianceCd = undefined;
	/**
	 * A description describing how the metric varies from the goal.
	 * Required: false
	 * Array: false
	 */
	varianceDesc:string = undefined;
	/**
	 * A description of the metric value. E.g. Delivery Weight per Trip, Delivery PPMH
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * The actual value in decimal form
	 * Required: false
	 * Array: false
	 */
	value:number = undefined;
	/**
	 * An update operation uses this to version check the record to ensure another transaction has not updated the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * An integer representing the order that this metric should appear in the list of metrics.  While this attribute is used internally for
	 * Required: false
	 * Array: false
	 */
	priority:number = undefined;
	/**
	 * Date for which the plan is active. (added for LH 08/02/2019)
	 * Required: false
	 * Array: false
	 */
	planDate:Date = undefined;
	/**
	 * Depending on the Metric, this could be Dest SIC, Next SIC, FAC SIC, etc. (added for LH 08/02/2019)
	 * Required: false
	 * Array: false
	 */
	targetSicCode:string = undefined;
	/**
	 * A Metric may be at the Region level. (added for LH 08/02/2019)
	 * Required: false
	 * Array: false
	 */
	regionCd:string = undefined;
	/**
	 * Shift Code this Metric applies to (added for LH 08/02/2019)
	 * 
	 * Valid values are 
	 * 
	 * D = Day FAC 
	 * I = Inbound 
	 * N = Night FAC 
	 * O = Outbound 
	 * P or blank = Pand D
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * The formatted string representing the metric value, derived by API.  Added 09/11/2019
	 * Required: false
	 * Array: false
	 */
	displayValue:string = undefined;
}
/**
 * 
 */
export class Note {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ownerInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:NoteTypeCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	noteSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	note:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ownerSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * An update operation uses this 
	 * to version check the record to ensure another transaction has not updated the ro
	 * w after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class DeliveryNotification {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	notificationInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:AppointmentNotificationStatCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	elecNotificationCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	callDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	callerId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactPhoneNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactPhoneExtensionNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	notificationCategoryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	notificationTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduledDeliveryDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduledDeliveryFromTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduledDeliveryToTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeRefNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rescheduledReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	internalNote:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fbdsNote:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * An update operation us
	 * es this to version check the record to ensure another transaction has not update
	 * d the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
}
/**
 * 
 */
export class NotificationShipment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	notificationInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstanceId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentProNbr:string = undefined;
	/**
	 * An update operation u
	 * ses this to version check the record to ensure another transaction has not updat
	 * ed the row after this transaction had read the row and is now about to update it
	 * .
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * 
 */
export class PositioningNode {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	areaInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stopSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedArriveDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedDepartDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	localEstimatedArriveDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	localEstimatedDepartDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planOpenTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planCloseTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planDistanceFromPrevNodeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nodeSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	latitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	longitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dwellTimeMinimum:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Same value for CORRELA
	 * TION id is assigned to various rows from different tables that form a single bus
	 * iness transactions. In other words, this groups multiple rows across various tab
	 * les for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Same value for event id 
	 * is assigned to various rows from different tables that form a single database tr
	 * ansactions. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 * Required: false
	 * Array: false
	 */
	scoEventId:number = undefined;
	/**
	 * An update operatio
	 * n uses this to version check the record to ensure another transaction has not up
	 * dated the row after this transaction had read the row and is now about to update
	 * 	it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class ProfileExcludeTrailer {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	selectionProfileId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * 
 */
export class ProfileSic {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	selectionProfileId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	profileSicSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	arrivalCutoffTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dateOffsetNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicGroupName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * An update operation uses t
	 * his to version check the record to ensure another transaction has not updated th
	 * e row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * 
 */
export class Route {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routePrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeSuffix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	categoryCd:RouteCategoryCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	plannedDoor:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockLocationInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:RouteStatusCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	terminalSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	liftgateCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalBillCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalPiecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalPalletCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalWeightCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalVolumeCubicFeet:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalCubePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalMmCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	guaranteedCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	warrantyCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryStopCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupStopCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceValidInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	xdockReleaseInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryRouteDepartTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originNodeInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originNodeTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originNodeSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationNodeInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationNodeTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationNodeSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	suggestedTrailerTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadedNodeInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadedNodeTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadedNodeSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scacCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalDeliveryWeightCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	protectRouteInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	enrouteEarliestDueTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	enrouteEarliestMessage:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	enrouteLatestMessage:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	enrouteLatestDueTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedDispatchTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedClearTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsrName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockLocationName:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fullTrailerId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentDockLocation:DockLocation = undefined;
	/**
	 * An update operation uses this
	 * 	to version check the record to ensure another transaction has not updated the r
	 * ow after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * The number of RRS shipments on this 
	 * 
	 * Route.  Added 10/23/2019
	 * Required: false
	 * Array: false
	 */
	rrsCount:string = undefined;
	/**
	 * The number of Guaranteed by 
	 * 
	 * 12 shipments on this Route.  Added 10/23/2019
	 * Required: false
	 * Array: false
	 */
	guaranteedBy12Count:string = undefined;
	/**
	 * Special services requested by a customer for a shipment delivery.
	 * Required: false
	 * Array: true
	 */
	specialServices:ShipmentSpecialServiceCd[] = undefined;
}
/**
 * 
 */
export class RouteShipment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeShipmentSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	positionOnTrailer:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadedFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	operatingRouteFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	diversionLoadDestinationSicCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * An update operation 
	 * uses this to version check the record to ensure another transaction has not upda
	 * ted the row after this transaction had read the row and is now about to update i
	 * t.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * 
 */
export class RouteTrip {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeTripSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fromDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	toDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * An update operation uses
	 * 	this to version check the record to ensure another transaction has not updated 
	 * the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * 
 */
export class SelectionProfile {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	selectionProfileId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	profileName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	profileTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	enrouteStartTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	enrouteEndTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	inboundDestinationSicInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	outboundArrivalTrailerInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	outboundOverheadTrailerInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	outboundTrapTrailerInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	outboundEnrouteTrailerInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	outboundCloseTrailerInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	weekDayNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	owningSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * An update operation u
	 * ses this to version check the record to ensure another transaction has not updat
	 * ed the row after this transaction had read the row and is now about to update it
	 * .
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	profileSic:ProfileSic[] = undefined;
}
/**
 * 
 */
export class Trip {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripDsrName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	actualDepartDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	actualReturnDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	categoryCd:TripCategoryCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsrEmployeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedDepartDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedReturnDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	helperEmployeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lunchTakenTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastStopInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastStopDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastStopTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastStopAreaId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	odometerEndNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	odometerStartNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overtimeStartDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:TripStatusCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripRmrkInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	terminalSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cashDueCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	timeDateCriticalCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryStopCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryCompletedCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	earlyCutoffCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freezeCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	guaranteedCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	liftgateCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupStopCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupCompletedCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stopCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stopCompletedCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentRtndCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headloadCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	warrantyCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripCompletedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceValidInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mobileDeviceStartDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mobileDeviceEndDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedEmptyDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	incompletePickupWeightCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	releaseStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	doNotAutoDisplayFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastStopArrivalDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cmsPullId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hssBookingNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cmsOutForBidInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cmsOfferId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scacCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	thirdPartyDriverHazmatCertFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastStopNodeSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUsedNodeSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	directionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	departTimeOfDayCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	arrivalTimeOfDayCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	runTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createSourceCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	optimizerSourceCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	optimizerRunId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	optimizerTripId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastResequenceDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * The total number of motor moves associated with the trip.
	 * Required: false
	 * Array: false
	 */
	totalMotorMoves:number = undefined;
	/**
	 * The identifier of the first Trip Node that was a Stop on this Trip.
	 * Required: false
	 * Array: false
	 */
	firstStopNodeSeqNbr:number = undefined;
	/**
	 * An update operation uses this 
	 * to version check the record to ensure another transaction has not updated the ro
	 * w after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * The number of RRS shipments on this 
	 * 
	 * Trip.  Added 10/23/2019
	 * Required: false
	 * Array: false
	 */
	rrsCount:string = undefined;
	/**
	 * The number of Guaranteed by 12 
	 * 
	 * shipments on this Trip.  Added 10/23/2019
	 * Required: false
	 * Array: false
	 */
	guaranteedBy12Count:string = undefined;
}
/**
 * 
 */
export class TripActivity {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	activityTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	activityDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	odometerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	latitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	longitudeNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * An update operation use
	 * s this to version check the record to ensure another transaction has not updated
	 * 	the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * 
 */
export class TripDriver {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsrName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsrEmployeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overtimeStartDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	domicileSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * An update operation use
	 * s this to version check the record to ensure another transaction has not updated
	 * 	the row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * 
 */
export class TripEquipment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripEquipmentSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdFullName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lengthUOMUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lengthUOMFeetNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	asgmntStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerEmptyFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	suggestedTrailerTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originalMoveToSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * An update operation uses t
	 * his to version check the record to ensure another transaction has not updated th
	 * e row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * 
 */
export class TripNode {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripNodeSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nodeInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nodeTypeCd:NodeTypeCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripNodeTypeCd:TripNodeTypeCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stopSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	areaInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedArriveDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedDepartDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedArriveDateTimeLocal:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedDepartDateTimeLocal:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overrideOperationsInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overrideInstructionInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduledDeliveryFromTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduledDeliveryToTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:TripNodeStatusCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planDistanceFromPrevNode:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	odometerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentRtndCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nodeSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	delayReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedDelayMinimum:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	latitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	longitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUsedActivitySequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stopBypassedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryWindowCalculatedOffsetMinutes:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryActivityCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupActivityCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentActivityCount:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * An update operation uses 
	 * this to version check the record to ensure another transaction has not updated t
	 * he row after this transaction had read the row and is now about to update it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * The planned distance from the previous node captured with up to 2 places decimal.  Added 10/08/2019
	 * Required: false
	 * Array: false
	 */
	plannedDistanceFromNodeMl:number = undefined;
}
/**
 * 
 */
export class TripNodeActivity {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripNodeSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripNodeActivitySequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	activityCd:TripNodeActivityCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	entityTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryInformationRqdCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryQualifierCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	actualActivityDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	actualActivityDateTimeLocal:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	swingEquipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	swingTripInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cashDueInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	earlyCutoffCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freezeCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	guaranteedCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headloadCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	liftgateInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	timeDateCriticalInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	appointmentInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	foodInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalBillCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalPiecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalPalletCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalWeightCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalVolumeCubicFeet:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalCubePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalMmCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * An update operat
	 * ion uses this to version check the record to ensure another transaction has not 
	 * updated the row after this transaction had read the row and is now about to upda
	 * te it.
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Defines the specific 
	 * 
	 * type of service provided for a shipment.    Added 10/23/2019                           
	 * 
	 * 																																																	
	 * Values:  
	 * 1 - Normal LTL
	 * 2 - Con-Way Deferred Express (Deprecated)
	 * 3 - LTL Expedite Service
	 * 4 - Guaranteed by 12
	 * Required: false
	 * Array: false
	 */
	serviceTypeCd:ShipmentServiceTypeCd = undefined;
}
/**
 * 
 */
export class TimeDateCritical {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded: Time Date Critical Source Code
	 * 
	 * Desc: Where the Time Date Critical information was entered.
	 * 
	 * Valid Values:
	 * BIL=Bill Entered
	 * PUR=Pick Up Request
	 * EDI=EDI
	 * WEB=Web
	 * COR=Corrections
	 * Required: false
	 * Array: false
	 */
	tdcSourceCd:string = undefined;
	/**
	 * Expanded: Time Date Critical Date Type Code
	 * 
	 * Desc: What kind the dates on the TDC shipment is/are.  There are 3 main flavors of date on Time Date Critical shipments: Deliver on a date, Deliver by a date, or a Deliver sometime within a date range  
	 * 
	 * Valid Values:
	 * ON=On
	 * BY=By
	 * RNG=Range
	 * Required: false
	 * Array: false
	 */
	tdcDateTypeCd:string = undefined;
	/**
	 * Expanded: Time Date Critical Date #1
	 * 
	 * Desc: There are 3 flavors of date for a TDC shipment.  This attribute captures the following flavor of date: ON, BY or FROM
	 * Required: false
	 * Array: false
	 */
	tdcDate1:string = undefined;
	/**
	 * Expanded: Time Date Critical Date #2
	 * 
	 * Desc: There are 3 flavors of date for a TDC shipment.  This attribute captures the TO date if the type is a date range.
	 * Required: false
	 * Array: false
	 */
	tdcDate2:string = undefined;
	/**
	 * Expanded: Time Date Critical Time Type
	 * 
	 * Desc: What kind the times on the TDC shipment is/are.  There are 4 main flavors of time for Time Date Critical shipments: Deliver at a time, Deliver before a time, Deliver after a time, or a Deliver sometime within a time range  
	 * 
	 * Valid Values:
	 * AT=At
	 * BEF=Before
	 * AFT=After
	 * RNG=Range
	 * Required: false
	 * Array: false
	 */
	tdcTimeTypeCd:string = undefined;
	/**
	 * Expanded: Time Date Critical Time #1
	 * 
	 * Desc: There are 4 flavors of time for a TDC shipment.  This attribute captures the following flavor of time: AT, AFTER, BEFORE, FROM
	 * Required: false
	 * Array: false
	 */
	tdcTime1:string = undefined;
	/**
	 * Expanded: Time Date Critical Time #2
	 * 
	 * Desc: There are 4 flavors of time for a TDC shipment.  This attribute captures the TO time if the type is a time range.
	 * Required: false
	 * Array: false
	 */
	tdcTime2:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Holds a snapshot of TimeDateCritical. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class TimeDateCriticalSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:TimeDateCritical = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:TimeDateCritical = undefined;
}
/**
 * Details about a message queue. E.g. Oracle Advanced Queue
 */
export class SymphonyQueue {
	/**
	 * Name of the queue
	 * Required: false
	 * Array: false
	 */
	name:string = undefined;
	/**
	 * Maximum number of retries
	 * Required: false
	 * Array: false
	 */
	maxRetries:number = undefined;
	/**
	 * Delay for retries
	 * Required: false
	 * Array: false
	 */
	retryDelay:number = undefined;
	/**
	 * Indicates if enqueue is enabled
	 * Required: false
	 * Array: false
	 */
	enqueueEnabled:string = undefined;
	/**
	 * Indicates if dequeue is enabled
	 * Required: false
	 * Array: false
	 */
	dequeueEnabled:string = undefined;
	/**
	 * The retention time
	 * Required: false
	 * Array: false
	 */
	retention:string = undefined;
}
/**
 * Details of a symphony message
 */
export class SymphonyMessage {
	/**
	 * ID of the message
	 * Required: false
	 * Array: false
	 */
	messageId:string = undefined;
	/**
	 * Timestamp of when the message was enqueued
	 * Required: false
	 * Array: false
	 */
	enqueueTmst:Date = undefined;
	/**
	 * Number of time the message was retried
	 * Required: false
	 * Array: false
	 */
	retryCount:number = undefined;
	/**
	 * Message payload
	 * Required: false
	 * Array: false
	 */
	payload:string = undefined;
}
/**
 * This type will be used to publish documents targeted for Elastic Search on a Kafka topic. The type includes KafkaMessageHeader.
 */
export class DeliveryShipmentSearchRecordMessage extends DeliveryShipmentSearchRecord {
	/**
	 * Additional details about the document
	 * Required: false
	 * Array: false
	 */
	messageHeader:SearchRecordMessageHeader = undefined;
}
/**
 * This type will be used to publish documents targeted for Elastic Search on a Kafka topic. The type includes KafkaMessageHeader.
 */
export class EnsembleStateSearchRecordMessage extends EnsembleStateSearchRecord {
	/**
	 * Additional details about the document
	 * Required: false
	 * Array: false
	 */
	messageHeader:SearchRecordMessageHeader = undefined;
}



