/**
 * AppMetadata API
 * Version: 1.0
 * Build: 1.0.0.12
 */

import {
	ListInfo,
	AuditInfo,
	ActionCd
} from '@xpo-ltl/sdk-common';

/**
 * Path parameter used to retrieve app metadata.
 */
export class GetAppMetadataPath {
	/**
	 * Required: true
	 * Array: false
	 */
	appName:string = undefined;
}
/**
 * Contains information about an app such as minimum version, supported browsers and version etc.
 */
export class GetAppMetadataResp {
	/**
	 * The name of the app
	 * Required: false
	 * Array: false
	 */
	appName:string = undefined;
	/**
	 * The minimum supported app version
	 * Required: false
	 * Array: false
	 */
	minAppVersion:string = undefined;
	/**
	 * List of supported browsers
	 * Required: false
	 * Array: true
	 */
	supportedBrowsers:Browser[] = undefined;
}
/**
 * List of applications
 */
export class ListApplicationsResp {
	/**
	 * Required: false
	 * Array: true
	 */
	applications:Application[] = undefined;
}
/**
 * Path parameter for the request to perform an app global search.
 */
export class PerformAppGlobalSearchPath {
	/**
	 * PRO number, Claims number or Disputes number are the expected key values for the first release.
	 * Required: true
	 * Array: false
	 */
	searchTxt:string = undefined;
}
/**
 * Query parameter for the request to perform an app global search.
 */
export class PerformAppGlobalSearchQuery {
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to perform an app global search.
 */
export class PerformAppGlobalSearchResp {
	/**
	 * A list of global search results returned for the key value entered.
	 * Required: false
	 * Array: false
	 */
	globalSearchResult:GlobalSearchResult = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Details about a loaded trailer.
 */
export class GlobalSearchResult {
	/**
	 * Search results relating to Shipment.
	 * Required: false
	 * Array: true
	 */
	shipmentSearchResults:ShipmentSearchResult[] = undefined;
	/**
	 * Search results relating to Invoice.
	 * Required: false
	 * Array: true
	 */
	invoiceSearchResults:InvoiceSearchResult[] = undefined;
	/**
	 * Search results relating to Claims.
	 * Required: false
	 * Array: true
	 */
	claimSearchResults:ClaimSearchResult[] = undefined;
	/**
	 * Search results relating to Disputes.
	 * Required: false
	 * Array: true
	 */
	disputeSearchResults:DisputeSearchResult[] = undefined;
}
/**
 * Details about shipment search results.
 */
export class ShipmentSearchResult {
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupDt:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneeName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billToName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	appName:string = undefined;
}
/**
 * Details about invoice search results.
 */
export class InvoiceSearchResult {
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupDt:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	invoiceDt:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	invoiceType:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneeName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billToName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	appName:string = undefined;
}
/**
 * Details about invoice search results.
 */
export class ClaimSearchResult {
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupDt:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	claimId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	filingDt:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	claimantName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	appName:string = undefined;
}
/**
 * Details about invoice search results.
 */
export class DisputeSearchResult {
	/**
	 * Required: false
	 * Array: false
	 */
	disputeId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	disputeType:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	disputeStatus:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	claimantName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	inspectorName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	debtorName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	workflowItemInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	appName:string = undefined;
}
/**
 * List of Applications for LTL (added 4/8/2019)
 */
export class Application {
	/**
	 * A unique id for the application
	 * Required: false
	 * Array: false
	 */
	applInstId:number = undefined;
	/**
	 * Name of the application
	 * Required: false
	 * Array: false
	 */
	appName:string = undefined;
	/**
	 * Path to the application, not including the 
	 * 
	 * host
	 * Required: false
	 * Array: false
	 */
	url:string = undefined;
	/**
	 * The minimum supported 
	 * 
	 * version of the application.
	 * Required: false
	 * Array: false
	 */
	minAppVersion:string = undefined;
	/**
	 * An image icon for the application
	 * Required: false
	 * Array: false
	 */
	icon:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	supportedBrowsers:Browser[] = undefined;
}
/**
 * List of browsers for LTL (added 4/8/2019)
 */
export class Browser {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	browserInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	minVersion:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Supported browsers by application (added 
 * 
 * 4/8/2019)
 */
export class SupportedBrowser {
	/**
	 * A unique id for the 
	 * 
	 * application
	 * Required: false
	 * Array: false
	 */
	applInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	browserInstId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}



