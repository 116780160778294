/**
 * ExceptionManagement API
 * Version: 1.0
 * Build: 1.0.0.13
 */

import {
	XrtSearchQueryHeader,
	XrtSearchRespHeader,
	KafkaMessageHeader,
	SearchRecordMessageHeader,
	XrtAttributeFilter,
	XrtSortExpression,
	AuditInfo,
	ActionCd,
	ExpectationOwnerTypeCd,
	ExpectationStatusCd
} from '@xpo-ltl/sdk-common';

/**
 * Path parameter used to create a freight exception.
 */
export class CreateExceptionPath {
	/**
	 * The unique identifier for the exception.
	 * Required: true
	 * Array: false
	 */
	expectationInstId:string = undefined;
	/**
	 * The sequence number associated with the expecation detail.
	 * Required: true
	 * Array: false
	 */
	expectationDetailSequenceNbr:string = undefined;
}
/**
 * Request to create a freight exception.
 */
export class CreateExceptionRqst {
	/**
	 * A freight exception.
	 * Required: false
	 * Array: false
	 */
	exception:Exception = undefined;
}
/**
 * Response to the request to create a freight exception.
 */
export class CreateExceptionResp {
	/**
	 * The exception alert that was created.
	 * Required: false
	 * Array: false
	 */
	exceptionAlert:ExceptionAlert = undefined;
}
/**
 * Path parameter used to create expectations.
 */
export class CreateExpectationsPath {
	/**
	 * The code that represents the expectation type.
	 * Required: true
	 * Array: false
	 */
	typeCd:string = undefined;
}
/**
 * Request to create expectations.
 */
export class CreateExpectationsRqst {
	/**
	 * One or more expectation headers.
	 * Required: false
	 * Array: true
	 */
	expectations:ExpectationHeader[] = undefined;
}
/**
 * Response to the request to create expectations.
 */
export class CreateExpectationsResp {
	/**
	 * A list of expectations that were created.
	 * Required: false
	 * Array: true
	 */
	expectations:ExpectationHeader[] = undefined;
}
/**
 * Path parameter used to create an expectation type.
 */
export class CreateExpectationTypePath {
	/**
	 * The code that represents the expectation type.
	 * Required: true
	 * Array: false
	 */
	typeCd:string = undefined;
}
/**
 * Request to create an expectation type.
 */
export class CreateExpectationTypeRqst {
	/**
	 * The description of the expectation type.
	 * Required: true
	 * Array: false
	 */
	typeDescription:string = undefined;
	/**
	 * A list of roles that will be assigned to the expectation type.
	 * Required: false
	 * Array: true
	 */
	expectationTypeRoles:ExpectationTypeRole[] = undefined;
}
/**
 * Response to the request create an expecation type.
 */
export class CreateExpectationTypeResp {
	/**
	 * The code associated with the type that was created.
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
}
/**
 * Path parameter used to delete an expectation type.
 */
export class DeleteExpectationTypePath {
	/**
	 * The code that represents the expectation type.
	 * Required: true
	 * Array: false
	 */
	typeCd:string = undefined;
}
/**
 * Requeset to delete an expectation type.
 */
export class DeleteExpectationTypeRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	typeDescription:string = undefined;
}
/**
 * Response to the request to delete an expectation type.
 */
export class DeleteExpectationTypeResp {
	/**
	 * The code that represents the expectation type.
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
}
/**
 * Path parameter used to delete an expectation type role.
 */
export class DeleteExpectationTypeRolePath {
	/**
	 * The unique identifier for the expectation type role.
	 * Required: true
	 * Array: false
	 */
	expectationTypeRoleInstId:number = undefined;
}
/**
 * Query parameters used to delete an expectation type role.
 */
export class DeleteExpectationTypeRoleQuery {
	/**
	 * The identifier associated with the employee role.
	 * Required: false
	 * Array: false
	 */
	emplRoleId:number = undefined;
	/**
	 * The code that represents the expectation type.
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
}
/**
 * Path parameters for list expectations
 */
export class ListExpectationsPath {
	/**
	 * Code indicating the owner.
	 * Required: true
	 * Array: false
	 */
	expectationOwnerTypeCd:ExpectationOwnerTypeCd = undefined;
	/**
	 * The identfier which corresponds to the owner type.
	 * Required: true
	 * Array: false
	 */
	expectationOwnerId:string = undefined;
}
/**
 * Query paramters used to filter the list of expectations.
 */
export class ListExpectationsQuery {
	/**
	 * The exception type. Optional filter to restrict to specified expectation type.
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * If set to true, return only the current expectations for the given owner.
	 * Required: false
	 * Array: false
	 */
	currentInd:boolean = undefined;
}
/**
 * Response to the request to list expectations.
 */
export class ListExpectationsResp {
	/**
	 * List of expectation headers.
	 * Required: false
	 * Array: true
	 */
	expectations:ExpectationHeader[] = undefined;
}
/**
 * Request to list expectation types.
 */
export class ListExpectationTypesRqst {
}
/**
 * Response to the requst to list expectation types.
 */
export class ListExpectationTypesResp {
	/**
	 * The list of expectation types retrieved.
	 * Required: false
	 * Array: true
	 */
	expectationTypes:ExpectationType[] = undefined;
}
/**
 * Path parameter used to update the status of an expectation.
 */
export class UpdateExpectationStatusPath {
	/**
	 * The unique identifier of the expectation.
	 * Required: true
	 * Array: false
	 */
	expectationInstId:string = undefined;
	/**
	 * The sequence number associated with the expectation.
	 * Required: true
	 * Array: false
	 */
	expectationDetailSequenceNbr:string = undefined;
}
/**
 * Request to update the status of an expectation.
 */
export class UpdateExpectationStatusRqst {
	/**
	 * The code associated with the status which will be updated for the expecation.  Valid transitions are New to Met or Exception and Exception to Met.
	 * Required: true
	 * Array: false
	 */
	statusCd:ExpectationStatusCd = undefined;
}
/**
 * Path parameter used to update an expectation type.
 */
export class UpdateExpectationTypePath {
	/**
	 * The code that represents the expectation type.
	 * Required: true
	 * Array: false
	 */
	typeCd:string = undefined;
}
/**
 * Request to update an expectation type.
 */
export class UpdateExpectationTypeRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	typeDescription:string = undefined;
	/**
	 * A list of expectation type roles.
	 * Required: false
	 * Array: true
	 */
	expectationTypeRoles:ExpectationTypeRole[] = undefined;
}
/**
 * Response to the request to update an expectation type.
 */
export class UpdateExpectationTypeResp {
	/**
	 * The code that represents the expectation type.
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
}
/**
 * Request to upsert an expectation type role.
 */
export class UpsertExpectationTypeRoleRqst {
	/**
	 * Required: true
	 * Array: false
	 */
	expectationTypeRoleInstId:number = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	emplRoleId:number = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	actionCd:ActionCd = undefined;
}
/**
 * Response to the request to upsert an expectation type role.
 */
export class UpsertExpectationTypeRoleResp {
	/**
	 * The expectation type role that was upserted.
	 * Required: false
	 * Array: false
	 */
	expectationType:ExpectationType = undefined;
}
/**
 * Request for searching exceptions.
 */
export class SearchExceptionsRqst {
	/**
	 * Specify page size, sort order etc
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchQueryHeader = undefined;
	/**
	 * The query for searching elastic/XRT
	 * Required: false
	 * Array: false
	 */
	filter:ExpectationSearchFilter = undefined;
}
/**
 * Response to the request to search exceptions.
 */
export class SearchExceptionsResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	results:ExpectationSearchRecord[] = undefined;
}
/**
 * Query parameters used to filter a list of exceptions.
 */
export class ListExceptionsQuery {
	/**
	 * The location to filter by.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The code used to filter by shift.
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * The date that the exception was created.
	 * Required: false
	 * Array: false
	 */
	createdDate:Date = undefined;
	/**
	 * The code that identifies the region to filter by.
	 * Required: false
	 * Array: false
	 */
	regionCd:string = undefined;
	/**
	 * The type of exception to filter by.
	 * Required: false
	 * Array: false
	 */
	exceptionType:string = undefined;
	/**
	 * The trailer number associated with the exceptions.
	 * Required: false
	 * Array: false
	 */
	trailerNbr:string = undefined;
	/**
	 * The status code of the exceptions.
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
}
/**
 * Response for the request to list exception alert details.
 */
export class ListExceptionsResp {
	/**
	 * List of exception alerts.
	 * Required: false
	 * Array: true
	 */
	exceptionAlerts:ExceptionAlert[] = undefined;
}
/**
 * Elastic Search document for Expectation details
 */
export class ExpectationSearchRecord {
	/**
	 * The id of the expectation
	 * Required: false
	 * Array: false
	 */
	expectationInstId:number = undefined;
	/**
	 * Shift Code this Expectations applies to
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * SIC the expectation was created for
	 * Required: false
	 * Array: false
	 */
	expectationSic:string = undefined;
	/**
	 * The region that the Expection SIC is in.  Added 10/02/2019
	 * Required: false
	 * Array: false
	 */
	regionCd:string = undefined;
	/**
	 * This is the timestamp when this row was created in the search repository.
	 * Required: false
	 * Array: false
	 */
	createdTmst:Date = undefined;
	/**
	 * This is the timestamp when this row was inserted/update in the search repository.
	 * Required: false
	 * Array: false
	 */
	lstUpdtTmst:Date = undefined;
	/**
	 * This is the timestamp when the original event was created.
	 * Required: false
	 * Array: false
	 */
	eventTmst:Date = undefined;
	/**
	 * The expectation header along with the exception details
	 * Required: false
	 * Array: false
	 */
	expectationHeader:ExpectationHeader = undefined;
}
/**
 * 
 */
export class ExceptionAlert {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exception:Exception = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expectationHeader:ExpectationHeader = undefined;
}
export class ExpectationSearchFilter {
	/**
	 * Required: false
	 * Array: false
	 */
	q:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	b:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	expectationSic:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	expectationSic_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shiftCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shiftCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	regionCd:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	regionCd_keyword:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	createdTmst:XrtAttributeFilter = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	createdTmst_keyword:XrtAttributeFilter = undefined;
	public toJSON = function(this: ExpectationSearchFilter) {
		return {
			"q" : this.q,
			"b" : this.b,
			"expectationSic" : this.expectationSic,
			"expectationSic.keyword" : this.expectationSic_keyword,
			"shiftCd" : this.shiftCd,
			"shiftCd.keyword" : this.shiftCd_keyword,
			"regionCd" : this.regionCd,
			"regionCd.keyword" : this.regionCd_keyword,
			"createdTmst" : this.createdTmst,
			"createdTmst.keyword" : this.createdTmst_keyword		}
	}
}
/**
 * Path parameters
 */
export class GetElasticDocumentExpectationPath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	expectationInstId:string = undefined;
}
/**
 * Query parameters
 */
export class GetElasticDocumentExpectationQuery {
	/**
	 * Source filter
	 * Required: false
	 * Array: false
	 */
	_source:string = undefined;
	/**
	 * Source include filter
	 * Required: false
	 * Array: false
	 */
	_source_include:string = undefined;
	/**
	 * Source exclude filter
	 * Required: false
	 * Array: false
	 */
	_source_exclude:string = undefined;
}
/**
 * Response from getElasticDocument.
 */
export class GetElasticDocumentExpectationResp {
	/**
	 * The record if found
	 * Required: false
	 * Array: false
	 */
	record:ExpectationSearchRecord = undefined;
}
/**
 * Query parameters
 */
export class GetElasticDocumentExpectationCountQuery {
	/**
	 * Query filter
	 * Required: false
	 * Array: false
	 */
	q:string = undefined;
}
/**
 * Response from getElasticDocumentCount.
 */
export class GetElasticDocumentExpectationCountResp {
	/**
	 * The count of the documents
	 * Required: false
	 * Array: false
	 */
	count:number = undefined;
}
/**
 * Request message to update information for Expectation.
 */
export class UpdateElasticDocumentExpectationRqst {
	/**
	 * The record to upsert in elastic
	 * Required: false
	 * Array: false
	 */
	doc:ExpectationSearchRecord = undefined;
	/**
	 * Indicate if an upsert operation should be executed if the document was not found
	 * Required: false
	 * Array: false
	 */
	doc_as_upsert:boolean = undefined;
}
/**
 * Path parameters
 */
export class UpdateElasticDocumentExpectationPath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	expectationInstId:string = undefined;
}
/**
 * Response from updateElasticDocument.
 */
export class UpdateElasticDocumentExpectationResp {
	/**
	 * The result of the operation
	 * Required: false
	 * Array: false
	 */
	result:string = undefined;
}
/**
 * Path parameters
 */
export class DeleteElasticDocumentExpectationPath {
	/**
	 * Uniquely identify the elastic search document
	 * Required: true
	 * Array: false
	 */
	expectationInstId:string = undefined;
}
/**
 * Request message to delete information for Expectation.
 */
export class DeleteElasticDocumentExpectationResp {
	/**
	 * The result of the operation
	 * Required: false
	 * Array: false
	 */
	result:string = undefined;
}
/**
 * Request message to search information for expectation-unfiltered.
 */
export class SearchExpectationUnfilteredRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	pageNumber:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	sortExpressions:XrtSortExpression[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pageSize:number = undefined;
	/**
	 * Query for searching
	 * Required: false
	 * Array: false
	 */
	filter:ExpectationSearchFilter = undefined;
}
/**
 * Response to the request to search information for expectation-unfiltered.
 */
export class SearchExpectationUnfilteredResp {
	/**
	 * Required: false
	 * Array: false
	 */
	header:XrtSearchRespHeader = undefined;
	/**
	 * Search results
	 * Required: false
	 * Array: true
	 */
	result:ExpectationSearchRecord[] = undefined;
}
/**
 * Request for register filter
 */
export class RegisterFilterExpectationUnfilteredRqst {
	/**
	 * Id of the  user
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The filter to register
	 * Required: false
	 * Array: false
	 */
	filter:ExpectationSearchFilter = undefined;
}
/**
 * Response for register filter
 */
export class RegisterFilterExpectationUnfilteredResp {
	/**
	 * Hash for the filter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
	/**
	 * Duration for the API execution
	 * Required: false
	 * Array: false
	 */
	duration:number = undefined;
	/**
	 * Number of registrations created
	 * Required: false
	 * Array: false
	 */
	numberOfRegistrations:number = undefined;
}
/**
 * Request for register
 */
export class UnregisterFilterExpectationUnfilteredRqst {
	/**
	 * Id of the  user. For future use.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Connection token
	 * Required: false
	 * Array: false
	 */
	connectionToken:string = undefined;
	/**
	 * The hash of the filter to unregister. Filter has is returned by registerFilter
	 * Required: false
	 * Array: false
	 */
	filterHash:string = undefined;
}
/**
 * Request for changes feed
 */
export class ChangesFeedExpectationUnfilteredRqst {
	/**
	 * A list of document Ids
	 * Required: false
	 * Array: true
	 */
	changedDocumentIds:number[] = undefined;
	/**
	 * A list of changed documents
	 * Required: false
	 * Array: true
	 */
	changedDocuments:ExpectationSearchRecord[] = undefined;
}
/**
 * 
 */
export class Exception {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exceptionInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	priority:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	postedDateTimeUtc:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	activeDateTimeUtc:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	resolvedDateTimeUtc:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nbrOfMinimumBeforeExpiry:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	resolution:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	operationException:OperationException = undefined;
}
/**
 * 
 */
export class ExpectationDetail {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expectationInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expectationDetailSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	valueKey:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	value:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	valueDateTimeUtc:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:ExpectationStatusCd = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	exception:Exception[] = undefined;
}
/**
 * 
 */
export class ExpectationHeader {
	/**
	 * Primary key, sequential number
	 * Required: false
	 * Array: false
	 */
	expectationInstId:number = undefined;
	/**
	 * Date for which this expectation was created for
	 * Required: false
	 * Array: false
	 */
	expectationDate:string = undefined;
	/**
	 * Shift Code this Expectations applies to
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * SIC the expectation was created for
	 * Required: false
	 * Array: false
	 */
	expectationSic:string = undefined;
	/**
	 * Destination SIC for a lane or for a shipment
	 * Required: false
	 * Array: false
	 */
	destinationSic:string = undefined;
	/**
	 * Door for which expectation is set up. If not at the door level - null
	 * Required: false
	 * Array: false
	 */
	doorNbr:string = undefined;
	/**
	 * Trailer ID for which expectation is set up. If not at trailer level, then null
	 * Required: false
	 * Array: false
	 */
	trailerId:number = undefined;
	/**
	 * Concatenated version of Trailer Prefix + Suffix; Trailer NBR for which expectation is set up. If not at trailer level, then null
	 * Required: false
	 * Array: false
	 */
	trailerNbr:string = undefined;
	/**
	 * Shipment PRO for which expectation is set up. If not at the PRO level - null
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * The region that the Expection SIC is in.  Added 10/02/2019
	 * Required: false
	 * Array: false
	 */
	regionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	expectationDetail:ExpectationDetail[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expectationType:ExpectationType = undefined;
}
/**
 * 
 */
export class ExpectationType {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeDescription:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class ExpectationTypeRole {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expectationTypeRoleInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	employeeRoleId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class OperationException {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exceptionInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loggedSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	responsibleSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	resolvedSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ltlProduct:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	doorNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	actualValue:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * This type will be used to publish documents targeted for Elastic Search on a Kafka topic. The type includes KafkaMessageHeader.
 */
export class ExceptionKafkaMessage extends ExpectationSearchRecord {
	/**
	 * Additional details about the document
	 * Required: false
	 * Array: false
	 */
	searchRecordMessageHeader:KafkaMessageHeader = undefined;
}
/**
 * This type will be used to publish documents targeted for Elastic Search on a Kafka topic. The type includes KafkaMessageHeader.
 */
export class ExpectationSearchRecordMessage extends ExpectationSearchRecord {
	/**
	 * Additional details about the document
	 * Required: false
	 * Array: false
	 */
	messageHeader:SearchRecordMessageHeader = undefined;
}



