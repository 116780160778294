import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	GetGeoAreasForLocationResp,
	GetGeoAreasForLocationQuery,
	GetGeoAreasForLocationPath,
	GetHostSicDetailsResp,
	GetHostSicDetailsPath,
	GetLocReferenceDetailsBySicResp,
	GetLocReferenceDetailsBySicQuery,
	GetLocReferenceDetailsBySicPath,
	GetSectorByDoorNumberResp,
	GetSectorByDoorNumberPath,
	GetServiceCenterDetailsBySicResp,
	GetServiceCenterDetailsBySicPath,
	GetZoneAndSatelliteBySicResp,
	GetZoneAndSatelliteBySicQuery,
	GetZoneAndSatelliteBySicPath,
	ListAllActiveDoorsBySicResp,
	ListAllActiveDoorsBySicQuery,
	ListAllActiveDoorsBySicPath,
	ListAllActiveSicsResp,
	ListAllSicCdsByRegionResp,
	ListAllSicCdsByRegionPath,
	ListLocationFeatureValuesByFeatureResp,
	ListLocationFeatureValuesByFeatureQuery,
	ListLocationFeatureValuesByFeaturePath,
	ListLocationGroupCodesResp,
	ListLocationGroupCodesQuery,
	ListLocationsRqst,
	ListLocationsResp,
	ListLocationsQuery,
	ListOperationalServiceCentersResp,
	ListOperationalServiceCentersQuery
} from './api-location';

import {
	GetHealthCheckResp,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class LocationApiService extends BaseService {
	private static LocationApiEndpoint = 'locationApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns geo area information about a location. 
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The SIC code and type code are required.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If a geo area is found for the SIC and type provided, then information about it will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public getGeoAreasForLocation(
							   pathParams: GetGeoAreasForLocationPath,
							   queryParams: GetGeoAreasForLocationQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetGeoAreasForLocationResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/areas/{polygonTypeCd}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves the host SIC for the requested zone SIC. 
	* <br/>
	* <br/>Mandatory Criteria:
	* <br/>- Zone SIC
	* <br/>           
	*/
	public getHostSicDetails(
							   pathParams: GetHostSicDetailsPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHostSicDetailsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/host/{sicCd}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns location reference information about a service center.
	*/
	public getLocReferenceDetailsBySic(
							   pathParams: GetLocReferenceDetailsBySicPath,
							   queryParams: GetLocReferenceDetailsBySicQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetLocReferenceDetailsBySicResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/loc-reference-sics/{sicCd}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns the sector number that is associated with the door number provided.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The SIC and door number are required.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the input provided is valid, then a sector number will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public getSectorByDoorNumber(
							   pathParams: GetSectorByDoorNumberPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetSectorByDoorNumberResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/doors/{doorNumber}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns location information about a service center. This information shall include the service center's physical address as well as geocoordinates for use in GIS applications.
	*/
	public getServiceCenterDetailsBySic(
							   pathParams: GetServiceCenterDetailsBySicPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetServiceCenterDetailsBySicResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns the zone and satellite details for a specific SIC location.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The SIC code is required.  The Boolean fields are optional.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the data provided is valid, info about the zone and satellite will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public getZoneAndSatelliteBySic(
							   pathParams: GetZoneAndSatelliteBySicPath,
							   queryParams: GetZoneAndSatelliteBySicQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetZoneAndSatelliteBySicResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/zone-satellite/{sicCd}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This oepration returns a list of all active doors for the service center provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. The sicCd is provided and valid.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then a list of all active doors is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public listAllActiveDoorsBySic(
							   pathParams: ListAllActiveDoorsBySicPath,
							   queryParams: ListAllActiveDoorsBySicQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListAllActiveDoorsBySicResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/all-active-doors'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns a list all active service service centers and their region, city, state, and if they are a FAC or OB.
	*/
	public listAllActiveSics(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListAllActiveSicsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/all-active-sics'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns a list of service centers
	*/
	public listAllSicCdsByRegion(
							   pathParams: ListAllSicCdsByRegionPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListAllSicCdsByRegionResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/sics-by-region/{locGroupCode}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves data for a location feature. The list can be restricted to those of a specific feature value, e.g. 'Y' or 'true' or 'New'. A feature relates to an application. We use the feature codes to identify whether a feature or application has been rolled out to a SIC, handheld device, or employee. For example, if we wanted to track which SICs have been rolled out to use the ELD / Vnomics system, we would create an ELD flag.
	* <br/>
	* <br/>Mandatory Criteria:
	* <br/>- Feature Cd
	* <br/>
	* <br/>Optional Criteria:
	* <br/>- Feature Setting Value	
	*/
	public listLocationFeatureValuesByFeature(
							   pathParams: ListLocationFeatureValuesByFeaturePath,
							   queryParams: ListLocationFeatureValuesByFeatureQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListLocationFeatureValuesByFeatureResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/features/{featureCd}/feature-values'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns a list of all location group codes.
	* <br/>â
	* <br/>Pre-conditions:
	* <br/>1. Group category code may be provided (LHO_LHAREA_ALL, LHO_MODEL_GRP) to return all location group codes for the category. 
	* <br/>2. A group category code and SIC may be provided to return a specific location group code.
	* <br/>â
	* <br/>Post-conditions:
	* <br/>1. If successful, then a list of all location group codes will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public listLocationGroupCodes(
							   queryParams: ListLocationGroupCodesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListLocationGroupCodesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/location-group-codes'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns a list of locations and their zones and satellites, including their regions, cities, states, and if they are a FAC or OB. If no input SIC is provided, all locations satisfying the other criteria are returned. 
	* <br/>		
	* <br/>Pre-conditions:
	* <br/>1. The activeInd, zoneDesiredInd, and satelliteDesiredInd fields are optional.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the data provided is valid, then the list of matching locations is returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	* <br/>		
	* <br/>		
	*/
	public listLocations(request: ListLocationsRqst,
							   queryParams: ListLocationsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListLocationsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/locations'),
			{
				queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Lists operational service centers.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>None 
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. It should return a list of all existing operational servie centers.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public listOperationalServiceCenters(
							   queryParams: ListOperationalServiceCentersQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListOperationalServiceCentersResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(LocationApiService.LocationApiEndpoint);
	}
}
