import {PnDOptimizerStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class PnDOptimizerStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PnDOptimizerStatusCd ) {
      this._values.push(PnDOptimizerStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PnDOptimizerStatusCd {

    for ( const obj in PnDOptimizerStatusCd ) {
      if (PnDOptimizerStatusCd[obj] === value){
        return PnDOptimizerStatusCd[obj] as PnDOptimizerStatusCd;
      }
    }
  }
}

const PnDOptimizerStatusCdHelper = new PnDOptimizerStatusCdHelperEnumHelperClass();
export default PnDOptimizerStatusCdHelper;
