import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	AssignShipmentsToRouteRqst,
	AssignShipmentsToRouteResp,
	AssignShipmentsToRoutePath,
	AutoSequencePnDStopsRqst,
	AutoSequencePnDStopsResp,
	BalancePnDRoutesRqst,
	BalancePnDRoutesResp,
	BalancePnDRoutesPath,
	CalculatePnDTripMetricsRqst,
	CalculatePnDTripMetricsResp,
	CreateNewRouteRqst,
	CreateNewRouteResp,
	DeletePnDRoutesQuery,
	DeletePnDRoutesPath,
	EvaluatePnDRouteBalancingRqst,
	EvaluatePnDRouteBalancingResp,
	GetFinalConsigneeDestinationArrivalTmstByProResp,
	GetFinalConsigneeDestinationArrivalTmstByProPath,
	GetPnDDriverLocationResp,
	GetPnDDriverLocationPath,
	GetPnDInboundSelectionProfileResp,
	GetPnDInboundSelectionProfilePath,
	GetPnDRouteResp,
	GetPnDRouteQuery,
	GetPnDRoutePath,
	GetPnDStopResp,
	GetPnDStopPath,
	GetPnDTripResp,
	GetPnDTripPath,
	GetPnDTripStopsResp,
	GetPnDTripStopsPath,
	ListPnDDriverLocationsResp,
	ListPnDDriverLocationsPath,
	ListPnDInboundSelectionProfilesResp,
	ListPnDInboundSelectionProfilesQuery,
	ListPnDRoutesResp,
	ListPnDRoutesQuery,
	ListPnDRoutesPath,
	ListPnDServiceCenterMetricsResp,
	ListPnDServiceCenterMetricsQuery,
	ListPnDServiceCenterMetricsPath,
	ListPnDStopsResp,
	ListPnDStopsPath,
	ListPnDSuggestedRouteNamesResp,
	ListPnDSuggestedRouteNamesQuery,
	ListPnDSuggestedRouteNamesPath,
	ListPnDTripsResp,
	ListPnDTripsQuery,
	ListPnDTripsPath,
	ListPnDUnassignedPickupsRqst,
	ListPnDUnassignedPickupsResp,
	ListPnDUnassignedStopsRqst,
	ListPnDUnassignedStopsResp,
	ReassignPnDShipmentsRqst,
	ReassignPnDShipmentsPath,
	ReassignStopsRqst,
	ReassignStopsResp,
	ResequenceFreightByRouteRqst,
	ResequenceFreightByTripRqst,
	UpdateDeliveryShipmentExcludedTrailerIndRqst,
	UpdateDeliveryShipmentExcludedTrailerIndPath,
	UpdatePnDRouteRqst,
	UpdatePnDRouteResp,
	UpdatePnDRoutePath,
	UnassignPnDShipmentsFromRouteRqst,
	UnassignPnDShipmentsFromRoutePath,
	UnassignStopsRqst,
	ValidatePnDRouteRqst,
	ValidatePnDRouteResp,
	ValidatePnDRoutePath,
	AssignPickupsToPnDTripRqst,
	AssignPickupsToPnDTripPath,
	UnregisterFilterRoutesRqst,
	ChangesFeedRoutesRqst,
	RegisterFilterRoutesRqst,
	RegisterFilterRoutesResp,
	UnregisterFilterTripsRqst,
	ChangesFeedTripsRqst,
	RegisterFilterTripsRqst,
	RegisterFilterTripsResp,
	UnregisterFilterServiceCenterMetricsRqst,
	ChangesFeedServiceCenterMetricsRqst,
	RegisterFilterServiceCenterMetricsRqst,
	RegisterFilterServiceCenterMetricsResp,
	StopQueuePath,
	StopQueueViaWebhookPath,
	StopQueueViaWebhookQuery,
	DeleteExpiredMessagesResp,
	DeleteExpiredMessagesQuery,
	DeleteExpiredMessagesPath,
	GetEnsembleStatusCountsResp,
	GetEnsembleStatusCountsPath,
	ReplayMessagePath,
	RestartEnsembleRqst,
	RestartEnsembleResp,
	RestartEnsemblePath,
	ListQueuesResp,
	StartQueuePath,
	ResumeEnsembleRqst,
	ResumeEnsembleResp,
	ResumeEnsemblePath,
	ListExpiredMessagesResp,
	ListExpiredMessagesPath,
	KillEnsembleRqst,
	KillEnsembleResp,
	GetQueuePendingCountResp,
	GetQueuePendingCountPath,
	SearchUnassignedDeliveriesRqst,
	SearchUnassignedDeliveriesResp,
	UnregisterFilterUnassignedDeliveriesRqst,
	ChangesFeedUnassignedDeliveriesRqst,
	RegisterFilterUnassignedDeliveriesRqst,
	RegisterFilterUnassignedDeliveriesResp,
	SearchUnbilledShipmentsRqst,
	SearchUnbilledShipmentsResp,
	UnregisterFilterUnbilledShipmentsRqst,
	ChangesFeedUnbilledShipmentsRqst,
	RegisterFilterUnbilledShipmentsRqst,
	RegisterFilterUnbilledShipmentsResp,
	SearchDeliveryShipmentsRqst,
	SearchDeliveryShipmentsResp,
	UnregisterFilterDeliveryShipmentsRqst,
	ChangesFeedDeliveryShipmentsRqst,
	RegisterFilterDeliveryShipmentsRqst,
	RegisterFilterDeliveryShipmentsResp,
	SearchUnmappedShipmentsRqst,
	SearchUnmappedShipmentsResp,
	UnregisterFilterUnmappedShipmentsRqst,
	ChangesFeedUnmappedShipmentsRqst,
	RegisterFilterUnmappedShipmentsRqst,
	RegisterFilterUnmappedShipmentsResp,
	SearchAdminRouteShipmentsRqst,
	SearchAdminRouteShipmentsResp,
	UnregisterFilterAdminRouteShipmentsRqst,
	ChangesFeedAdminRouteShipmentsRqst,
	RegisterFilterAdminRouteShipmentsRqst,
	RegisterFilterAdminRouteShipmentsResp,
	SearchUndeletedShipmentsRqst,
	SearchUndeletedShipmentsResp,
	UnregisterFilterUndeletedShipmentsRqst,
	ChangesFeedUndeletedShipmentsRqst,
	RegisterFilterUndeletedShipmentsRqst,
	RegisterFilterUndeletedShipmentsResp,
	SearchDeliveryShipmentUnfilteredRqst,
	SearchDeliveryShipmentUnfilteredResp,
	UnregisterFilterDeliveryShipmentUnfilteredRqst,
	ChangesFeedDeliveryShipmentUnfilteredRqst,
	RegisterFilterDeliveryShipmentUnfilteredRqst,
	RegisterFilterDeliveryShipmentUnfilteredResp,
	UpdateElasticDocumentDeliveryShipmentRqst,
	UpdateElasticDocumentDeliveryShipmentResp,
	UpdateElasticDocumentDeliveryShipmentPath,
	DeleteElasticDocumentDeliveryShipmentResp,
	DeleteElasticDocumentDeliveryShipmentPath,
	GetElasticDocumentDeliveryShipmentCountResp,
	GetElasticDocumentDeliveryShipmentCountQuery,
	GetElasticDocumentDeliveryShipmentResp,
	GetElasticDocumentDeliveryShipmentQuery,
	GetElasticDocumentDeliveryShipmentPath,
	SearchEnsembleStateUnfilteredRqst,
	SearchEnsembleStateUnfilteredResp,
	UnregisterFilterEnsembleStateUnfilteredRqst,
	ChangesFeedEnsembleStateUnfilteredRqst,
	RegisterFilterEnsembleStateUnfilteredRqst,
	RegisterFilterEnsembleStateUnfilteredResp,
	UpdateElasticDocumentEnsembleStateRqst,
	UpdateElasticDocumentEnsembleStateResp,
	UpdateElasticDocumentEnsembleStatePath,
	DeleteElasticDocumentEnsembleStateResp,
	DeleteElasticDocumentEnsembleStatePath,
	GetElasticDocumentEnsembleStateCountResp,
	GetElasticDocumentEnsembleStateCountQuery,
	GetElasticDocumentEnsembleStateResp,
	GetElasticDocumentEnsembleStateQuery,
	GetElasticDocumentEnsembleStatePath
} from './api-cityoperations';

import {
	GetHealthCheckResp,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class CityOperationsApiService extends BaseService {
	private static CityOperationsApiEndpoint = 'cityoperationsApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation allows one to assign shipments to an existing route
	* <br/>
	* <br/>            Pre-conditions:
	* <br/>            1. A valid service center is provided as a path parameter
	* <br/>            2. A valid route instance ID is provided as a path parameter
	* <br/>            3. A request body specfying the collection of delivery shipment instance ids to be assigned to that route
	* <br/>
	* <br/>            Post-condition:
	* <br/>            1. The modified Trip, with the associated route instance ID, is returned along with the updated accumulators.
	*/
	public assignShipmentsToRoute(request: AssignShipmentsToRouteRqst,
							   pathParams: AssignShipmentsToRoutePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<AssignShipmentsToRouteResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/routes/{routeInstId}/shipments'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation optimizes the order of given stops
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid list of stops is provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. Returns all the given stops in optimized order.
	*/
	public autoSequencePnDStops(request: AutoSequencePnDStopsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<AutoSequencePnDStopsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/stops/autosequence'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation performs route balancing actions consisting of stop sequencing within a route, reassigning a stop from one route to another, and unassigning a stop from a route.  The request document is organized by the type of route (new or existing) as well as Unassigned Stops.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid service center is provided.
	* <br/>2. A valid request body specifying the state of the routes in the Route Balancing Board is provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If successful, the specified route balancing actions are performed and committed or rolled-back as a single atomic database transaction.  The response will include the updated state of the Trips/Routes involved in the route balancing activities.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public balancePnDRoutes(request: BalancePnDRoutesRqst,
							   pathParams: BalancePnDRoutesPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<BalancePnDRoutesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/routes/balancing'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation returns the collection of metrics associated with the specified trip or route.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid calculatePnDTripMetricsRqst is provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. All metrics associated with the specified trip are returned.
	*/
	public calculatePnDTripMetrics(request: CalculatePnDTripMetricsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CalculatePnDTripMetricsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/trips/metrics'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Creates a new route and associates it with a new trip from a list of shipments and exports, the route and trip instance IDs, and the list of stops.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Route prefix and suffix, date and category code for the route are imported.
	* <br/>2. A valid area instance ID is imported.
	* <br/>3. An optional list of shipment instance IDs are imported.
	* <br/>4. A SIC code is imported.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. A trip is created; a route is created and associated with the trip.  If shipments were imported, they are associated with the route/trip and stops are created.  The route and trip instance IDs are exported.
	* <br/>2. Otherwise an appropriate error message is returned. 
	*/
	public createNewRoute(request: CreateNewRouteRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateNewRouteResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/new-routes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation deletes routes and possibly trips associated with the routes.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid service center and route instance IDs are provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, all routes, and possibly trips, that meet the criteria are deleted.
	* <br/>2. Otherwise a list of error messages for why routes cannot be deleted is returned.
	*/
	public deletePnDRoutes(
							   pathParams: DeletePnDRoutesPath,
							   queryParams: DeletePnDRoutesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/routes'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Evaluates the routes based on the type indicator and returns the metrics. 
	* <br/>		
	* <br/>Pre-condition:
	* <br/>1. Valid trips were passed on the request containing trips, routes, and any stops.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. A list of trip metrics are returned.
	*/
	public evaluatePnDRouteBalancing(request: EvaluatePnDRouteBalancingRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<EvaluatePnDRouteBalancingResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/trips/route-balancing'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation retrieves the final consignee destination arrival timestamp for the PRO provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid PRO is provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If the input is valid, then the final consignee destination arrival timestamp for the PRO will be returned.
	* <br/>2. Otherwise a 404 - Not Found error message will be returned.
	*/
	public getFinalConsigneeDestinationArrivalTmstByPro(
							   pathParams: GetFinalConsigneeDestinationArrivalTmstByProPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetFinalConsigneeDestinationArrivalTmstByProResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/final-destination-arrival-times/{proNbr}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves a driver location and pertinent information about the driver.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid employeeId is provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If the input is valid, then the driverLocation for requested employeeId is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	* <br/>2. 
	*/
	public getPnDDriverLocation(
							   pathParams: GetPnDDriverLocationPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetPnDDriverLocationResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/drivers/{employeeId}/location'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves the selection profile for the selectionProfileId. The selection profile is an inbound planning profile.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid selectionProfileId is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the input is valid, then the selection profile, for the ID provided, will be returned.
	* <br/>2. Otherwise a 404 - Not Found error message will be returned.
	*/
	public getPnDInboundSelectionProfile(
							   pathParams: GetPnDInboundSelectionProfilePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetPnDInboundSelectionProfileResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/selection-profiles/{selectionProfileId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves a single route for the route instance ID provided.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid service center is provided.
	* <br/>2. A valid route instance ID is provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. The requested route, with the associated route instance ID, is retrieved.
	*/
	public getPnDRoute(
							   pathParams: GetPnDRoutePath,
							   queryParams: GetPnDRouteQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetPnDRouteResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/routes/{routeInstId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns a single instance of a stop associated with a specific route.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid route instance ID is provided.
	* <br/>2. A valid stop sequence number is provided
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If the input is valid, then the requested route stop is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	* <br/>2. 
	*/
	public getPnDStop(
							   pathParams: GetPnDStopPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetPnDStopResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/routes/{routeInstId}/stops/{tripNodeSequenceNbr}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves trip details.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid service center is provided.
	* <br/>2. A valid trip instance ID is provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If the input is valid, then details for a trip are retrieved.
	* <br/>2. Otherwise an appropriate error message is returned. 
	* <br/>
	*/
	public getPnDTrip(
							   pathParams: GetPnDTripPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetPnDTripResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/trips/{tripInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns stops for a trip based on the trip instance ID provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid trip instance ID is provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. The requested trip, with the associated trip instance id, and its stops are retrieved.
	* <br/>2. If not found, an appropriate error message is returned.
	*/
	public getPnDTripStops(
							   pathParams: GetPnDTripStopsPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetPnDTripStopsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/trips/{tripInstId}/stops'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves a list of driver locations and pertinent information about each driver.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid service center is provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. In the input provided is valid, the requested route summary is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public listPnDDriverLocations(
							   pathParams: ListPnDDriverLocationsPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPnDDriverLocationsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/drivers/locations'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns all the inbound selection profiles for the SIC provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid service center is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, all inbound selection profiles meeting the criteria are returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public listPnDInboundSelectionProfiles(
							   queryParams: ListPnDInboundSelectionProfilesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPnDInboundSelectionProfilesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/selection-profiles'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns all routes that need to be planned for delivery today.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid service center is provided
	* <br/>2. A valid planning date is provided
	* <br/>
	* <br/>Post-condition:
	* <br/>1. All routes meeting the criteria are returned.
	*/
	public listPnDRoutes(
							   pathParams: ListPnDRoutesPath,
							   queryParams: ListPnDRoutesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPnDRoutesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/routes'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns the collection of metrics associated with the specified service center.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid service center is provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. All metrics associated with the specified service center are returned.
	*/
	public listPnDServiceCenterMetrics(
							   pathParams: ListPnDServiceCenterMetricsPath,
							   queryParams: ListPnDServiceCenterMetricsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPnDServiceCenterMetricsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/metrics'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns the collection of all stops associated with a specific route.
	* <br/>
	* <br/>Pre-condition:
	* <br/>- A valid route instance ID is provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>- All stops meeting the criteria are returned.
	*/
	public listPnDStops(
							   pathParams: ListPnDStopsPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPnDStopsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/routes/{routeInstId}/stops'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves a list of areas for a service center and suggests the next route name for the area.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid parent SIC is provided.
	* <br/>2. A valid planning date is provided.
	* <br/>3. A boolean to specify if satellites should be returned.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. In the input provided is valid, the list of suggested route names is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public listPnDSuggestedRouteNames(
							   pathParams: ListPnDSuggestedRouteNamesPath,
							   queryParams: ListPnDSuggestedRouteNamesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPnDSuggestedRouteNamesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/suggested-route-names'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns the collection of all trips for a SIC and trip date.  The attribute includeZoneCode is an optional indicator to include zones.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid SIC code and trip date is provided in a trip object.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. All trips associated with that SIC and date are returned.
	*/
	public listPnDTrips(
							   pathParams: ListPnDTripsPath,
							   queryParams: ListPnDTripsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPnDTripsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/trip-dates'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation aggregates pickups by pickup callNbr/purInstId to create a list of unassigned pickups.
	* <br/> 
	* <br/>Pre-condition:
	* <br/>1. A valid XRT/Elastic search filter is provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. All pickups meeting the criteria are aggregated and returned.
	* <br/>2. If the input is invalid, then an appropriate error message is returned.
	*/
	public listPnDUnassignedPickups(request: ListPnDUnassignedPickupsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPnDUnassignedPickupsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/aggregations/unassigned-pickups'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation aggregates shipments by consignee to create a list of unassigned stops.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid XRT/Elastic search filter is provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. All shipments meeting the criteria are aggregated and returned.
	*/
	public listPnDUnassignedStops(request: ListPnDUnassignedStopsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPnDUnassignedStopsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/aggregations/unassigned-stops'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Reassigns a list of shipments from one route to another.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid old route instance ID is provided and that route exists.
	* <br/>2. A valid new route instance ID is provided and that route exists.
	* <br/>3. A valid list of shipments instance ID numbers is provided that represent the list of shipments that need to be reassigned.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the input is valid, then the stops are reassigned from the old route to the new route.
	* <br/>2. Otherwise, an appropriate error message is returned. 
	*/
	public reassignPnDShipments(request: ReassignPnDShipmentsRqst,
							   pathParams: ReassignPnDShipmentsPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/routes/{routeInstId}/reassigned-shipments'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Reassigns a list of stops to a route.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid trip instance ID is provided and the trip exists.
	* <br/>2. A valid route instance ID is provided and that route exists.
	* <br/>3. A valid list of trip node sequence numbers is provided that represent the list of nodes that need to be resequenced.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If the input is valid, then the stops are removed from the route.
	* <br/>2. Otherwise an appropriate error message is returned. 
	*/
	public reassignStops(request: ReassignStopsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ReassignStopsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/stops/reassign'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Updates the sequence of the freight on the trailer by the order of its stops on a specific route.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid route instance ID is provided and the route exists.
	* <br/>2. A valid SIC code, route prefix and suffix, and date are provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If the input is valid, then the stops are resequenced based on the order input into the operation.
	* <br/>2. Otherwise an appropriate error message is returned. 
	*/
	public resequenceFreightByRoute(request: ResequenceFreightByRouteRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/routes/stops/resequence'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Updates the sequence of the freight on the trailer by the order of their stops on a specific trip.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid SIC cd is given
	* <br/>2. A valid geocoordinates is given for the SIC
	* <br/>3. A valid trip and its routes to be resequenced are given in the form of RouteBalancingTrip
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If the input is valid, then the stops are resequenced based on the order input into the operation.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public resequenceFreightByTrip(request: ResequenceFreightByTripRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/trips/stops/resequence'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Given the equipment ID, SIC code, and excluded indicator (boolean), updates the excluded trailer status in the SCO_DLVR_SHM table.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid SIC is provided.
	* <br/>2. A valid equipment ID is provided.
	* <br/>3. A valid excluded indicator is provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. The SCO_DLVR_SHM table is updated accordingly as defined by the inputs.
	*/
	public updateDeliveryShipmentExcludedTrailerInd(request: UpdateDeliveryShipmentExcludedTrailerIndRqst,
							   pathParams: UpdateDeliveryShipmentExcludedTrailerIndPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/delivery-shipment/service-centers/{sicCd}/equipment/{equipmentInstId}'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Updates a PnD Route and release flag on the route.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Valid Route should exist.
	* <br/>
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. A route is updated with the door/trailer number or dock location with the nearest door.
	* <br/>2. The release indicator is updated as well per the request.
	* <br/>3. Otherwise an appropriate error message is returned. 
	*/
	public updatePnDRoute(request: UpdatePnDRouteRqst,
							   pathParams: UpdatePnDRoutePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdatePnDRouteResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/routes/{routeInstId}'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation unassigns a list of shipments from a route.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid route instance ID is provided and the route exists.
	* <br/>2. A valid list of shipments instance ID numbers is provided that represent the list of shipments that need to be unassigned from the route.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the input is valid, then the shipments are removed from the route.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public unassignPnDShipmentsFromRoute(request: UnassignPnDShipmentsFromRouteRqst,
							   pathParams: UnassignPnDShipmentsFromRoutePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/routes/{routeInstId}/unassigned-shipments'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Unassigns a list of stops from a route.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid trip instance ID is provided and the trip exists.
	* <br/>2. A valid list of trip node sequence numbers is provided that represent the list of nodes that need to be resequenced.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If the input is valid, then the stops are removed from the route.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public unassignStops(request: UnassignStopsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/stops/unassign'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation determines if it's valid to create a new route.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>. A valid service center, planning date, route prefix and suffix must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. A true value is returned if the route is valid (i.e. NOT in use).
	* <br/>2. False is returned if the route is not valid.
	* <br/>3. If the data provided is invalid, an appropriate error message is returned.
	*/
	public validatePnDRoute(request: ValidatePnDRouteRqst,
							   pathParams: ValidatePnDRoutePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ValidatePnDRouteResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/route-validitions'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation assigns one or more pickup stops to an existing trip.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid trip exists and the trip ID is provided.
	* <br/>2. A list of one or more pickup request instance IDs is provided and those pickup requests exist in the system
	* <br/>3. A pickup request status is provided whose value is either 'ASGN' or 'PREA'.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then the pickup stops are either added as new stops or merged with existing stops to the trip based on whether or not the shipper is already on an existing stop.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public assignPickupsToPnDTrip(request: AssignPickupsToPnDTripRqst,
							   pathParams: AssignPickupsToPnDTripPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/trips/{tripInstId}/pickups'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Unregister a filter with XRT for Routes
	*/
	public unregisterFilterRoutes(request: UnregisterFilterRoutesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/routes/filters/unregister'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Check changed documents against registered filters for routes
	*/
	public changesFeedRoutes(request: ChangesFeedRoutesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/routes/changes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Register a filter with XRT for Routes
	*/
	public registerFilterRoutes(request: RegisterFilterRoutesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RegisterFilterRoutesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/routes/filters/register'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Unregister a filter with XRT for Trips
	*/
	public unregisterFilterTrips(request: UnregisterFilterTripsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/trips/filters/unregister'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Check changed documents against registered filters for trips
	*/
	public changesFeedTrips(request: ChangesFeedTripsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/trips/changes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Register a filter with XRT for Trips
	*/
	public registerFilterTrips(request: RegisterFilterTripsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RegisterFilterTripsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/trips/filters/register'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Unregister a filter with XRT for ServiceCenterMetrics
	*/
	public unregisterFilterServiceCenterMetrics(request: UnregisterFilterServiceCenterMetricsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-center-metrics/filters/unregister'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Check changed documents against registered filters for service-center-metrics
	*/
	public changesFeedServiceCenterMetrics(request: ChangesFeedServiceCenterMetricsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-center-metrics/changes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Register a filter with XRT for ServiceCenterMetrics
	*/
	public registerFilterServiceCenterMetrics(request: RegisterFilterServiceCenterMetricsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RegisterFilterServiceCenterMetricsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-center-metrics/filters/register'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Stop a queue. Stopping a queue will only stop the dequeuing process, it will continue to enqueue
	*/
	public stopQueue(request: StopQueuePath,
							   pathParams: StopQueuePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/stop'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Stop a queue. Stopping a queue will only stop the dequeuing process, it will continue to enqueue
	*/
	public stopQueueViaWebhook(request: StopQueueViaWebhookPath,
							   pathParams: StopQueueViaWebhookPath,
							   queryParams: StopQueueViaWebhookQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/webhooks/queues/{queueName}/stop'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Delete expired messages in the queue
	*/
	public deleteExpiredMessages(
							   pathParams: DeleteExpiredMessagesPath,
							   queryParams: DeleteExpiredMessagesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DeleteExpiredMessagesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/messages'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This operation returns counts for the number of ensembles in each of the status cd values.
	* <br/>Pre-conditions:
	* <br/>1. Input search criteria may be provided.
	* <br/>Post-condition:
	* <br/>1. Counts for each ensemble status are returned.
	*/
	public getEnsembleStatusCounts(
							   pathParams: GetEnsembleStatusCountsPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetEnsembleStatusCountsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/counts/{fromTmst}/{toTmst}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Replay a message in expired status
	*/
	public replayMessage(request: ReplayMessagePath,
							   pathParams: ReplayMessagePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/messages/{messageId}'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation Restarts a STARTED or NOT_STARTED Ensemble execution based on input Ensemble Instance Id.
	* <br/>Pre-conditions:
	* <br/>1. Input Ensemble Instance Id is supplied and valid.
	* <br/>Post-condition:
	* <br/>1. STARTED or NOT_STARTED Ensemble execution status is updated to STARTED and execution is initiated; if any Minuets exist for the Ensemble, those are initiated in sequence and status updates are performed.
	*/
	public restartEnsemble(request: RestartEnsembleRqst,
							   pathParams: RestartEnsemblePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RestartEnsembleResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/ensembles/{ensembleInstId}/restart'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* List the queues.
	*/
	public listQueues(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListQueuesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Start a queue.
	*/
	public startQueue(request: StartQueuePath,
							   pathParams: StartQueuePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/start'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation Resumes a SUSPENDED Ensemble execution based on input Ensemble Instance Id.
	* <br/>Pre-conditions:
	* <br/>1. Input Ensemble Instance Id is supplied and valid.
	* <br/>Post-condition:
	* <br/>1. SUSPENDED Ensemble execution status is updated to STARTED and execution is initiated; if any Minuets exist for the Ensemble, those are initiated in sequence and status updates are performed.
	*/
	public resumeEnsemble(request: ResumeEnsembleRqst,
							   pathParams: ResumeEnsemblePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ResumeEnsembleResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/ensembles/{ensembleInstId}/resume'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* List all expired messages in the queue
	*/
	public listExpiredMessages(
							   pathParams: ListExpiredMessagesPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListExpiredMessagesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/messages/{enqueueFromTmst}/{enqueueToTmst}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation updates one or more SUSPENDED Ensemble execution row to a status of KILLED, in order to indicate it is has been forced to stop processing.
	* <br/>Pre-conditions:
	* <br/>1. One or more Ensemble Instance Ids that is in a status of SUSPENDED is supplied and valid.
	* <br/>Post-condition:
	* <br/>1. Ensemble record(s) updated to a Status of KILLED.
	*/
	public killEnsemble(request: KillEnsembleRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<KillEnsembleResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/ensembles/kill'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation returns counts for the number of messages pending in the queue.
	* <br/>Pre-conditions:
	* <br/>1. Queue name is provided.
	* <br/>Post-condition:
	* <br/>1. Count of all messages in ready and wait statuses
	*/
	public getQueuePendingCount(
							   pathParams: GetQueuePendingCountPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetQueuePendingCountResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/symphony-ensemble/queues/{queueName}/counts/pending'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns all shipments that need to be planned for delivery today based on the requested wave criteria.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The service center is provided and valid and there is a valid current wave defined.  No wave is provided as input.
	* <br/>2. Alternatively, a service center and a valid wave are provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. All shipments meeting the criteria of the service center's current, active wave are returned.
	* <br/>2. All shipments meeting the criteria of the named wave for the service center are returned.
	*/
	public searchUnassignedDeliveries(request: SearchUnassignedDeliveriesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<SearchUnassignedDeliveriesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/unassigned-deliveries/searches'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Unregister a filter with XRT for UnassignedDeliveries
	*/
	public unregisterFilterUnassignedDeliveries(request: UnregisterFilterUnassignedDeliveriesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/unassigned-deliveries/filters/unregister'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Check changed documents against registered filters for unassigned-deliveries
	*/
	public changesFeedUnassignedDeliveries(request: ChangesFeedUnassignedDeliveriesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/unassigned-deliveries/changes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Register a filter with XRT for UnassignedDeliveries
	*/
	public registerFilterUnassignedDeliveries(request: RegisterFilterUnassignedDeliveriesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RegisterFilterUnassignedDeliveriesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/unassigned-deliveries/filters/register'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation returns all shipments that need to be planned for delivery today based on the requested wave criteria.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The service center is provided and valid and there is a valid current wave defined.  No wave is provided as input.
	* <br/>2. Alternatively, a service center and a valid wave are provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. All shipments meeting the criteria of the service center's current, active wave are returned.
	* <br/>2. All shipments meeting the criteria of the named wave for the service center are returned.
	*/
	public searchUnbilledShipments(request: SearchUnbilledShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<SearchUnbilledShipmentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/unbilled-shipments/searches'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Unregister a filter with XRT for UnbilledShipments
	*/
	public unregisterFilterUnbilledShipments(request: UnregisterFilterUnbilledShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/unbilled-shipments/filters/unregister'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Check changed documents against registered filters for unbilled-shipments
	*/
	public changesFeedUnbilledShipments(request: ChangesFeedUnbilledShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/unbilled-shipments/changes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Register a filter with XRT for UnbilledShipments
	*/
	public registerFilterUnbilledShipments(request: RegisterFilterUnbilledShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RegisterFilterUnbilledShipmentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/unbilled-shipments/filters/register'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation returns all shipments that need to be planned for delivery today based on the requested wave criteria.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The service center is provided and valid and there is a valid current wave defined.  No wave is provided as input.
	* <br/>2. Alternatively, a service center and a valid wave are provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. All shipments meeting the criteria of the service center's current, active wave are returned.
	* <br/>2. All shipments meeting the criteria of the named wave for the service center are returned.
	*/
	public searchDeliveryShipments(request: SearchDeliveryShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<SearchDeliveryShipmentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/delivery-shipments/searches'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Unregister a filter with XRT for DeliveryShipments
	*/
	public unregisterFilterDeliveryShipments(request: UnregisterFilterDeliveryShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/delivery-shipments/filters/unregister'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Check changed documents against registered filters for delivery-shipments
	*/
	public changesFeedDeliveryShipments(request: ChangesFeedDeliveryShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/delivery-shipments/changes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Register a filter with XRT for DeliveryShipments
	*/
	public registerFilterDeliveryShipments(request: RegisterFilterDeliveryShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RegisterFilterDeliveryShipmentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/delivery-shipments/filters/register'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation returns all shipments that need to be planned for delivery today based on the requested wave criteria.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The service center is provided and valid and there is a valid current wave defined.  No wave is provided as input.
	* <br/>2. Alternatively, a service center and a valid wave are provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. All shipments meeting the criteria of the service center's current, active wave are returned.
	* <br/>2. All shipments meeting the criteria of the named wave for the service center are returned.
	*/
	public searchUnmappedShipments(request: SearchUnmappedShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<SearchUnmappedShipmentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/unmapped-shipments/searches'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Unregister a filter with XRT for UnmappedShipments
	*/
	public unregisterFilterUnmappedShipments(request: UnregisterFilterUnmappedShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/unmapped-shipments/filters/unregister'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Check changed documents against registered filters for unmapped-shipments
	*/
	public changesFeedUnmappedShipments(request: ChangesFeedUnmappedShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/unmapped-shipments/changes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Register a filter with XRT for UnmappedShipments
	*/
	public registerFilterUnmappedShipments(request: RegisterFilterUnmappedShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RegisterFilterUnmappedShipmentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/unmapped-shipments/filters/register'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation returns all shipments that need to be planned for delivery today based on the requested wave criteria.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The service center is provided and valid and there is a valid current wave defined.  No wave is provided as input.
	* <br/>2. Alternatively, a service center and a valid wave are provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. All shipments meeting the criteria of the service center's current, active wave are returned.
	* <br/>2. All shipments meeting the criteria of the named wave for the service center are returned.
	*/
	public searchAdminRouteShipments(request: SearchAdminRouteShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<SearchAdminRouteShipmentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/admin-route-shipments/searches'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Unregister a filter with XRT for AdminRouteShipments
	*/
	public unregisterFilterAdminRouteShipments(request: UnregisterFilterAdminRouteShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/admin-route-shipments/filters/unregister'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Check changed documents against registered filters for admin-route-shipments
	*/
	public changesFeedAdminRouteShipments(request: ChangesFeedAdminRouteShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/admin-route-shipments/changes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Register a filter with XRT for AdminRouteShipments
	*/
	public registerFilterAdminRouteShipments(request: RegisterFilterAdminRouteShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RegisterFilterAdminRouteShipmentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/admin-route-shipments/filters/register'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation returns all shipments that need to be planned for delivery today based on the requested wave criteria.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The service center is provided and valid and there is a valid current wave defined.  No wave is provided as input.
	* <br/>2. Alternatively, a service center and a valid wave are provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. All shipments meeting the criteria of the service center's current, active wave are returned.
	* <br/>2. All shipments meeting the criteria of the named wave for the service center are returned.
	*/
	public searchUndeletedShipments(request: SearchUndeletedShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<SearchUndeletedShipmentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/undeleted-shipments/searches'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Unregister a filter with XRT for UndeletedShipments
	*/
	public unregisterFilterUndeletedShipments(request: UnregisterFilterUndeletedShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/undeleted-shipments/filters/unregister'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Check changed documents against registered filters for undeleted-shipments
	*/
	public changesFeedUndeletedShipments(request: ChangesFeedUndeletedShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/undeleted-shipments/changes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Register a filter with XRT for UndeletedShipments
	*/
	public registerFilterUndeletedShipments(request: RegisterFilterUndeletedShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RegisterFilterUndeletedShipmentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/undeleted-shipments/filters/register'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation returns all shipments that need to be planned for delivery today based on the requested wave criteria.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The service center is provided and valid and there is a valid current wave defined.  No wave is provided as input.
	* <br/>2. Alternatively, a service center and a valid wave are provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. All shipments meeting the criteria of the service center's current, active wave are returned.
	* <br/>2. All shipments meeting the criteria of the named wave for the service center are returned.
	*/
	public searchDeliveryShipmentUnfiltered(request: SearchDeliveryShipmentUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<SearchDeliveryShipmentUnfilteredResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/delivery-shipment-unfiltered/searches'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Unregister a filter with XRT for DeliveryShipmentUnfiltered
	*/
	public unregisterFilterDeliveryShipmentUnfiltered(request: UnregisterFilterDeliveryShipmentUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/delivery-shipment-unfiltered/filters/unregister'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Check changed documents against registered filters for delivery-shipment-unfiltered
	*/
	public changesFeedDeliveryShipmentUnfiltered(request: ChangesFeedDeliveryShipmentUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/delivery-shipment-unfiltered/changes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Register a filter with XRT for DeliveryShipmentUnfiltered
	*/
	public registerFilterDeliveryShipmentUnfiltered(request: RegisterFilterDeliveryShipmentUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RegisterFilterDeliveryShipmentUnfilteredResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/delivery-shipment-unfiltered/filters/register'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Update Elastic document for DeliveryShipment
	*/
	public updateElasticDocumentDeliveryShipment(request: UpdateElasticDocumentDeliveryShipmentRqst,
							   pathParams: UpdateElasticDocumentDeliveryShipmentPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateElasticDocumentDeliveryShipmentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/delivery-shipment-unfiltered/_doc/{shipmentInstId}/_update'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Delete Elastic document for DeliveryShipment
	*/
	public deleteElasticDocumentDeliveryShipment(
							   pathParams: DeleteElasticDocumentDeliveryShipmentPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DeleteElasticDocumentDeliveryShipmentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/delivery-shipment-unfiltered/_doc/{shipmentInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Get document count from DeliveryShipment index
	*/
	public getElasticDocumentDeliveryShipmentCount(
							   queryParams: GetElasticDocumentDeliveryShipmentCountQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetElasticDocumentDeliveryShipmentCountResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/delivery-shipment-unfiltered/_count'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Get Elastic document from DeliveryShipment index for given primary key
	*/
	public getElasticDocumentDeliveryShipment(
							   pathParams: GetElasticDocumentDeliveryShipmentPath,
							   queryParams: GetElasticDocumentDeliveryShipmentQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetElasticDocumentDeliveryShipmentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/delivery-shipment-unfiltered/_doc/{shipmentInstId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation returns all shipments that need to be planned for delivery today based on the requested wave criteria.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The service center is provided and valid and there is a valid current wave defined.  No wave is provided as input.
	* <br/>2. Alternatively, a service center and a valid wave are provided.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. All shipments meeting the criteria of the service center's current, active wave are returned.
	* <br/>2. All shipments meeting the criteria of the named wave for the service center are returned.
	*/
	public searchEnsembleStateUnfiltered(request: SearchEnsembleStateUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<SearchEnsembleStateUnfilteredResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/ensemble-state-unfiltered/searches'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Unregister a filter with XRT for EnsembleStateUnfiltered
	*/
	public unregisterFilterEnsembleStateUnfiltered(request: UnregisterFilterEnsembleStateUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/ensemble-state-unfiltered/filters/unregister'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Check changed documents against registered filters for ensemble-state-unfiltered
	*/
	public changesFeedEnsembleStateUnfiltered(request: ChangesFeedEnsembleStateUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/ensemble-state-unfiltered/changes'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Register a filter with XRT for EnsembleStateUnfiltered
	*/
	public registerFilterEnsembleStateUnfiltered(request: RegisterFilterEnsembleStateUnfilteredRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RegisterFilterEnsembleStateUnfilteredResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/ensemble-state-unfiltered/filters/register'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Update Elastic document for EnsembleState
	*/
	public updateElasticDocumentEnsembleState(request: UpdateElasticDocumentEnsembleStateRqst,
							   pathParams: UpdateElasticDocumentEnsembleStatePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateElasticDocumentEnsembleStateResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/ensemble-state-unfiltered/_doc/{instId}/_update'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Delete Elastic document for EnsembleState
	*/
	public deleteElasticDocumentEnsembleState(
							   pathParams: DeleteElasticDocumentEnsembleStatePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<DeleteElasticDocumentEnsembleStateResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/ensemble-state-unfiltered/_doc/{instId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Get document count from EnsembleState index
	*/
	public getElasticDocumentEnsembleStateCount(
							   queryParams: GetElasticDocumentEnsembleStateCountQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetElasticDocumentEnsembleStateCountResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/ensemble-state-unfiltered/_count'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Get Elastic document from EnsembleState index for given primary key
	*/
	public getElasticDocumentEnsembleState(
							   pathParams: GetElasticDocumentEnsembleStatePath,
							   queryParams: GetElasticDocumentEnsembleStateQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetElasticDocumentEnsembleStateResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/elastic/ensemble-state-unfiltered/_doc/{instId}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(CityOperationsApiService.CityOperationsApiEndpoint);
	}
}
